import { render, staticRenderFns } from "./RejectedOrderCard.vue?vue&type=template&id=9bb6f170&scoped=true&lang=true&"
import script from "./RejectedOrderCard.vue?vue&type=script&lang=js&"
export * from "./RejectedOrderCard.vue?vue&type=script&lang=js&"
import style0 from "./RejectedOrderCard.vue?vue&type=style&index=0&id=9bb6f170&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bb6f170",
  null
  
)

export default component.exports