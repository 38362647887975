<template>
    <div :class="$twMerge('card cursor-pointer w-full flex flex-col justify-between', classes)">
        <div class="flex items-center justify-between">
            <p class="text-neutral font-medium">
                {{ order._id }}
            </p>
            <div class="" :class="badgeClass">
                {{ statusText }}
            </div>
        </div>
        <div class="flex flex-row  gap-2 items-center mt-2">
            <div class="flex items-center">
                <img alt="" class="w-6 h-6 rounded-full" :src="image.take" v-if="image.take" />
                <div class="ml-2">
                    <div class="text-neutral">{{ order.amount.take }} {{ order.currency.take.code }}</div>
                    <div class="text-sm text-neutral-content">{{ order.currency.take.name }}</div>
                </div>
            </div>
            <div class="col-span-1 text-neutral-content  mx-4">
                <Icon icon="mage:exchange-a" height="24" />
            </div>

            <div class="col-span-4 flex items-center justify-start">
                <img alt="" class="w-6 h-6 rounded-full" :src="image.give" v-if="image.give" />
                <div class="ml-2">
                    <div class="text-neutral">{{ order.amount.give }} {{ order.currency.give.code }}</div>
                    <div class="text-sm text-neutral-content">{{ order.currency.give.name }}</div>
                </div>
            </div>
        </div>
        <div class="mt-2 flex justify-end items-end">
            <p class="text-sm text-neutral-content text-right ">
                {{
                    moment(order.created)
                        .lang($i18n.locale)
                        .format("DD MMMM YYYY, HH:mm")
                }}
            </p>
        </div>
        <!-- <div class="info">
            <div class="status-text-with-number">
                <span class="number">
                    {{ order._id }}
                </span>

               
            </div>
            <div class="date">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="1 1 24 24" fill="none" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock">
                    <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                {{
                    moment(order.created)
                        .lang($i18n.locale)
                        .format("DD MMMM YYYY, HH:mm")
                }}
            </div>
        </div>

         -->
    </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import { Icon } from "@iconify/vue2";

export default {
    components: {
        Icon
    },
    props: {
        order: {
            type: Object,
            default: () => ({})
        },
        classes: {
            type: String,
            default: ""
        }
    },
    methods: {
        moment
    },
    computed: {
        image() {
            return {
                take: !this.objectEmpty(this.order) ? `${this.URL.server}/images/${this.order.currency.take.image}.png` : "",
                give: !this.objectEmpty(this.order) ? `${this.URL.server}/images/${this.order.currency.give.image}.png` : ""
            };
        },
        badgeClass() {
            let result = "mt-1 w-min text-white text-sm font-medium px-2 py-0.5 rounded-xs";
            switch (this.order.status) {
                case 101:
                case 102:
                case 103:
                case 104:
                    result += " bg-warning";
                    break;
                case 105:
                case 107:
                    result += " bg-success";
                    break;
                case 106:
                case 108:
                    result += " bg-error";
                    break;
                default:
                    result += " bg-black";
            }
            return this.$twMerge(result);
        },
        status() {
            switch (this.order.status) {
                case 101:
                    return "verification";
                case 102:
                    return "payment-user";
                case 103:
                    return "check-payment";
                case 104:
                    return "payment-service";
                case 105:
                    return "resolved";
                case 106:
                    return "rejected";
                case 107:
                    return "checked";
                case 108:
                    return "error";
                default:
                    return null;
            }
        },
        statusText() {
            switch (this.status) {
                case "resolved":
                    return this.$t("orderCard.statusName.completed");
                case "rejected":
                    return this.$t("orderCard.statusName.rejected");
                case "checked":
                    return this.$t("orderCard.statusName.completed");
                default:
                    return this.$t("orderCard.statusName.new");
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.order {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-sm;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
}

.info {
    padding: 16px 12px;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}

.status-text-with-number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.status-text-with-number .status-text {
    color: $white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: $border-sm;
}

.status-text-with-number .number {
    color: $grafit;
    font-size: 20px;
    font-weight: 500;
}

.info .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grey-lg-text;
    font-size: 14px;
}

.info .date svg {
    margin-right: 8px;
}

.currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 12px;
}

.currency.take {
    padding-top: 16px;
}

.currency.give {
    padding-bottom: 16px;
}

.currency .image {
    -o-object-fit: cover;
    object-fit: cover;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 12px;
}

.currency .details {
    padding-top: 4px;
    width: 100%;
}

.currency .details .amount {
    color: $cur-name;
    font-size: 18px;
    margin-bottom: 8px;
}

.currency .details .name {
    color: $cur-name;
    font-size: 14px;
}

.icon-exchange {
    color: $cur-name;
    padding: 0 12px 0 14px;
    margin-bottom: 8px;
}

.status-text.verification {
    background-color: $yellow;
}

.status-text.payment-user {
    background-color: $yellow;
}

.status-text.check-payment {
    background-color: $yellow;
}

.status-text.payment-service {
    background-color: $yellow;
}

.status-text.rejected {
    background-color: $red;
}

.status-text.resolved {
    background-color: $green;
}

.status-text.checked {
    background-color: $green;
}

.status-text.error {
    background-color: $red;
}
</style>
