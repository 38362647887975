<template lang="">
    <div class="flex flex-col gap-2 items-center bg-white rounded-lg p-4 my-4">
        <p class="text-lg text-neutral-content">
            {{ $t("popupOrder.paymentOrder.paymentMethod") }}
        </p>
        <div class="">
            <span class="text-primary hover:underline cursor-pointer" referrerpolicy="origin" target="_blank" @click="() => changeShowQiwiPaymentInfo('links')">
                {{ $t("popupOrder.paymentOrder.paymentLinkBtn") }}
            </span>
            или
            <span class="text-primary hover:underline cursor-pointer" @click="() => changeShowQiwiPaymentInfo('fields')">
                {{ $t("popupOrder.paymentOrder.paymentDirectBtn") }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        changeShowQiwiPaymentInfo: Function
    }
};
</script>
<style lang="scss" scoped>
.qiwiChoosePayment {
    margin: 15px 0px;
}
.qiwiChoosePayment_title {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: 600;
}
.qiwiChoosePayment_selectWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.qiwiChoosePayment_linkPay {
    color: #3597fa;
    cursor: pointer;
    margin-right: 5px;
}

.qiwiChoosePayment_direct {
    color: #3597fa;
    cursor: pointer;
    margin-left: 5px;
}

.qiwiChoosePayment_linkPay:hover,
.qiwiChoosePayment_direct:hover {
    text-decoration: underline;
}
</style>
