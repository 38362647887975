<template lang="">
    <div class=" h-full">
        <h5 class="font-medium text-lg text-neutral text-center">{{ direct == "left" ? $t("orderPage.fields.directNameLeft") : $t("orderPage.fields.directNameRight") }}</h5>
        <div class="flex items-center mt-3">
            <img alt="" class="w-7 h-7 object-cover rounded-xs" :src="getCurrencyImage()" />
            <div class="ml-3">
                <h6 class="text-sm text-neutral-content">{{ currencyName }}</h6>
                <div>
                    <span class="text-neutral font-medium">
                        {{ amount }}
                    </span>
                    <span class="text-sm text-neutral-content">
                        {{ currencyCode }}
                    </span>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div v-for="(item, i) in fields" :key="i" class="mb-1">
                <p class="text-sm text-neutral-content">{{ item.name }}:</p>
                <p class="text-neutral">{{ item.value }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { getCurrencyImageLink } from "../../utils/currency/getImageLink";

export default {
    props: {
        title: String,
        currencyName: String,
        currencyCode: String,
        currencyImage: String,
        fields: Array,
        amount: Number,
        direct: String
    },
    methods: {
        getCurrencyImage() {
            return getCurrencyImageLink(this.currencyImage);
        }
    }
};
</script>
<style lang="scss" scoped>
.orderClientFields {
    padding: 10px 24px;
}
.orderClientFields .title {
    font-weight: 600;
}
.orderClientFields .currencInfoSection {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.currencInfoSection .currencyImage {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}

.currencInfoSection .currencyName {
    margin-left: 3px;
    font-size: 16px;
    font-weight: 600;
}

.orderClientFields .fieldsListWrap {
    margin-top: 20px;
}

.fieldsListItem {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 15px;
}
.orderClientFields .fieldsListItemLabel {
    margin-right: 5px;
}

.orderClientFields .fieldsListItemValue {
    font-weight: 600;
    word-break: break-all;
}

// .orderClientFields
</style>
