<template lang="">
    <div class="">
        <div class="card bg-base-100">
            <StepItem :number="1" :text="$t('orderPage.payment.step1')" />
            <QiwiChoosePayment v-if="isQiwi && hasLinkAndFields" :changeShowQiwiPaymentInfo="changeChoose" />
            <div class="mt-4">
                <p class="text-sm text-neutral-content">{{ $t("orderPage.payment.sum") + ":" }}</p>
                <div class="flex items-center gap-2">
                    <span class="text-lg text-neutral"
                        >{{ new Intl.NumberFormat("ru-RU").format(paymentSum) }} <span class="text-sm text-neutral-content">{{ paymentCurrencyCode }}</span>
                    </span>
                    <CopyBoard title="Sum" iconName="tabler:copy" classes="ml-1" :text="paymentSum" />
                </div>
            </div>

            <div class="mt-4">
                <div class="grid grid-cols-12 gap-0">
                    <div :class="{ 'col-span-12': true, 'md:col-span-6': qrCodeValue }">
                        <Fields v-if="showFields && hasFields" :fields="order.fields.info" />
                    </div>
                    <div v-if="qrCodeValue" class="col-span-12 mt-4 md:mt-0 md:col-span-6">
                        <div class="flex justify-center">
                            <div class="flex justify-center bg-white rounded-lg p-4">
                                <QrcodeVue class="qr-code-section__image" :value="qrCodeValue" :size="250" level="H" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PaymentLinks v-if="showLinks" :links="links" />

            <div v-if="paymentNotify" class="text-lg text-primary text-center mt-6">
                {{ paymentNotify }}
            </div>

            <div class="text-sm text-neutral-content mt-6">
                {{ $t("popupOrder.paymentOrder.chatQuest") }}
                <span class="cursor-pointer text-primary hover:underline" @click="openChat">
                    {{ $t("popupOrder.paymentOrder.chatBtn") }}
                </span>
            </div>
            <StepItem class="mt-4" :number="2" :text="step2text" />

            <UploadReceipt v-if="isAttachCheck" :setHandler="setImageName" :clearHandler="clearImageName" />
            <div class="flex justify-center mt-4">
                <div class="w-full  md:w-1/2 flex gap-2">
                    <my-button class="w-full" variant="error" :value="$t('popupOrder.paymentOrder.action.cancel')" @click.native="rejectedHandler" :spinner="spinner1"></my-button>
                    <my-button class="w-full" variant="primary" v-if="!hideConfirm" :disabled="isAttachCheck ? spinner2 || !imagename : spinner2" :value="$t('popupOrder.paymentOrder.action.iPaid')" @click.native="paymentHandler" :spinner="spinner2"></my-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import StepItem from "../../../../components/ui/StepItem.vue";
import MyButton from "../../../../components/ui/button.vue";
import CopyBoard from "../../../../components/ui/CopyBoard.vue";

import Fields from "./Fields.vue";
import PaymentLinks from "./PaymentLinks.vue";
// import Sum from "./Sum.vue";
import UploadReceipt from "./UploadReceipt.vue";
import QiwiChoosePayment from "./QiwiChoosePayment.vue";
import QrcodeVue from "qrcode.vue";
import OrderService from "../../../../services/OrderService";
import axios from "axios";

export default {
    data() {
        return {
            choose: "",
            spinner1: false,
            spinner2: false,
            imagename: ""
        };
    },
    props: {
        order: Object,
        setOrder: Function
    },
    components: {
        QrcodeVue,
        StepItem,
        Fields,
        PaymentLinks,
        QiwiChoosePayment,
        MyButton,
        UploadReceipt,
        CopyBoard
        // Sum
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        paymentSum() {
            return this.order.amount.take;
        },
        paymentCurrencyCode() {
            return this?.order?.currency?.take?.currency?.code;
        },
        isAttachCheck() {
            let isAttachCheck = this.order.isAttachCheck;
            return isAttachCheck;
        },
        step2text() {
            let isAttachCheck = this.order.isAttachCheck;

            if (isAttachCheck) {
                return this.$t("popupOrder.paymentOrder.step2withCheck");
            }
            if (this.hideConfirm) {
                return this.$t("popupOrder.paymentOrder.step2hideConfirm");
            } else {
                return this.$t("popupOrder.paymentOrder.step2");
            }
        },
        hideConfirm() {
            return this?.order?.viewSettings?.hidePaymentConfirmBtn;
        },
        paymentNotify() {
            let text = this?.order?.currency?.take?.currency?.paymentNotification;
            return text;
        },
        hasFields() {
            return !!this.order.fields.info.length;
        },
        showLinks() {
            let paymentLinks = this.links;

            let hasLinks = paymentLinks && paymentLinks.length;

            if (!hasLinks) return false;

            if (this.isQiwi && this.hasLinkAndFields) {
                return this.choose == "links";
            } else {
                return true;
            }
        },
        showFields() {
            if (this.isQiwi && this.hasLinkAndFields) {
                return this.choose == "fields";
            } else {
                return true;
            }
        },
        isQiwi() {
            let takeCurrency = this.order.currency.take.currency;
            return takeCurrency && takeCurrency.uniqueType && takeCurrency.uniqueType === "qiwi";
        },
        hasLinkAndFields() {
            let hasInfo = this.order.fields.info.length !== 0;
            return hasInfo && this.links && !!this.links.length;
        },
        links() {
            let order = this.order;
            let paymentLinks = order.paymentLinks;
            let hasPaymentLinks = paymentLinks && paymentLinks.length;

            if (!hasPaymentLinks) return;

            let firstLink = paymentLinks[0];

            if (firstLink.includes("qiwi.com")) {
                return paymentLinks;
            } else {
                let resultLink = `${this.URL.api}${firstLink}?orderId=${order._id}`.replace(/([^:]\/)\/+/g, "$1");
                return [resultLink];
            }
        },
        qrCodeValue() {
            let isAddressFields = this.order.fields.info.filter(f => f.isAddress);
            return isAddressFields.length == 1 ? isAddressFields[0].value : null;
        }
    },
    methods: {
        ...mapMutations(["SET_SNACK_BAR"]),
        setImageName(name) {
            console.log(name, "set image name");
            this.imagename = name;
        },
        clearImageName() {
            console.log("clear image name");
            this.imagename = "";
        },
        changeChoose(v) {
            this.choose = v == this.choose ? "" : v;
        },
        openChat() {
            // window.jivo_api.open();
            window.Chatra("openChat");
            axios.post("/click-handler", {
                hash: this.order.hash,
                code: "can-not-pay"
            });
            return false;
        },
        paymentHandler() {
            if (this.spinner2) return;
            this.spinner2 = true;
            let data = { hash: this.order.hash, type: "payment", filename: this.imagename };
            this.changeOrder(data);
        },
        rejectedHandler() {
            if (this.spinner1) return;
            this.spinner1 = true;
            let data = { hash: this.order.hash, type: "reject" };

            this.changeOrder(data);
        },
        async changeOrder(data) {
            try {
                let { data: order } = await OrderService.change(data);

                if (order) {
                    this.setOrder(order);
                }
            } catch (e) {
                let message = e?.response?.data?.error;
                if (message) {
                    this.SET_SNACK_BAR({ active: true, text: message });
                }
            } finally {
                this.spinner1 = false;
                this.spinner2 = false;
            }
        }
    }
};
</script>
