<template>
    <Drawer :header="$t('rewardWithdrawal.title')" :isOpen="!!showRewardMode" :closeHandler="closeReward" direction="bottom" size="100">
        <div v-if="currencies.length !== 0" class="">
            <div class="grid mt-3 grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                    <CurrencyList :selectedId="selectedCurrencyId" :currencies="currencies" :changeCurrency="changeCurrency" />
                </div>
                <div>
                    <p v-if="rewardError" class="text-error text-lg">{{ rewardError }}</p>
                    <form novalidate @submit.stop.prevent="send">
                        <!-- <my-select :name="'select'" @change-option="changeCurrency" :options="currencies"></my-select> -->

                        <div class="currency-inputs" v-if="currency && currency.uniqueType === 'qiwi'">
                            <my-input :value="mobileNumber" @input="mobileNumberHandler" class="qiwi-input" :validation="validations.mobileNumber" :label="$t('rewardWithdrawal.form.qiwiWallet')" />
                        </div>

                        <div class="currency-inputs" v-if="currency">
                            <my-input v-for="field in currency.fields.give" :key="field._id" v-model.trim="field.value" :label="field.name" @input="resetValidateField(field)" :validation="validations.fields[field._id]"></my-input>
                        </div>

                        <my-input v-model.trim="amount" :label="$t('rewardWithdrawal.form.amount')" :validation="validations.amount" inputType="number"></my-input>

                        <my-button classes="mt-4 w-full" variant="primary" :value="$t('rewardWithdrawal.form.createBtn')" :spinner="spinner"></my-button>
                    </form>
                </div>
            </div>
        </div>
    </Drawer>
</template>

<script>
import { mapGetters } from "vuex";

import input from "./ui/input.vue";
import button from "./ui/button.vue";
// import select from "./ui/select.vue";
import axios from "axios";

import Drawer from "./ui/Drawer.vue";
import CurrencyList from "./ui/CurrencyList.vue";

export default {
    components: {
        myInput: input,
        myButton: button,
        // mySelect: select,
        Drawer,
        CurrencyList
    },
    props: {
        showRewardMode: {
            type: String,
            default: ""
        },
        addReward: Function
    },
    data() {
        return {
            mobileNumber: "",
            amount: 0,
            currency: null,
            validations: {
                fields: {},
                amount: {
                    isValid: true,
                    text: ""
                },
                mobileNumber: {
                    isValid: true,
                    text: ""
                }
            },
            currencies: [],
            spinner: false,
            rewardError: ""
        };
    },
    computed: {
        ...mapGetters(["user"]),
        selectedCurrencyId() {
            return this.currency ? this.currency._id : "";
        }
    },
    watch: {
        currency() {
            if (this.currency) {
                this.initValidateFields();
            }
        },
        currencies() {
            if (this.currencies.length && !this.currency) {
                this.currency = this.currencies[0];
            }
        },
        amount() {
            this.validations.amount = {
                isValid: true,
                text: ""
            };
        },
        mobileNumber() {
            this.validations.mobileNumber = {
                isValid: true,
                text: ""
            };
        }
    },
    mounted() {
        this.fetchCurrencies();
    },
    methods: {
        mobileNumberHandler(e) {
            this.validations.mobileNumber = {
                isValid: true,
                text: ""
            };
            let value = e;
            if (value.length === 0 || value.length === 1) {
                this.selectedCountry = "";
            }
            if (value) {
                let r = value.replace(/\D/g, "").match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,3})/);

                let stringLength = r[0].length;
                let x;
                if (stringLength < 12) {
                    x = value.replace(/\D/g, "").match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,3})/);
                    this.mobileNumber = !x[3] ? "+" + x[1] + " " + x[2] : "+" + x[1] + " " + "(" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : "") + (x[5] ? "-" + x[5] : "");
                } else {
                    x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,4})/);
                    this.mobileNumber = !x[3] ? "+" + x[1] + "" + x[2] : "+" + x[1] + " " + x[2] + " " + x[3] + (x[4] ? " " + x[4] : "") + (x[5] ? " " + x[5] : "");
                }
            }
        },
        async fetchCurrencies() {
            try {
                let res = await axios.get("/reward-currencies");
                this.currencies = res.data;
            } catch (e) {
                console.error(e);
            }
        },
        resetData() {
            this.amount = 0;
            this.resetValidateField();
            this.spinner = false;
            this.rewardError = "";
            this.currency.fields.give = this.currency.fields.give.map(field => Object.assign(field, { value: "" }));
        },
        closeReward() {
            this.resetData();
            this.$emit("close-reward");
        },
        changeCurrency(currency) {
            this.currency = JSON.parse(JSON.stringify(currency));
        },
        initValidateFields() {
            this.validations.fields = {};
            this.currency.fields.give.forEach(field => {
                this.validations.fields[field._id] = {
                    isValid: true,
                    text: ""
                };
            });
        },
        resetValidateField() {
            this.currency.fields.give.forEach(field => {
                this.validations.fields[field._id] = {
                    isValid: true,
                    text: ""
                };
            });
            this.validations.amount = {
                isValid: true,
                text: ""
            };
        },
        validationFields() {
            let isValid = true;
            this.currency.fields.give.forEach(field => {
                if (isValid) {
                    if (field.value.length == 0) {
                        this.validations.fields[field._id] = {
                            isValid: false,
                            text: this.$t("rewardWithdrawal.validationFields.incorrectData")
                        };
                        isValid = false;
                    }
                }
            });

            if (this.currency.uniqueType === "qiwi") {
                if (this.mobileNumber.replace(/\D/g, "").length < 10) {
                    this.validations.mobileNumber = {
                        text: this.$t("rewardWithdrawal.validationFields.incorrectPhone"),
                        isValid: false
                    };
                    isValid = false;
                }
            }

            return isValid;
        },
        amountValid() {
            if (this.showRewardMode == "cashback" && +this.amount > +this.user.accrual.availableBalance) {
                this.validations.amount = {
                    isValid: false,
                    text: this.$t("rewardWithdrawal.validationAmount.invalidSum")
                };
                return false;
            } else if (this.showRewardMode == "referral" && +this.amount > +this.user.referral.balance) {
                this.validations.amount = {
                    isValid: false,
                    text: this.$t("rewardWithdrawal.validationAmount.maxSum")
                };
                return false;
            } else if (!this.amount) {
                this.validations.amount = {
                    isValid: false,
                    text: this.$t("rewardWithdrawal.validationAmount.incorrectSum")
                };
                return false;
            } else {
                return true;
            }
        },
        async send() {
            if (this.spinner) return;
            this.spinner = true;
            if (!this.validationFields()) {
                this.spinner = false;
            } else if (!this.amountValid()) {
                this.spinner = false;
            } else {
                await axios
                    .post("/reward", {
                        sum: this.amount,
                        type: this.showRewardMode,
                        currencyId: this.currency._id,
                        fields: this.currency.fields.give,
                        mobileNumber: this.mobileNumber
                    })
                    .then(response => {
                        this.addReward(response.data);
                        this.closeReward();
                    })
                    .catch(err => {
                        if (err.response.data.has) {
                            this.rewardError = err.response.data.error;
                        } else if (err.response.data.field) {
                            this.validations.fields[err.response.data.id] = {
                                isValid: false,
                                text: err.response.data.error
                            };
                        } else {
                            console.error(err);
                        }
                    })
                    .finally(() => {
                        this.spinner = false;
                    });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.reward-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 0;
    top: 0;
    right: 0;
    background-color: $white;
    transition: width 0.32s;
    z-index: 999;
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
    overflow-x: hidden;
    overflow-y: auto;
}

.reward-form {
    padding: 24px 32px;
}

.header-name {
    color: $grafit;
    font-size: 28px;
    margin-bottom: 32px;
}

.icon-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 24px;
    color: $black;
}

.message {
    font-size: 18px;
    line-height: 26px;
    color: $white;
    font-weight: 500;
    margin: 64px 0;
}

.button {
    margin: 8px 0 0 0 !important;
}

.reward-error {
    color: red;
    font-weight: 400;
}

@media (max-width: 479px) {
    .reward-wrapper {
        min-width: 0;
    }
}
</style>
