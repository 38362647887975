<template>
    <div class="profile-view">
        <div v-if="user.partner" class="view-name" :class="'referral monitoring'">
            {{ $t("referralPage.monitoringTitle") }}
        </div>

        <div v-if="user.partner" class="grid monitoring-block">
            <div class="cell cell--4-col cell--4-col-tablet cell--4-col-phone monitoring-block_wrap"><my-input class="monitoring-block_input" v-model.trim="partner.ip" label="IP"></my-input></div>
            <div class="cell cell--4-col cell--4-col-tablet cell--4-col-phone monitoring-block_wrap"><my-input class="monitoring-block_input" v-model.trim="partner.address" :label="$t('referralPage.partnerForm.address')"></my-input></div>
            <div class="cell cell--4-col cell--8-col-tablet cell--4-col-phone monitoring-block_wrap"><my-button class="monitoring-block_input" :spinner="spinner.partner" @click.native="changePartner" :value="$t('referralPage.partnerForm.saveBtn')"></my-button></div>
        </div>

        <div class="view-name" :class="'referral'">
            {{ $t("referralPage.title") }}
        </div>

        <RefSection v-if="user.verified" :copyboardTitle="$t('referralPage.referralLinkTitle')" :copyText="referralLink()">
            <p>
                {{ $t("referralPage.regulations.p1") }}
            </p>
            <p>
                {{ $t("referralPage.regulations.p2") }}
            </p>
        </RefSection>

        <div v-if="user.verified" class="referral">
            <div class="grid">
                <div class="rewards-wrapper">
                    <h3 @click="showReward = !showReward">
                        <span>
                            {{ $t("referralPage.rewardConclusions.title") }}
                        </span>
                        <span class="arrow-show" :class="showReward ? 'show' : ''"
                            ><svg version="1.1" id="Capa_1" width="24" height="24" x="0px" y="0px" stroke="currentColor" viewBox="0 0 512.002 512.002">
                                <g>
                                    <g>
                                        <path
                                            d="M388.425,241.951L151.609,5.79c-7.759-7.733-20.321-7.72-28.067,0.04c-7.74,7.759-7.72,20.328,0.04,28.067l222.72,222.105
			L123.574,478.106c-7.759,7.74-7.779,20.301-0.04,28.061c3.883,3.89,8.97,5.835,14.057,5.835c5.074,0,10.141-1.932,14.017-5.795
			l236.817-236.155c3.737-3.718,5.834-8.778,5.834-14.05S392.156,245.676,388.425,241.951z"
                                        />
                                    </g>
                                </g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                                <g></g>
                            </svg>
                        </span>
                    </h3>
                    <div class="reward-list" :class="showReward ? 'reward-list_open' : ''">
                        <reward-card v-for="reward in rewards" :key="reward._id" :reward="reward" />
                    </div>
                </div>
            </div>

            <div v-if="user.referral && referralHistory" class="grid referral-accruals">
                <div class="cell cell--7-col cell--4-col-tablet cell--4-col-phone referral-accruals">
                    <div class="title">
                        {{ $t("referralPage.referralRewards.title") }}
                    </div>

                    <div class="accruals-empty" v-if="referralHistory.length === 0">
                        {{ $t("referralPage.referralRewards.notRewards") }}
                    </div>

                    <div v-else>
                        <item-accrual v-for="item in referralHistory" :key="item._id" :accrual="item" mode="referral"></item-accrual>
                        
                    </div>
                </div>

                <div class="cell cell--5-col cell--4-col-tablet cell--4-col-phone referral-details">
                    <div class="description">
                        {{ $t("referralPage.referralRewards.regulations") }}
                    </div>

                    <div class="title">
                        {{ $t("referralPage.referralRewards.total") }}
                    </div>

                    <div class="total">{{ new Intl.NumberFormat("ru-RU").format(getUserReferralBalance).trim() }} <span class="code">RUB</span></div>

                    <my-button class="full-width" v-if="getUserReferralBalance !== 0" style="margin: 8px 0 24px 0" :spinner="spinner.referral" :value="$t('referralPage.referralRewards.orderAPayoutBtn')" @click.native.stop="orderPayment('referral')"></my-button>

                    <div class="msg">
                        {{ message.referral }}
                    </div>
                </div>
            </div>

            <div class="border"></div>

            <div v-if="user.accrual && accrualHistory" class="grid">
                <div class="cell cell--7-col cell--4-col-tablet cell--4-col-phone cash-back-accruals">
                    <div class="title">
                        {{ $t("referralPage.bonuses.title") }}
                    </div>
                    <div class="accruals-empty" v-if="accrualHistory.length === 0">
                        {{ $t("referralPage.bonuses.notRewards") }}
                    </div>

                    <div v-else>
                        <item-accrual v-for="item in accrualHistory" :accrual="item" :key="item._id" mode="accrual"></item-accrual>
                    </div>
                </div>

                <div class="cell cell--5-col cell--4-col-tablet cell--4-col-phone">
                    <div class="cash-back-details">
                        <div class="description">
                            {{ $t("referralPage.bonuses.regulations") }}
                        </div>

                        <div class="title">
                            {{ $t("referralPage.bonuses.total") }}
                        </div>

                        <div class="total">{{ new Intl.NumberFormat("ru-RU").format(getUserAccrualBalance).trim() }} <span class="code">RUB</span></div>

                        <my-button v-if="getUserAccrualBalance !== 0" class="full-width" style="margin: 8px 0 16px 0" :spinner="spinner.bonus" :value="$t('referralPage.bonuses.orderAPayoutBtn')" @click.native.stop="orderPayment('cashback')"></my-button>

                        <div class="msg">
                            {{ message.bonus }}
                        </div>
                    </div>
<!-- 
                    <div class="promo-code-wrap">
                        <div class="title">
                            {{ $t("referralPage.promocode.title") }}
                        </div>

                        <my-input v-model.trim="promocode" :label="$t('referralPage.promocode.promocode')" :validation="validations.promocode"></my-input>

                        <my-button class="full-width" :value="$t('referralPage.promocode.activateBtn')" :spinner="spinner.promocode" @click.native="activatePromocode"></my-button>
                    </div> -->
                </div>
            </div>
        </div>

        <div v-else class="referral">
            <div class="access-denied">
                <span class="user-name">{{ user.name || user.email }},</span>
                <span class="description"> {{ $t("referralPage.notVerifiedTitle") }}.</span>
            </div>
        </div>

        <reward :addReward="addReward" :mode="reward.mode" @close-reward="closeReward" ref="reward"></reward>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";

import reward from "../../components/reward.vue";
import myButton from "../../components/ui/button.vue";
import itemAccrual from "../../components/item/accrual.vue";
import myInput from "../../components/item/input";
import rewardCard from "../../components/item/reward.vue";
import Reward from "../../components/reward.vue";
import RefSection from "./RefSection.vue";

export default {
    data() {
        return {
            refPromocode: "",
            rewards: [],
            promocode: "",
            validations: {
                promocode: {
                    isValid: true,
                    text: ""
                }
            },
            message: {
                referral: "",
                bonus: ""
            },
            spinner: {
                referral: false,
                bonus: false,
                promocode: false,
                partner: false
            },
            reward: {
                mode: "referral",
                open: false
            },
            showReward: false,
            partner: {
                ip: "",
                address: ""
            }
        };
    },
    components: {
        myButton,
        itemAccrual,
        myInput,
        reward,
        rewardCard,
        Reward,
        RefSection
    },
    watch: {
        promocode: {
            handler() {
                this.validations.promocode = {
                    isValid: true,
                    text: ""
                };
            }
        }
    },
    computed: {
        ...mapGetters(["user", "isDesktop", "getCourse"]),
        currentLocale() {
            return this.$i18n.locale;
        },
        getUserAccrualBalance() {
            return this.user.accrual.availableBalance;
        },
        getUserReferralBalance() {
            return this.user.referral.balance;
        },
        referralHistory() {
            let user = this.user;
            if (user.referral && user.referral.history) {
                let l = user.referral.history.filter(i => i.sum !== 0);
                return l;
            } else {
                return [];
            }
        },
        accrualHistory() {
            let user = this.user;
            if (user.accrual && user.accrual.history) {
                return user.accrual.history.filter(i => i.sum !== 0);
            } else {
                return [];
            }
        },
        hasAccruals() {
            return typeof this.user.accrual !== "string";
        }
    },
    created() {
        this.getRewards();

        this.sockets.subscribe("update reward", reward => {
            this.rewards = this.rewards.map(r => (r._id === reward._id ? reward : r));
        });
        this.getPartner();
    },
    mounted() {
        if (this.user.verified) {
            this.getRefPromocode();
        }
    },
    methods: {
        ...mapMutations(["SET_SNACK_BAR"]),
        async getRefPromocode() {
            try {
                let { data } = await axios.get("users-ref-promocode");
                this.refPromocode = data.code;
            } catch (e) {
                console.error(e);
            }
        },
        referralLink() {
            return "https://ex-bank.cc/?ref=" + this.user.referral.link;
        },
        async getPartner() {
            await axios
                .get("/partner")
                .then(response => {
                    this.partner = response.data;
                })
                .catch(e => {
                    console.error(e);
                });
        },
        async changePartner() {
            if (this.spinner.partner) return;
            this.spinner.partner = true;
            await axios
                .put("/partner", { ip: this.partner.ip, address: this.partner.address })
                .then(response => {
                    this.partner = response.data;
                    this.spinner.partner = false;
                })
                .catch(() => {
                    this.spinner.partner = false;
                });
        },
        copyReferralLink() {},
        activatePromocode() {
            if (this.spinner.promocode) return;

            this.spinner.promocode = true;

            if (this.promocode === "") {
                this.validations.promocode.isValid = false;
                this.validations.promocode.text = this.$t("referralPage.promocodeActivateHandler.incorrectPromocode");
                this.spinner.promocode = false;
            } else {
                axios
                    .put("promocodes", {
                        code: this.promocode
                    })
                    .then(() => {
                        this.promocode = "";
                        this.spinner.promocode = false;
                        this.SET_SNACK_BAR({ active: true, text: this.$t("referralPage.promocodeActivateHandler.activated") });
                    })
                    .catch(() => {
                        this.validations.promocode.isValid = false;
                        this.validations.promocode.text = this.$t("referralPage.promocodeActivateHandler.notValid");
                        this.promocode = "";
                        this.spinner.promocode = false;
                    });
            }
        },
        openReward() {
            if (!this.reward.open) {
                this.isDesktop ? (this.$refs.reward.$el.style.width = "420px") : (this.$refs.reward.$el.style.width = "100%");
                this.reward.open = true;
            }
        },
        closeReward() {
            if (this.reward.open) {
                this.$refs.reward.$el.style.width = "0";
                this.reward.open = false;
            }
        },
        orderPayment(mode) {
            if (mode === "referral") {
                if (this.user.referral.balance < 100) {
                    this.message.referral = this.$t("referralPage.orderPaymentHandler.minSumError");

                    setTimeout(() => {
                        this.message.referral = "";
                    }, 3200);
                } else {
                    this.reward.mode = mode;
                    this.openReward();
                }
            } else if (mode === "cashback") {
                if (this.user.accrual.availableBalance < 100) {
                    this.message.bonus = this.$t("referralPage.orderPaymentHandler.minSumError");
                    setTimeout(() => {
                        this.message.bonus = "";
                    }, 3200);
                } else {
                    this.reward.mode = mode;
                    this.openReward();
                }
            }
        },
        async getRewards() {
            await axios
                .get("/reward")
                .then(response => {
                    this.rewards = response.data;
                })
                .catch(e => {
                    console.error(e);
                });
        },
        addReward(reward) {
            this.rewards.unshift(reward);
            this.showReward = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.profile {
    display: flex;
    flex-direction: column;
}

.profile > .grid {
    width: 100%;
}

.profile-view {
    background-color: $white;
    margin: 16px;
    padding: 16px 8px;
    border-radius: 10px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.profile-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.profile-view .view-name.referral {
    margin: 0 16px 8px 16px;
}

.cards-view {
    margin-top: 1rem;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
}

.cards-view .view-name {
    color: $grafit;
    font-size: 26px;
    margin: 0 16px 24px 16px;
}

.orders-move,
.cards-move {
    transition: transform 0.64s;
}

.add-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding: 16px;
    border-radius: $border-sm;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.32);
}

.add-card .title {
    font-size: 18px;
    color: $grafit;
    margin-right: 16px;
}

.add-card .icon {
    cursor: pointer;
    color: $grafit;
}

.add-card .icon:hover {
    color: $grey-lg-text;
}

.referral > .grid {
    width: 100%;
}

.referral > .grid > .cell {
    padding: 0;
}

.referral .description p {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
}

.referral .description p:not(:last-child) {
    margin-bottom: 16px;
}

.referral .referral-link-card,
.referral .promo-code-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px !important;
    border: 1px solid #ebeff6;
    border-radius: $border-sm;
}

.referral .referral-link-card > .title,
.referral .promo-code-wrap > .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-link-card > .link {
    color: $black;
    font-size: 16px;
    font-weight: 400;
    background-color: #ebeff6;
    padding: 12px 16px;
    margin-bottom: 16px;
    border-radius: $border-sm;
}

.referral > .border {
    width: calc(100% - 32px);
    margin: 24px 16px;
}

.referral .referral-accruals > .title,
.referral .cash-back-accruals > .title {
    color: $grafit;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 32px;
}

.referral .referral-details,
.referral .cash-back-details {
    background-color: #ebeff6;
    padding: 12px 16px !important;
    border-radius: $border-sm;
}

hr {
    opacity: 0.5;
}

.referral .referral-details > .description,
.referral .cash-back-details > .description {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}

.referral .referral-details > .title,
.referral .cash-back-details > .title,
.referral .referral-details > .total,
.referral .cash-back-details > .total {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 16px;
}

.referral .referral-details > .total > .code,
.referral .cash-back-details > .total > .code {
    font-weight: 400;
}

.referral .referral-details > .history,
.referral .cash-back-details > .history {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
}

.referral .promo-code-wrap {
    margin-top: 32px;
}

.referral .promo-code-wrap > .title {
    margin-bottom: 0;
}

.referral .access-denied {
    color: $grafit;
    font-size: 16px;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.referral .access-denied a {
    color: #fff;
}

.linkButton {
    margin: 1rem;
}

.referral .access-denied .user-name {
    margin-right: 0.5rem;
    font-weight: 500;
    font-size: 18px;
}

.settings .section-name {
    color: $grafit;
    font-size: 20px;
    margin: 16px;
}

.settings > .grid {
    width: 100%;
}

.settings > .grid > .cell {
    padding: 0 1%;
}

.settings > .grid > .cell:first-child {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-self: flex-start;
}

.settings > .grid > .cell:first-child > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
}

.settings .action {
    display: flex;
    flex-direction: row;
    margin: 24px 16px 16px 16px;
}

.email {
    color: $grafit;
    font-size: 16px;
}

.сhange-pass {
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
}

@media (max-width: 739px) {
    .сhange-pass {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.16);
    }
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .card {
    position: relative;
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
}

@media (max-width: 739px) {
    .container .card {
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
    }
}

.container .card:hover {
    transform: translateY(5px);
}

.container .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.03);
    pointer-events: none;
    z-index: 1;
}

.circle svg {
    position: relative;
    width: 150px;
    height: 150px;
    z-index: 1000;
}

svg circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: $grey-lg-text;
    stroke-width: 10;
    stroke-linecap: round;
    transform: translate(5px, 5px);
}

svg circle:nth-child(2) {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
}

.card:nth-child(1) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440px - (440px * 100) / 100);
    stroke: $orange;
}

.card:nth-child(2) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $green;
}

.card:nth-child(3) svg circle:nth-child(2) {
    stroke-dashoffset: calc(440 - (440 * 0) / 100);
    stroke: $blue;
}

.percent {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px $page-wrap;
    z-index: 1;
    margin: 0 auto;
}

.percent .number {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.percent .number h2 {
    color: $grafit;
    font-weight: 600;
    font-size: 26px;
    transition: 0.5s;
}

.card:hover .percent .number h2 {
    color: $grafit;
    font-size: 30px;
}

.card:hover .percent img {
    width: 100px;
    transition: 0.5s;
}

.percent .number h2 span {
    font-size: 24px;
    color: $grafit;
}

.card:hover .percent .number h2 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.card:hover .percent .number h3 span {
    font-size: 24px;
    color: $grafit;
    transition: 0.5s;
}

.text {
    position: relative;
    color: $grey-text;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
    transition: 0.5s;
    max-width: 200px;
}

.card:hover .text {
    color: $grey-text;
}

.description-setting {
    background-color: #ebeff6;
    padding: 2px 16px !important;
    border-radius: 10px;
    text-align: start !important;
    line-height: 25px;
    margin-top: 1rem;
    display: block !important;
}

.description-setting div {
    margin: 10px 0;
}

.description-setting b {
    font-weight: 500;
}

.deleteAccBtn {
    margin-top: 2rem;
}

.deleteAccBtn {
    background-color: $red !important;
}

.deleteAccBtn:hover {
    background-color: $red !important;
}

.level-image {
    width: 90px;
    margin-top: 1rem;
    transition: 0.5s;
}

.rewards-wrapper {
    margin: 0 10px;
    width: 100%;
}

.rewards-wrapper h3 {
    color: #2d3540;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}

.arrow-show {
    transform: rotateZ(90deg);
    cursor: pointer;
}

.show {
    // transform: rotateY(90deg);
}

.reward-list {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: scroll;
    background-color: #6cbbff3d;
    border-radius: 10px;
}

.reward-list_open {
    max-height: 600px;
    transition: max-height 0.25s ease-in;
}

.monitoring-block {
    margin: 8px 0px;
    width: 100%;
    align-items: center;
}

.monitoring-block_wrap {
    padding: 0px 5px;
}

.monitoring-block_input {
    width: 100%;
}

.referral-accruals {
    align-items: flex-start;
}
</style>
