<template lang="">
    <div>
        <ModalHeader :title="$t('popupCard.rejectedCard.title', { number: card.number })" :closeHandler="() => closeHandler()" />
        <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 px-0">
            <div>
                <StepItem :number="1" :text="$t('popupCard.rejectedCard.step1')" />

                <div class="mt-2">
                    <ContentDescription :number="number" />
                </div>
               
                <ContentComment classes="mb-2" :comment="card.comment" />

                <StepItem :number="2" :text="addImageText" />
                <div class="mt-4 flex flex-col items-center justify-center">
                    <div v-if="!image"  class="rounded-md   w-8/12">
                        <CardPhotoExample />
                    </div>
                    <p class="mt-2 text-sm text-center text-neutral-content">
                        {{ $t("popupCard.verifyCard.examplePhotoDesc") }}
                    </p>
                </div>
            </div>
            <div class="flex flex-col justify-between w-full">
                <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
                <div class="grid grid-cols-2 gap-4 w-full">
                    <div><my-button classes="w-full" size="large" :value="$t('popupCard.rejectedCard.confirm.cancel')" @click.native="closeHandler"></my-button></div>
                    <div><my-button classes="w-full" size="large" variant="primary" :value="$t('popupCard.rejectedCard.confirm.send')" @click.native="sendVerifyHandler" :spinner="spinner" /></div>
                </div>
            </div>
        </div>
    </div>

    <!-- </ModalContentWrap> -->
</template>
<script>
import { mapGetters } from "vuex";

// Components
// import ModalContentWrap from "../../ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../../ui/ImageSelect.vue";
import CardPhotoExample from "../UI/CardPhotoExample.vue";

// // Computeds
import addImageText from "../computeds/addImageText";
import isScreenMobile from "../computeds/isScreenMobile";

// // Service
import OrderService from "../../../services/OrderService";

// // UI
import StepItem from "../../ui/StepItem.vue";
import MyButton from "../../ui/button.vue";

// Modules
import ContentDescription from "./ContentDescription";
import ContentComment from "./ContentComment";

import ModalHeader from "../../ui/Modal/ModalHeader.vue";
// import NoVerifyExchange from "../modules/NoVerifyExchange.vue";

export default {
    components: {
        ModalHeader,
        ImageSelect,
        CardPhotoExample,
        StepItem,
        MyButton,
        ContentDescription,
        ContentComment
        // NoVerifyExchange
    },
    data() {
        return {
            image: null,
            file: null,
            spinner: false
        };
    },
    created(){
        console.log(this.closeHandler, 'closeHandler')
    },
    props: {
        number: Number,
        closeHandler: Function,
        card: Object,
        successCreateHandler: Function,
        errorCreateHandler: Function,
        orderFieldsData: Object
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        addImageText,
        isScreenMobile,
        comment() {
            let text = this.card?.comment?.client;
            return text;
        }
    },
    methods: {
        async sendVerifyHandler() {
            try {
                if (this.spinner) return;
                if (!this.file) return;
                this.spinner = true;
                let form = new FormData();
                form.append("data", JSON.stringify(this.orderFieldsData));
                form.append("dest", "card");
                form.append("image", this.file);
                let response = await OrderService.create(form);
                this.successCreateHandler(response.data);
            } catch (e) {
                console.error(e);
                this.errorCreateHandler(e);
            } finally {
                this.spinner = false;
            }
        },
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        }
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    }
};
</script>
<style lang="scss" scoped>
.rejectedOrderCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.rejectedOrderCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}

.rejectedOrderCardSection_btnsWrap button:last-child {
    margin-left: 10px;
}
</style>
