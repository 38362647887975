<template lang="">
    <div>
        <div :class="'card flex flex-col items-center text-neutral-content' + classes">
            <Icon :icon="iconName" height="32" color=""/>
            <p class="text-center mt-3 text-md font-normal">{{ title }}</p>
        </div>
    </div>
</template>
<script>

import { Icon } from "@iconify/vue2";

export default {
    props: {
        title: String,
        iconName: String,
        classes: {
            type: String,
            default: ""
        }
    },
    components: {
        Icon
    }
};
</script>
<style lang=""></style>
