<template lang="">
    <div class="text-center text-error leading-7">
        <div v-html="$t('popupCard.bannedCard.desc')"/>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.cardBannedText {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
}
</style>
