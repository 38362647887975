<template lang="">
    <div>
        <ModalHeader :title="$t('popupCard.rejectedCard.title', { number: card.number })" :closeHandler="closeHandler" />

        <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 px-0">
            <div>
                <StepItem :number="1" :text="$t('popupCard.rejectedCard.step1')" />

                <div class="text-neutral-content mt-2">
                    {{ $t("popupCard.rejectedCard.desc1", { number: number }) }}
                </div>

                <div class="text-neutral mt-4">
                    {{ $t("popupCard.rejectedCard.desc2") }}<br />
                    <div class="ml-3 mt-1 font-medium">
                        {{ $t("popupCard.rejectedCard.desc3") }}
                        <br />
                        {{ $t("popupCard.rejectedCard.desc4") }}
                    </div>
                </div>
                <div class="mt-2 text-error">
                    <p class="text-center text-normal">{{ $t("popupCard.rejectedCard.error1") }}</p>
                    <div v-if="comment" class="text-sm mt-2 text-center">{{ $t("popupCard.rejectedCard.commentText") }} "{{ comment }}"</div>
                </div>
                <StepItem :number="2" :text="addImageText" />
                <div class="mt-4 flex flex-col items-center justify-center">
                    <div v-if="!image" class="rounded-md   w-8/12">
                        <CardPhotoExample />
                    </div>
                    <p class="mt-2 text-sm text-center text-neutral-content">
                        {{ $t("popupCard.verifyCard.examplePhotoDesc") }}
                    </p>
                </div>
            </div>
            <div class="flex flex-col justify-between w-full">
                <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
                <div class="grid grid-cols-2 gap-4 w-full">
                    <div><my-button classes="w-full" size="large" :value="$t('popupCard.rejectedCard.confirm.cancel')" @click.native="closeHandler"></my-button></div>
                    <div><my-button classes="w-full" size="large" variant="primary" :value="$t('popupCard.rejectedCard.confirm.send')" @click.native="reverifyCardHandler" :spinner="spinner" /></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// Store
import { mapGetters } from "vuex";

// Components
// import ModalContentWrap from "../ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../ui/ImageSelect.vue";
import CardPhotoExample from "./UI/CardPhotoExample.vue";
import ModalHeader from "../ui/Modal/ModalHeader.vue";

// Computeds
import addImageText from "./computeds/addImageText";
import isScreenMobile from "./computeds/isScreenMobile";

// Service
import CardService from "../../services/CardService";

// UI
import StepItem from "../ui/StepItem.vue";
import MyButton from "../ui/button.vue";
// import ContentComment from "../Card/RejectedOrderCard/ContentComment.vue";
export default {
    data() {
        return {
            image: null,
            file: null,
            spinner: false
        };
    },
    props: {
        number: Number,
        closeHandler: Function,
        card: Object
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        addImageText,
        isScreenMobile,
        comment() {
            let text = this.card?.comment?.client;
            return text;
        }
    },
    methods: {
        async reverifyCardHandler() {
            try {
                if (this.spinner) return;
                if (!this.file) return;

                this.spinner = true;

                let form = new FormData();
                form.append("image", this.file);
                await CardService.reverify(form, this.card.hash);
            } catch (err) {
                console.error(err);
            } finally {
                this.spinner = false;
            }
        },
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        }
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    },
    components: {
        ModalHeader,
        StepItem,
        ImageSelect,
        CardPhotoExample,
        MyButton
        // ContentComment
    }
};
</script>
<style lang="scss" scoped>
.rejectedCardSection_description {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 16px;
}

.rejectedCardSection_descriptionImport {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 0px 16px;
    margin-top: 5px;
    font-weight: 600;
}

.rejectedCardSection_description > p {
    margin-bottom: 16px;
}

.rejectedCardSection_errorComment {
    color: red;
    text-align: center;
    margin-top: 10px;
}

.rejectedCardSection_errorCommentText {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}

.rejectedCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.rejectedCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.rejectedCardSection_btnsWrap button:last-child {
    margin-left: 10px;
}
</style>
