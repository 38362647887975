<template>
    <div :class="$twMerge('', classes)">
        <p v-if="error" class="text-error text-sm mb-1">
            {{ $t("agreeSelect.error") }}
        </p>
        <label class="custom-checkbox">
            <input type="checkbox" class="display-none" :checked="checked"
                @change="$emit('change', $event.target.checked)" />
            <span class="checkmark"> </span>
            <span class="text-sm text-neutral-content flex-1">
                {{ $t("agreeSelect.first") }}
                <!-- <router-link _blank class="regulations" to="/agreement" tag="span"><span
                        class="text-primary transform-underline hover:underline  underline-offset-2"> {{
                            $t("agreeSelect.second") }} </span>
                </router-link> -->
                <a class="regulations" :href="$router.resolve({ path: '/agreement' }).href" target="_blank"
                    rel="noopener noreferrer">
                    <span class="text-primary transform-underline hover:underline underline-offset-2">
                        {{ $t("agreeSelect.second") }}
                    </span>
                </a>
            </span>
        </label>
        <!-- 
       -->
    </div>
</template>

<script>
export default {
    model: {
        prop: "checked",
        event: "change"
    },
    props: {
        checked: Boolean,
        error: Boolean,
        classes: {
            type: String,
            default: ""
        }
    }
};
</script>

<style scoped>
.exchange-agree-section {
    margin: 16px;
}

.exchange-agree-section_error {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
}

/* Скрываем стандартный чекбокс */
.custom-checkbox input[type="checkbox"] {
    display: none;
}

/* Стилизация контейнера чекбокса */
.custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

/* Стилизация псевдоконтейнера (чекбокса) */
.custom-checkbox .checkmark {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    background-color: #ebeff6;
    border-radius: 5px;
    /* flex */
}

/* Стилизация "галочки" внутри чекбокса */
.custom-checkbox .checkmark::after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.custom-checkbox input {
    -webkit-appearance: none;
    appearance: none;
    transform: scale(1.5);
    transform-origin: center;
}

/* При нажатии на чекбокс */
.custom-checkbox input[type="checkbox"]:checked+.checkmark {
    background-color: #3597fa;
}

/* Отображение галочки при активном чекбоксе */
.custom-checkbox input[type="checkbox"]:checked+.checkmark::after {
    display: block;
}

/* .custom-checkbox .desc {
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    color: #646464;
    flex: 1;
}

.custom-checkbox .regulations {
    text-decoration: underline;
    display: inline-flex;
}

.custom-checkbox .regulations:hover {
    color: #3597fa;
} */
</style>
