<template lang="">
    <div class="flex justify-center">
        <div class="bg-base-100 rounded-md w-max px-6 py-4">
            <order-pair classes="justify-center" mode="change" v-if="exchange" :pair="pair" />
            <div v-if="exchange" v-html="orderNewDirectionText" class="text-center text-neutral my-2 leading-7" />
            <div class="orderChangeDirection_btns">
                <MyButton classes="w-full" size="" variant="error" :value="$t('popupCard.rejectedOrderCard.confirm.cancel')" @click.native="() => changeDirection('cancel')" :spinner="spinner1" />
                <MyButton classes="w-full" size="" variant="primary" :value="$t('popupOrder.directionOrder.confirm.create')" @click.native="() => changeDirection('create')" :spinner="spinner2" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import orderPair from "../../../components/PreOrder/orderPair";
import ExchangeService from "../../../services/ExchangeService";
import OrderService from "../../../services/OrderService";
import getExchangeAmount from "../../../utils/getExchangeAmount";
import MyButton from "../../../components/ui/button.vue";
export default {
    data() {
        return {
            spinner1: false,
            spinner2: false,
            exchange: null
        };
    },
    components: {
        orderPair,
        MyButton
    },
    props: {
        hideHandler: Function,
        order: Object
    },
    mounted() {
        this.fetchExchange();
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        orderNewDirectionText() {
            return this.$t("popupOrder.directionOrder.textHtml", { rate: this.newPairRate });
        },
        newPairRate() {
            let exchange = this.exchange;
            return `"${exchange.currency.take.name}" => "${exchange.currency.give.name}"`;
        },
        pair() {
            let amount = this.order.amount.take;

            let exchange = this.exchange;

            let sum = getExchangeAmount(exchange, "give", +amount);

            return {
                take: {
                    image: exchange.currency.take.image,
                    amount: this.order.amount.take,
                    code: exchange.currency.take.code,
                    name: exchange.currency.take.name
                },
                give: {
                    image: exchange.currency.give.image,
                    amount: sum,
                    code: exchange.currency.give.code,
                    name: exchange.currency.give.name
                }
            };
        }
    },
    methods: {
        async changeDirection(type) {
            try {
                if (this.spinner1 || this.spinner2) return;

                if (type == "create") {
                    this.spinner2 = true;
                } else {
                    this.spinner1 = true;
                }
                let data = { id: this.order._id, type };

                let response = await OrderService.changeDirection(data, this.isAuthenticated);

                if (response) {
                    if (response.status == 102) {
                        this.$router.push("/order/" + response.hash);
                    }
                    // this.SET_CURRENT_ORDER(response.data);
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.spinner1 = false;
                this.spinner2 = false;
            }
        },
        async fetchExchange() {
            try {
                let response = await ExchangeService.getOne({ id: this.order.direction.exchange });
                this.exchange = response.data;
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.orderChangeDirection {
    background-color: #0000000d;
    padding: 18px 22px;
    border-radius: 8px;
    margin-top: 20px;
}

.orderChangeDirection_desc {
    text-align: center;
    margin-top: 20px;
}

.orderChangeDirection_btns {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
}

.orderChangeDirection_btns button:first-child {
    margin-right: 10px;
}

.orderChangeDirection_btns button:last-child {
    margin-left: 10px;
}
</style>
