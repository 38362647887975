<template lang="">
    <div class="my-8">
        <div class="flex justify-center items-center mb-2">
            <div class="text-success" v-if="orderStatus == 'order-resolve'">
                <Icon icon="weui:done2-outlined" height="60" />
            </div>
            <div class="text-primary" v-if="orderStatus == 'order-process' || orderStatus == 'order-send-process' || orderStatus == 'card-process'">
                <Icon icon="eos-icons:loading" height="60" />
            </div>
            <div class="text-error" v-if="orderStatus == 'order-reject' || orderStatus == 'card-banned'">
                <Icon icon="basil:cancel-outline" height="60" />
            </div>
        </div>
        <div>
            <div class="flex justify-center mb-4">
                <h4 class="text-center text-xl text-neutral">{{ title }}</h4>
            </div>
            <h4 class="text-center mt-1 text-neutral-content" v-if="subtitle">{{ subtitle }}</h4>
        </div>

        <div class="my-3">
            <!-- <Process v-if="orderStatus == 'order-process' || orderStatus == 'card-process'" /> -->
            <Payment v-if="orderStatus == 'order-payment'" :setOrder="setOrder" :order="order" />
            <Rejected v-if="orderStatus == 'order-reject'" :order="order" />
            <Resolved v-if="orderStatus == 'order-resolve'" :order="order" />
            <BannedCard v-if="orderStatus == 'card-banned'" />
            <Reverify v-if="orderStatus == 'card-reverify'" :card="order.card" />
            <Direction v-if="orderStatus == 'order-direction'" :order="order" />
        </div>
    </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

// Components
// import Process from "./ViewStepps/Process.vue";
import { formattedCard } from "../../utils/сard";

import Payment from "./ViewStepps/Payment/Payment.vue";
import Rejected from "./ViewStepps/Rejected.vue";
import Resolved from "./ViewStepps/Resolved.vue";
import Direction from "./ViewStepps/Direction.vue";
import BannedCard from "./ViewStepps/Card/Banned.vue";
import Reverify from "./ViewStepps/Card/Reverify.vue";

export default {
    props: {
        order: Object,
        orderStatus: String,
        setOrder: Function
    },
    components: {
        // Process,
        Payment,
        Icon,
        Rejected,
        Resolved,
        BannedCard,
        Reverify,
        Direction
    },
    methods: {
        formattedCard: formattedCard
    },
    computed: {
        title() {
            let cardNumber = this?.order?.card?.number;
            switch (this.orderStatus) {
                case "order-direction":
                    return this.$t("popupOrder.directionOrder.title");
                case "card-banned": // +
                    return this.$t("popupCard.bannedCard.titleOrder", { number: formattedCard(cardNumber) });
                case "card-process": //
                    return this.$t("popupCard.processingCard.titleOrder", { number: formattedCard(cardNumber) });
                case "card-reverify": // +
                    return this.$t("popupCard.rejectedCard.title", { number: formattedCard(cardNumber) });
                case "order-process":
                    return this.$t("orderPage.viewStepper.processTitle");
                case "order-send-process":
                    return this.$t("orderPage.viewStepper.processSendTitle");
                case "order-payment": // +
                    return this.$t("orderPage.viewStepper.paymentTitle");
                case "order-reject": // +
                    return this.$t("orderPage.viewStepper.rejectedTitle");
                case "order-resolve":
                    return this.$t("orderPage.viewStepper.reolvedTitle"); // +
                default:
                    return "";
            }
        },
        titleIcon() {
            switch (this.orderStatus) {
                case "order-reject":
                case "card-banned":
                    return {
                        name: "maki:cross",
                        color: "#ff0000a8"
                    };
                case "order-resolve":
                    return {
                        name: "mdi:success",
                        color: "#00bd008f"
                    };
                default:
                    return null;
            }
        },
        subtitle() {
            switch (this.orderStatus) {
                case "order-process":
                    return this.$t("orderPage.viewStepper.processSubTitle");
                case "order-send-process":
                    return this.$t("orderPage.viewStepper.processSendSubTitle");
                case "order-payment":
                    return this.$t("orderPage.viewStepper.paymentSubTitle") + ".";
                case "order-resolve":
                    return "";
                default:
                    return "";
            }
        }
    }
};
</script>
<style lang="scss">
.viewStepperSection {
    padding: 24px 32px;
    padding-bottom: 42px;
}

.viewStepperSection .titleLine {
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewStepperSection .title {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
}

.viewStepperSection .subtitle {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    margin-top: 12px;
}

// .viewStepperSection .view {

// }
</style>
