<template lang="">
    <div class="">
        <div v-for="field in fields" :key="field._id" class="">
            <p class="text-sm text-neutral-content">{{ field.name + ": " }}</p>
            <div class="text-neutral flex items-center">
                <span>{{ field.value }}</span> <CopyBoard :title="field.name" iconName="tabler:copy" classes="ml-2" :text="field.value" />
            </div>
        </div>
    </div>
</template>
<script>
import CopyBoard from "../../../../components/ui/CopyBoard.vue";

export default {
    props: {
        fields: Array
    },
    components: {
        CopyBoard
    }
};
</script>
<style lang="scss" scoped>
.orderFields {
    margin: 20px 0px;
}

.orderFields_item {
    margin-bottom: 10px;
    // display: flex;
    // align-items: center;
    // flex-wrap: wrap;
}

.orderFields_itemName {
}
.orderFields_itemValue {
    font-weight: 600;
    display: flex;
    align-items: center;
    word-break: break-all;
}
.orderFields_itemValue span {
    margin-right: 5px;
}
</style>
