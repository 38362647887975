<template>
    <div :class="$twMerge('w-full', classes)">
        <MyInput inputType="number" v-model.trim="text" :validation="validation" :label="$t('captcha.result')">
            <div :id="link" />
        </MyInput>
    </div>
</template>

<script>
import MyInput from "./ui/input.vue";

export default {
    data() {
        return {
            result: null,
            text: "",
            validation: {
                text: "",
                isValid: true
            }
        };
    },
    components: {
        MyInput
    },
    props: {
        link: String,
        classes: {
            type: String,
            default: ""
        }
    },
    watch: {
        text() {
            this.validation = {
                text: "",
                isValid: true
            };
        }
    },
    mounted() {
        this.createCaptcha();
    },
    methods: {
        getRandomNumber() {
            return Math.floor(Math.random() * (10 - 0 + 1) + 0);
        },
        createCaptcha() {
            let link = this.link;
            document.getElementById(link).innerHTML = "";
            let number1 = this.getRandomNumber();
            let number2 = this.getRandomNumber();

            var canv = document.createElement("canvas");
            canv.id = link;
            canv.width = 80;
            canv.height = 50;
            var ctx = canv.getContext("2d");
            ctx.font = "25px Georgia";
            ctx.strokeText(`${number1} + ${number2}`, 0, 30);
            this.result = number1 + number2;
            document.getElementById(link).appendChild(canv);
        },
        validateCaptcha() {
            let isValid = false;
            if (this.text == this.result) {
                this.createCaptcha();
                isValid = true;
            } else {
                this.createCaptcha();
                this.validation = {
                    text: this.$t("captcha.error"),
                    isValid: false
                };
                isValid = false;
            }
            return isValid;
        }
    }
};
</script>

<style lang="sass" scoped></style>
