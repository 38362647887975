export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        };
    },
    methods: {
        updateWindowSize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        }
    },
    mounted() {
        window.addEventListener("resize", this.updateWindowSize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.updateWindowSize);
    }
};
