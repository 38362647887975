<template>
    <div @click="changeLangHandler" :title="this.$i18n.locale == 'ru' ? 'Русский' : 'English'" style="width: 34px;" class="cursor-pointer rounded-lg">
        <!-- <p class="name">{{ name }}</p> -->
        <img style="width: 100%;" class="overflow-hidden rounded-lg" :src="imgUrl" :alt="name" />
        <!-- <div class="lang-switcher_triange"/> -->
    </div>
</template>

<script>
export default {
    computed: {
        name() {
            return this.$i18n.locale.toUpperCase();
        },
        imgUrl() {
            var images = require.context("../assets/flags/", false, /\.png$/);
            let fileName = this.$i18n.locale === "ru" ? "ru-flag" : "en-flag";
            return images("./" + fileName + ".png");
        }
    },
    methods: {
        changeLangHandler() {
            let newLocale = this.$i18n.locale === "ru" ? "en" : "ru";
            // this.$i18n.locale = newLocale;
            localStorage.setItem("locale", newLocale);
            this.$router.go();
        }
    }
};
</script>

<style lang="sass" scoped></style>
