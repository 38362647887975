<template>
    <div>
        <!-- <div class="profile-view referral" v-if="user.serviceStatus !== 'permanent'">
            <div class="view-name" :class="'referral'">
                {{ $t("myCardsPage.title") }}
            </div>

            <div class="access-denied">
                <span v-if="hasCards" class="user-name">{{ userName }},</span>
                <span v-if="hasCards">{{ $t("myCardsPage.hasCardsText") }}</span>

                <span v-if="!hasCards" class="user-name">{{ userName }},</span>
                <span v-if="!hasCards">
                    {{ $t("myCardsPage.notHasCardsText") }}
                </span>
                <div class="spacer"></div>
                <router-link :to="'/'" class="button linkButton"> {{ $t("myCardsPage.createOrderLink") }} </router-link>
            </div>
        </div> -->

        <EmptyListStub v-if="!cards.length && loaded" title="Список карт пуст" classes=" mt-4" iconName="f7:rectangle-stack" />

        <!-- <transition-group class="grid" name="cards" tag="div"> -->
        <!-- <div @click="openAddCard" class="cell cell--4-col cell--4-col-tablet cell--4-col-phone add-card" v-if="user.serviceStatus === 'permanent'" key="addCard">
                <div class="title">
                    {{ $t("myCardsPage.addCardBtn") }}
                </div>
                <div class="icon" :title="$t('myCardsPage.addCardBtn')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="-2 -1 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square">
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                    </svg>
                </div>
            </div> -->

        <div v-if="cards.length" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div v-for="item in cards" :key="item._id">
                <item-card :card="item" @click.native.stop="card = item"></item-card>
            </div>
        </div>
        <!-- </transition-group> -->
        <CardModal :show="showModal" :closeModal="closeShowModal" :cardData="card" />

        <!-- <VueModal :style="'card-panel-style'" :defaultWidth="'fit-content'" :bg-overlay="'#00000052'" :bgPanel="'white'" :visible="showModal" @hideModal="hideCard" :animation-panel="'modal-slide-bottom'"> <card :setCard="setCard" :card="card" :hideHandler="hideCard" /> </VueModal> -->
    </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import itemCard from "../../components/Card/card.vue";
import CardService from "../../services/CardService";

// Components
import CardModal from "../../components/Card/CardModal.vue";

import EmptyListStub from "../../components/EmptyListStub.vue";

export default {
    components: {
        itemCard,
        CardModal,
        EmptyListStub
    },
    data() {
        return {
            cards: [],
            transition: null,
            card: null,
            showModal: false,
            loaded: false
        };
    },
    computed: {
        ...mapGetters(["user"]),
        userName() {
            return this.user.name || this.user.email;
        },
        hasCards() {
            return this.cards.length !== 0;
        }
    },
    mounted() {
        this.fetchCards();
        this.sockets.subscribe("update card", card => {
            if (this.card && card._id == this.card._id) {
                this.card = card;
            }
            this.cards = this.cards.map(item => {
                if (item._id === card._id) {
                    return Object.assign({}, item, card);
                } else {
                    return item;
                }
            });
        });
        this.sockets.subscribe("add card", card => {
            this.cards = [card, ...this.cards];
        });
    },
    beforeDestroy() {
        this.cards = [];
        this.sockets.unsubscribe("add card");
        this.sockets.unsubscribe("update card");
    },
    watch: {
        card() {
            let card = this.card;
            if (card) {
                this.showModal = true;
            }
        },
        showModal() {
            if (!this.showModal) {
                this.card = null;
            }
        }
    },
    methods: {
        ...mapMutations(["SET_LOADING"]),
        openAddCard() {
            this.card = {};
            this.showModal = true;
        },
        closeShowModal() {
            this.showModal = false;
            this.card = null;
        },
        async fetchCards() {
            try {
                this.SET_LOADING(true);
                let response = await CardService.getAll();
                this.cards = response.data;
                this.loaded = true;
            } catch (e) {
                console.error(e);
            } finally {
                this.SET_LOADING(false);
            }
        },
        setCard(card) {
            this.card = card;
        },
        hideCard() {
            this.showModal = false;
        }
    }
};
</script>
