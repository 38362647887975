<template lang="">
    <div class="text-error text-sm ">
        <p class="mb-2">
            {{ $t("notifInfo.first") }}
        </p>
        <p class="mb-2">
            {{ $t("notifInfo.second") }}
        </p>
        <p class="">
            {{ $t("notifInfo.third") }}
        </p>
    </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.notif-error {
    color: red;
    font-size: 14px;
    padding: 16px;
}

.notif-error p {
    margin-bottom: 8px;
}
</style>
