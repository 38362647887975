<template lang="">
    <div class="">
        <p class="text-neutral-content">{{ $t("popupCard.rejectedCard.desc1", { number: number }) }}</p>
        <div class="text-neutral mt-4">
            {{ $t("popupCard.rejectedCard.desc2") }}
            <br />
            <div class="ml-3 mt-1 font-medium">
                {{ $t("popupCard.rejectedCard.desc3") }}
                <br />
                {{ $t("popupCard.rejectedCard.desc4") }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        number: Number
    }
};
</script>
<style lang="scss" scoped>
.rejectedOrderContentDesc {
    font-size: 14px;
    line-height: 20px;
    // background-color: $white;
    padding: 16px;
}

.rejectedOrderContentDesc > p {
    margin-bottom: 16px;
}
.rejectedOrderContentDesc_import {
    font-size: 14px;
    line-height: 20px;
    // background-color: $white;
    padding: 0px 16px;
    margin-top: 5px;
    font-weight: 600;
}
</style>
