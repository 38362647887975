<template lang="">
    <div>
        <ModalHeader :title="$t('popupQr.add.title')" :closeHandler="closeHandler" />

        <div class="mt-4">
            <div class="text-neutral-content">
                {{ $t("popupQr.add.desc1") }}
            </div>
            <div class="text-neutral mt-2 font-medium my-8 flex flex-col gap-2">
                <StepItem :number="1" :text="$t('popupQr.add.descList.step1')" />
                <StepItem :number="2" :text="$t('popupQr.add.descList.step2')" />
                <StepItem :number="3" :text="$t('popupQr.add.descList.step3')" />
                <!-- <p class="">1. {{ $t("popupQr.add.descList.step1") }}</p>
                <p class="">2. {{ $t("popupQr.add.descList.step2") }}</p>
                <p class="">3. {{ $t("popupQr.add.descList.step3") }}</p> -->
            </div>
            <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
        </div>

        <div class="grid grid-cols-2 gap-4 w-full">
            <div>
                <my-button classes="w-full" size="large" :value="$t('popupCard.verifyCard.confirm.cancel')" @click.native="closeHandler"></my-button>
            </div>
            <div class="">
                <my-button :disabled="!file" classes="w-full" size="large" variant="primary" :value="$t('popupCard.verifyCard.confirm.send')" @click.native="sendQr" :spinner="spinner"></my-button>
            </div>
        </div>
    </div>
</template>
<script>
// import ModalContentWrap from "../../components/ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../ui/ImageSelect.vue";
import MyButton from "../ui/button.vue";
import ModalHeader from "../ui/Modal/ModalHeader.vue";

// Computeds
import addImageText from "../Card/computeds/addImageText";
import isScreenMobile from "../Card/computeds/isScreenMobile";

import OrderService from "../../services/OrderService";
import StepItem from "../ui/StepItem.vue";

export default {
    data() {
        return {
            image: null,
            file: null,
            spinner: false
        };
    },
    props: {
        closeHandler: Function,
        successCreateHandler: Function,
        errorDeleteHandler: Function,
        orderFieldsData: Object
    },
    computed: {
        addImageText,
        isScreenMobile
    },
    components: {
        // ModalContentWrap,
        ImageSelect,
        MyButton,
        ModalHeader,
        StepItem
    },
    methods: {
        async sendQr() {
            try {
                if (this.spinner) return;
                if (!this.file) return;

                this.spinner = true;

                let form = new FormData();

                form.append("data", JSON.stringify(this.orderFieldsData));
                form.append("dest", "qr");
                form.append("image", this.file);

                let response = await OrderService.create(form);

                this.successCreateHandler(response.data);
            } catch (e) {
                console.error(e);
                this.errorCreateHandler(e);
            } finally {
                this.spinner = false;
            }
        },
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        }
    }
};
</script>
<style lang="scss" scoped>
.preOrderAddQr {
}

.preOrderAddQr_content {
    font-size: 18px;
    line-height: 20px;
    color: $grafit;
}

.preOrderAddQr_desc {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: $grafit;
    margin-top: 20px;
}

.preOrderAddQr_desc p {
    margin-bottom: 10px;
}
.preOrderAddQr_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.preOrderAddQr_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.preOrderAddQr_btnsWrap button:last-child {
    margin-left: 10px;
    display: inline-block;
}
</style>
