<template lang="">
    <div class="flex justify-between">
        <div>
            <div class="">
                <div class="flex items-center">
                    <h5 class="text text-neutral-content text-lg">
                        <span>ID</span>: {{ " " }}<span class="font-medium text-neutral">{{ id }}</span>
                    </h5>
                    <CopyBoard title="ID" iconName="tabler:copy" classes="ml-2" :text="id" />
                    <CopyBoard :title="$t('orderPage.header.orderLink')" iconName="ci:link" classes="ml-2" :text="orderLink" />
                </div>

                <p class="text-sm text-neutral-content mt-1">
                    {{ moment(created).format("HH:mm DD.MM.YYYY") }}
                </p>
                <!-- <CopyBoard :text="id + ''" /> -->
            </div>
        </div>
        <div v-if="time" class="flex flex-col items-end">
            <p class="text-sm text-neutral-content">{{ timeTitleText }}:</p>
            <h5 class="text-xl text-neutral">
                {{ time }}
            </h5>
        </div>
    </div>
</template>
<script>
// Components
import CopyBoard from "../../components/ui/CopyBoard.vue";

// Config
import { URL } from "../../config";

// Time
import moment from "moment";

export default {
    props: {
        id: Number,
        hash: String,
        created: String,
        finished: String,
        statusPayment: Boolean,
        sendExpireDate: String || undefined,
        statusConfirmReceive: Boolean
    },
    data() {
        return {
            now: new Date(),
            timer: ""
        };
    },
    components: {
        CopyBoard
    },
    methods: {
        moment
    },
    created() {
        this.timer = setInterval(() => {
            this.now = new Date();
        }, 1000);
    },
    computed: {
        orderLink() {
            return URL.client + "order/" + this.hash;
        },
        timeToFinish() {
            return new Date(this.finished) - this.now;
        },
        time() {
            if (this.statusPayment) {
                let diff = new Date(this.finished) - this.now;
                let min = Math.floor(diff / 60000);
                let sec = Math.floor((diff % 60000) / 1000);
                return `${min}:${sec}`;
            } else if (this.statusConfirmReceive) {
                let diff = new Date(this.sendExpireDate) - this.now;
                let min = Math.floor(diff / 60000);
                let sec = Math.floor((diff % 60000) / 1000);
                if (min < 0 || sec < 0) {
                    return "";
                } else {
                    return `${min}:${sec}`;
                }
            } else {
                return undefined;
            }
        },
        timeTitleText() {
            if (this.statusPayment) {
                return this.$t("orderPage.header.paymentTimeTitle");
            } else if (this.statusConfirmReceive) {
                return this.$t("orderPage.header.receiveTimeTitle");
            } else {
                return "";
            }
        }
    },
    destroyed() {
        clearInterval(this.timer);
    }
};
</script>
<style lang="scss" scoped>
.orderHeaderSection {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
}

.orderHeaderSection .textLine {
    display: flex;
    align-items: center;
}
.orderHeaderSection .orderId {
    font-size: 22px;
    font-weight: 600;
    margin-right: 8px;
}
.orderHeaderSection .orderLink {
    font-size: 14px;
    margin-right: 8px;
    color: #3597fa;
}

.orderLinkSection {
    margin-top: 8px;
}

.orderHeaderSection_timeSection {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: flex-end;
}

.orderHeaderSection_timer {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.orderHeaderSection_dateCreated {
    font-size: 14px;
}
</style>
