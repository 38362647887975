<template>
    <div class="card">
        <div class="">
            <div class="flex justify-between">
                <span class="text-neutral text-lg font-medium">{{ formattedCard(hideCardDetails(card.number + "")) }}</span>
                <StatusCardNameBadge :statusCode="card.status" />
            </div>
            <div class="flex justify-end mt-2">
                <p class="text-sm text-neutral-content">
                    {{
                        moment(card.created)
                            .lang($i18n.locale)
                            .format("DD MMMM YYYY, HH:mm")
                    }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import StatusCardNameBadge from "./UI/StatusCardNameBadge.vue";
import moment from "moment";
import "moment/locale/ru";
import { hideCardDetails, formattedCard } from "../../utils/сard";
export default {
    components: {
        StatusCardNameBadge
    },
    props: {
        card: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        moment: moment,
        hideCardDetails,
        formattedCard
    },
    computed: {
        status() {
            console.log(this.card, "dw");
            switch (this.card.status) {
                case 101:
                    return "verification";
                case 102:
                    return "resolved";
                case 103:
                case 104:
                    return "rejected";

                default:
                    return "";
            }
        },
        number() {
            return this.card.number;
            // return `${this.card.number.replace(/\s+/g, "").replace(/(.{4})(.{4})(.{4})(.{4,6})/, "$1 $2 $3 $4")}`;
        },

        name() {
            return `${this.card.name}`;
        },

        statusText() {
            switch (this.status) {
                case "verification":
                    return this.$t("itemCard.statusName.new");
                case "resolved":
                    return this.$t("itemCard.statusName.received");
                case "rejected":
                    return this.$t("itemCard.statusName.rejected");
                default:
                    return "";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
// .card {
//     cursor: pointer;
//     display: flex;
//     flex-direction: column;
//     background-color: $white;
//     border-radius: $border-sm;
//     padding: 16px;
//     box-sizing: border-box;
//     box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
// }

// .info {
//     padding: 6px 6px;
// }

// .status-text-with-number {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 12px;
// }

// .status-text-with-fullName {
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 12px;
//     font-weight: 400;
//     color: $grafit;
// }

// .status-text-with-number .status-text {
//     color: #fff;
//     font-size: 14px;
//     padding: 5px 10px;
//     border-radius: $border-sm;
// }

// .status-text-with-number .number {
//     color: $grafit;
//     font-size: 20px;
//     font-weight: 500;
// }

// .info .date {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     color: $grafit;
//     font-size: 14px;
// }

// .info .date svg {
//     color: $grafit;
//     margin-right: 8px;
// }

// .status-text.verification {
//     background-color: $yellow;
// }

// .status-text.rejected {
//     background-color: $red;
// }

// .status-text.resolved {
//     background-color: $green;
// }

// .status-text.banned {
//     background-color: $red;
// }
</style>
