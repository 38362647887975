<template lang="">
    <div :title="title" @click="onCopiedHandler" @mouseout="clickOutside" :class="$twMerge('relative flex text-neutral-content hover:text-primary cursor-pointer transition-all duration-200', classes)">
        <Icon :icon="iconName" height="22" />
        <div v-if="copied" :style="{ height: '30px', bottom: '-210%', left: '50%', transform: 'translate(-50%, -50%)' }" :class="$twMerge('absolute bottom-full bottom-0 h-min w-max px-3 py-1 bg-primary text-white text-sm rounded shadow-md transition-opacity duration-300')">
            {{ $t("popupOrder.info.copiedAction") }}
        </div>
    </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
    props: {
        text: String,
        classes: {
            type: String,
            default: ""
        },
        iconName: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: ""
        }
    },
    components: {
        Icon
    },
    data() {
        return {
            copied: false
        };
    },
    methods: {
        clickOutside() {
            this.copied = false;
        },
        onCopiedHandler() {
            this.copied = true;
            this.copyTextToClipboard();
        },
        copyTextToClipboard() {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(this.text);
                return;
            }
            navigator.clipboard.writeText(this.text).catch(err => {
                console.error("Async: Could not copy text: ", err);
            });
        },
        fallbackCopyTextToClipboard(text) {
            let textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                let successful = document.execCommand("copy");
                let msg = successful ? "successful" : "unsuccessful";
                alert(msg);
            } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
            }
            document.body.removeChild(textArea);
        }
    }
};
</script>
<style lang="scss">
.svg-icon-copy {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.copy-icon > path {
    fill: #565656;
}
.copy-icon:hover > path {
    fill: #3597fa;
}
.copy-text {
    color: #3597fa;
    font-size: 14px;
}
</style>
