<template lang="">
    <div>
        <Modal :windowClasses="modalClasses" :windowStyle="{ width: isRejected ? undefined : modalWindowWidth }" :isOpen="show" @close="closeModal" @confirm="closeModal">
            <!-- <AddCard v-if="isAdd" :closeHandler="closeModal" :setCard="c => (this.card = c)" /> -->
            <div v-if="hasCard">
                <ResolvedCard v-if="isResolved" :closeHandler="closeModal" :card="card" />
                <RejectedCard v-if="isRejected" :closeHandler="closeModal" :card="card" />
                <ProcessCard v-if="isProcessing" :closeHandler="closeModal" :card="card" />
                <BannedCard v-if="isBanned" :closeHandler="closeModal" :card="card" />
            </div>
        </Modal>

        <!---->

        <!--  -->
    </div>
</template>
<script>
// Libs
// import VueModal from "../vue-modal/vue-modaltor.vue";

import windowSize from "../../mixins/windowSize";
import Modal from "../ui/Modal/Modal.vue";
// Components
// import AddCard from "./AddCard.vue";
import ProcessCard from "./ProcessCard.vue";
import ResolvedCard from "./ResolvedCard.vue";
import BannedCard from "./BannedCard.vue";
import RejectedCard from "./RejectedCard.vue";

// Constatns
import { CARD_STATUSES } from "../../constants/card/cardStatuses";

export default {
    mixins: [windowSize],
    data() {
        return {
            card: null
        };
    },
    props: {
        show: Boolean,
        closeModal: Function,
        cardData: Object
    },
    components: {
        // VueModal,
        Modal,
        // AddCard,
        ProcessCard,
        ResolvedCard,
        BannedCard,
        RejectedCard
    },
    computed: {
        modalWindowWidth() {
            if (this.windowWidth > 550) {
                return "500px";
            } else {
                return "100%";
            }
            // return this.windowWidth;
        },
        hasCard() {
            return !!this.card;
        },
        modalClasses() {
            // reject process banned resolve
            let result = "";

            switch (this.status) {
                case CARD_STATUSES.REJECTED_CARD:
                    result = "max-w-5xl";
                    break;
                case CARD_STATUSES.PROCESS_CARD:
                    result = "max-w-xl ";
                    break;
                case CARD_STATUSES.BANNED_CARD:
                    result = "max-w-xl";
                    break;
                case CARD_STATUSES.RESOLVED_CARD:
                    result = "max-w-xl";
                    break;
                default:
                    result = "min-w-xl";
            }

            result += " ";

            return result;
        },
        status() {
            let status = this.card?.status;
            switch (status) {
                case 100:
                    return CARD_STATUSES.VERIFY_CARD;
                case 101:
                    return CARD_STATUSES.PROCESS_CARD;
                case 102:
                    return CARD_STATUSES.RESOLVED_CARD;
                case 103:
                    return CARD_STATUSES.REJECTED_CARD;
                case 104:
                    return CARD_STATUSES.BANNED_CARD;
                default:
                    return CARD_STATUSES.ADD_CARD;
            }
        },
        isVerification() {
            return this.status === CARD_STATUSES.VERIFY_CARD;
        },
        isProcessing() {
            return this.status === CARD_STATUSES.PROCESS_CARD;
        },
        isResolved() {
            return this.status === CARD_STATUSES.RESOLVED_CARD;
        },
        isRejected() {
            return this.status === CARD_STATUSES.REJECTED_CARD;
        },
        isBanned() {
            return this.status === CARD_STATUSES.BANNED_CARD;
        },
        isAdd() {
            return this.status === CARD_STATUSES.ADD_CARD;
        }
    },
    watch: {
        show() {
            if (this.show) {
                this.sockets.subscribe("update card", upd => {
                    let currenctCardId = this?.card?._id;
                    if (currenctCardId !== upd._id) return;
                    this.card = Object.assign({}, upd);
                });
            } else {
                this.sockets.unsubscribe("update card");
            }
        },

        cardData() {
            if (this.cardData) {
                if (this.card) {
                    if (this.card._id !== this.cardData._id) {
                        this.card = this.cardData;
                    }
                } else {
                    this.card = this.cardData;
                }
            }
        }
    }
};
</script>
<style lang=""></style>
