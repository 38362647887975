<template lang="">
    <div class="card bg-base-200 rounded-md">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 px-0">
            <div class="">
                <StepItem :number="1" :text="$t('popupCard.rejectedCard.step1')" />
                <ContentDescription class="mt-2" :number="number" />
                <ContentComment class="mt-4" :comment="card.comment.client" />
                <StepItem class="mt-2" :number="2" :text="addImageText" />
                <div v-if="!image"  class="mt-4 flex flex-col items-center justify-center">
                    <div class="rounded-md   w-8/12">
                        <CardPhotoExample />
                    </div>
                    <p class="mt-2 text-sm text-center text-neutral-content">
                        {{ $t("popupCard.verifyCard.examplePhotoDesc") }}
                    </p>
                </div>
            </div>
            <div class="flex flex-col justify-between w-full">
               
                <!-- <CardPhotoExample v-if="!image" /> -->
                <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
                <my-button classes="w-full" size="large" variant="primary"  :value="$t('popupCard.rejectedCard.confirm.send')" @click.native="sendVerifyHandler" :spinner="spinner" />
            </div>
        </div>
    </div>
</template>
<script>
import StepItem from "../../../../components/ui/StepItem.vue";
import ContentDescription from "../../../../components/Card/RejectedOrderCard/ContentDescription.vue";
import ContentComment from "../../../../components/Card/RejectedOrderCard/ContentComment.vue";
import CardPhotoExample from "../../../../components/Card/UI/CardPhotoExample.vue";
import ImageSelect from "../../../../components/ui/ImageSelect.vue";
import addImageText from "../../../../components/Card/computeds/addImageText";
import isScreenMobile from "../../../../components/Card/computeds/isScreenMobile";
import MyButton from "../../../../components/ui/button.vue";
import CardService from "../../../../services/CardService";

export default {
    data() {
        return {
            file: null,
            image: null,
            spinner: false
        };
    },
    props: {
        card: Object
    },
    computed: {
        isScreenMobile: isScreenMobile,
        addImageText: addImageText
    },
    methods: {
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        },
        async sendVerifyHandler() {
            try {
                if (this.spinner) return;
                if (!this.file) return;
                this.spinner = true;

                let form = new FormData();
                form.append("image", this.file);
                await CardService.reverify(form, this.card.hash);
            } catch (err) {
                console.error(err);
            } finally {
                this.spinner = false;
            }
        }
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    },
    components: {
        CardPhotoExample,
        ImageSelect,
        StepItem,
        ContentDescription,
        ContentComment,
        MyButton
    }
};
</script>
<style lang="scss" scoped>
.orderCardReverify {
    background-color: #0000000d;
    // padding: 18px 22px;
    border-radius: 8px;
    margin-top: 20px;
}
.orderCardReverify_sbmt {
    width: 100%;
    margin-top: 20px;
}
</style>
