<template lang="">
    <div>
        <ModalHeader :title="$t('popupCard.processingCard.title', { number: formattedCard(card.number) })" :closeHandler="closeHandler" />

        <div class="mt-4">
            <div class="my-4">
                <div class="text-primary flex justify-center ">
                    <Icon icon="eos-icons:loading" height="60" />
                </div>
            </div>
            <p class="text-center text-neutral-content mb-4">{{ $t("popupCard.processingCard.desc") }}.</p>
            <div class="flex justify-between">
                <p class="text-lg font-medium text-neutral">{{ formattedCard(card.number) }}</p>
                <!-- <StatusCardNameBadge classes="ml-3" :statusCode="card.status" /> -->
            </div>
            <div class="mt-2">
                <p class="text-sm font-medium text-neutral-content">{{ card.name }}</p>
            </div>
            <div class="mt-4 flex justify-end">
                <p class="text-sm text-neutral-content">
                    {{
                        moment(card.created)
                            .lang(locale)
                            .format("DD MMMM YYYY, HH:mm")
                    }}
                </p>
            </div>

            <!--  -->
        </div>
    </div>
</template>
<script>
// Components
import ModalHeader from "../ui/Modal/ModalHeader.vue";
import { formattedCard } from "../../utils/сard";
// import StatusCardNameBadge from "./UI/StatusCardNameBadge.vue";
import moment from "moment";
import { Icon } from "@iconify/vue2";

export default {
    components: {
        ModalHeader,
        // StatusCardNameBadge,
        Icon
    },
    props: {
        closeHandler: Function,
        card: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale;
        }
    },
    methods: {
        moment: moment,
        formattedCard
    }
};
</script>
<style lang="scss" scoped>
.processCardSection {
    font-size: 16px;
    line-height: 22px;
    color: #252525;
}
</style>
