<template>
    <header class="header-wrap" :class="{ 'is-profile': isProfile }">
        <loading v-show="isLoading"></loading>
        <div class="bg-base-200 ">
            <div class="container max-w-screen-xl mx-auto flex-1 px-4 py-1 flex justify-between">
                <div class="flex items-center gap-2">
                    <Icon icon="lets-icons:time" class="text-primary" :height="iconHeight" />
                    <p class="text-neutral">
                        {{ isDesktop ? "Мы работаем " : "" }}
                        24/7
                    </p>
                </div>
                <div class="flex flex-row items-center gap-4">
                    <div class="flex items-center gap-2">
                        <Icon icon="ic:outline-mail" class="text-primary" :height="iconHeight" />
                        <a :href="`mailto:${supportEmail}`" class="text-neutral cursor-pointer hover:underline">
                            {{ supportEmail }}
                        </a>
                    </div>
                    <div class="flex items-center gap-2">
                        <Icon icon="material-symbols:chat-outline" class="text-primary" :height="iconHeight" />
                        <p @click="openChat" class="text-neutral cursor-pointer hover:underline">
                            {{ $t("header.chat") }}
                        </p>
                    </div>
                    <div><LangSwithcer /></div>
                </div>
            </div>
        </div>
        <!-- <div class="divider" /> -->

        <nav class="container max-w-screen-xl mx-auto flex-1 px-4  flex items-end justify-between py-3" v-if="isDesktop">
            <div class="flex items-end gap-2 h-full">
                <router-link class="text-xl font-bold text-neutral-content italic cursor-pointer flex items-center hover:text-primary transition-colors" :to="{ name: 'exchanger' }" tag="div"> <span class="text-primary">Ex</span><span>Bank</span> </router-link>
                <div class="flex items-center gap-4 ml-6 h-full">
                    <router-link :class="$twMerge(`text-neutral-content  cursor-pointer hover:text-primary ${isActiveRoute('agreement') ? 'text-primary' : ''}`)" :to="{ name: 'agreement' }" tag="div">{{ $t("header.termsOfUse") }}</router-link>
                    <router-link :class="$twMerge(`text-neutral-content cursor-pointer hover:text-primary ${isActiveRoute('partners') ? 'text-primary' : ''}`)" :to="{ name: 'partners' }" tag="div">{{ $t("header.partnership") }}</router-link>
                    <router-link :class="$twMerge(`text-neutral-content cursor-pointer hover:text-primary ${isActiveRoute('contacts') ? 'text-primary' : ''}`)" :to="{ name: 'contacts' }" tag="div">{{ $t("header.contacts") }}</router-link>
                </div>
            </div>

            <div class="flex items-center gap-2" v-if="!isAuthenticated">
                <my-button variant="primary" @click.native="openAuth('signIn')" size="small" :value="$t('header.login')"></my-button>
                <my-button variant="" @click.native="openAuth('signUp')" size="small" :value="$t('header.reg')"></my-button>
            </div>

            <router-link class="" :to="{ name: 'profile', params: { view: 'orders' } }" tag="div" :title="$t('header.myProfile')" v-if="isAuthenticated && isUser">
                <div class="text-neutral-content cursor-pointer hover:text-primary transition-colors">
                    {{ user.email }}
                </div>
            </router-link>
        </nav>

        <div v-if="!isDesktop">
            <nav class="container max-w-screen-xl mx-auto flex-1 px-4  flex items-end justify-between py-3">
                <router-link class="text-xl font-bold text-neutral-content italic cursor-pointer flex items-center hover:text-primary transition-colors" :to="{ name: 'exchanger' }" tag="div"> <span class="text-primary">Ex</span><span>Bank</span> </router-link>

                <div class="text-neutral-content cursor-pointer hover:text-primary transition-colors" @click.stop="toggleMobileMenu">
                    <Icon icon="ion:menu" height="32" />
                </div>
            </nav>
            <div class="relative">
                <div v-if="mobileMenu" class="border-b border-base-200 border-solid border-t pb-8 pt-4 absolute top-0 left-0 w-full bg-white z-50" ref="mobileMenu" v-click-outside="closeMobileMenu">
                    <div class="flex flex-col gap-6 mt-4">
                        <router-link :class="$twMerge(`text-neutral-content  cursor-pointer hover:text-primary ${isActiveRoute('agreement') ? 'text-primary' : ''}`)" :to="{ name: 'agreement' }" tag="div" @click.native="closeMobileMenu">
                            {{ $t("header.termsOfUse") }}
                        </router-link>
                        <router-link :class="$twMerge(`text-neutral-content  cursor-pointer hover:text-primary ${isActiveRoute('partners') ? 'text-primary' : ''}`)" :to="{ name: 'partners' }" tag="div" @click.native="closeMobileMenu">
                            {{ $t("header.partnership") }}
                        </router-link>
                        <router-link :class="$twMerge(`text-neutral-content  cursor-pointer hover:text-primary ${isActiveRoute('contacts') ? 'text-primary' : ''}`)" :to="{ name: 'contacts' }" tag="div" @click.native="closeMobileMenu">
                            {{ $t("header.contacts") }}
                        </router-link>
                    </div>

                    <div v-if="!isAuthenticated" class="flex flex-col justify-center gap-2 mt-8">
                        <my-button
                            variant="primary"
                            @click.native="
                                closeMobileMenu();
                                openAuth('signIn');
                            "
                            size=""
                            :value="$t('header.login')"
                        ></my-button>
                        <my-button
                            variant=""
                            @click.native="
                                closeMobileMenu();
                                openAuth('signUp');
                            "
                            size=""
                            :value="$t('header.reg')"
                        ></my-button>
                    </div>

                    <router-link
                        class="text-center font-bold mt-8 text-neutral-content cursor-pointer hover:text-primary transition-colors"
                        :to="{ name: 'profile', params: { view: 'orders' } }"
                        tag="div"
                        :title="$t('header.myProfile')"
                        @click.native="closeMobileMenu"
                        v-if="isAuthenticated && isUser"
                    >
                        {{ user.email }}
                    </router-link>
                </div>
            </div>
        </div>

        <div class="border-t border-base-200 border-solid" v-if="isProfile">
            <nav class="container max-w-screen-xl mx-auto flex-1  flex flex-row justify-between overflow-x-auto gap-4">
                <router-link :class="getProfileMenuItemStyle('orders')" :to="{ name: 'profile', params: { view: 'orders' } }" tag="div">
                    <Icon icon="mage:exchange-b" height="26" />
                    <div class="w-max">{{ $t("header.myOrders") }}</div>
                </router-link>

                <router-link :class="getProfileMenuItemStyle('cards')" :to="{ name: 'profile', params: { view: 'cards' } }" tag="div">
                    <Icon icon="iconamoon:credit-card-light" height="26" />
                    <div class="w-max">{{ $t("header.myCards") }}</div>
                </router-link>

                <router-link :class="getProfileMenuItemStyle('accruals')" :to="{ name: 'profile', params: { view: 'accruals' } }" tag="div">
                    <Icon icon="iconoir:hand-cash" height="26" />
                    <div>
                        {{ $t("header.accruals") }}
                    </div>
                </router-link>

                <router-link :class="getProfileMenuItemStyle('settings')" :to="{ name: 'profile', params: { view: 'settings' } }" tag="div">
                    <Icon icon="hugeicons:profile-02" height="26" />
                    <div>{{ $t("header.myProfile") }}</div>
                </router-link>

                <div :class="getProfileMenuItemStyle('logout')" @click="logout">
                    <Icon icon="mynaui:logout" height="26" />
                    <div>{{ $t("header.logout") }}</div>
                </div>
            </nav>
        </div>

        <AuthModal :view="authorization.view" :show="authorization.open" :changeView="changeView" :closeHandler="closeAuth" />
    </header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import loading from "./loading.vue";
import AuthModal from "./authorization.vue";
import button from "./ui/button.vue";
import LangSwithcer from "./LangSwithcer.vue";

import { Icon } from "@iconify/vue2";
// import discountIcon from "../assets/discount.png";

import { SUPPORT_EMAIL } from "../config";

import TextManager from "../utils/TextManager";
export default {
    components: {
        loading,
        AuthModal,
        myButton: button,
        LangSwithcer,
        Icon
    },
    data() {
        return {
            iconHeight: 24,
            supportEmail: SUPPORT_EMAIL,
            mobileMenu: false,
            authorization: {
                open: false,
                view: "signIn"
            }
        };
    },
    watch: {},
    computed: {
        ...mapGetters(["isLoading", "isDesktop", "isAuthenticated", "user"]),

        isProfile() {
            return this.$route.name === "profile";
        },
        isUser() {
            return !!this.user;
        }
    },
    methods: {
        getEmailFirstPart: TextManager.getEmailFirstPart,
        getProfileMenuItemStyle(route) {
            let isActive = this.isActiveView(route) && this.isActiveRoute("profile");
            return this.$twMerge(`flex flex-nowrap px-3 py-3  flex-row items-center gap-2 text-neutral-content  cursor-pointer hover:text-primary ${isActive ? "text-primary" : ""}`);
        },
        ...mapMutations({
            logoutUser: "LOGOUT_USER"
        }),
        isActiveView(view) {
            return this.$route?.params?.view === view;
        },
        isActiveRoute(route) {
            return this.$route.name === route;
        },
        openChat() {
            window.Chatra("openChat");
        },
        toggleMobileMenu() {
            this.mobileMenu = !this.mobileMenu;
        },
        closeMobileMenu() {
            this.mobileMenu = false;
        },
        openAuth(view) {
            if (!this.authorization.open) {
                this.authorization.view = view;
                this.authorization.open = true;
            }
        },
        closeAuth() {
            if (this.authorization.open) {
                this.authorization.open = false;
            }
        },
        changeView(view) {
            this.authorization.view = view;
        },
        logout() {
            this.logoutUser();
            this.$router.push("/");
        }
    }
};
</script>

<i18n>
    {
      "en": {
        "registration": "hello world!"
      },
      "ru": {
        "registration": "こんにちは、世界！"
      }
    }
    </i18n>

<style lang="scss" scoped>
.header-wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    margin: 0 auto;
    // height: 64px;
    z-index: 998;
    background-color: $white;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
    transition: height 0.35s;
}

.header-wrap.is-profile {
    // height: 128px;
}

.navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    // height: 64px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 16px;
    overflow: hidden;
}

.navigation-profile-wrap {
    width: 100%;
    height: 64px;
    background-color: #ffffff;
    box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.32);
}

.navigation-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
}

.navigation .logo {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    cursor: pointer;
    color: $grey-lg-text;
    font-family: Whitney, Proxima Nova, sans-serif, -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-right: 32px;
}

.navigation-item,
.navigation-profile-item {
    font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    position: relative;
    display: inline;
    text-align: center;
    height: 64px;
    line-height: 64px;
    font-size: 16px;
    color: $grey-lg-text;
    margin: 0 16px;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.navigation-item:hover:after,
.navigation-profile-item:hover:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-item.router-link-active,
.navigation-profile-item.router-link-active {
    color: $orange;
}

.navigation-item.router-link-active:after,
.navigation-profile-item.router-link-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-profile-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    color: $grey-lg-text;
    line-height: inherit;
    padding-right: 2px;
}

.navigation-profile-item .icon {
    margin-right: 16px;
}

.navigation-sign {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    color: $grafit;
    border-radius: 4px;
}

.navigation-sign .icon {
    margin-right: 12px;
}

.navigation-user {
    cursor: pointer;
    position: relative;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 16px;
}

.navigation-user:hover:after {
    height: 2px;
    width: 100%;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.slang {
    margin: 0 0.5rem;
    font-size: 14px;
    font-weight: 400;
}

.flex {
    display: flex;
    text-align: end;
    align-items: center;
}

.sign-name {
    font-weight: 600;
}

.registr {
    margin-bottom: 1rem;
}

.navigation-user.router-link-active:after {
    height: 2px;
    width: 100%;
    display: block;
    content: "";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.navigation-user .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #565656;
    margin-right: 16px;
    border-radius: 50%;
}

.navigation-user .user-name {
    color: $grafit;
    font-weight: 500;
}

/* Mobile styles */
.navigation .icon-hamburger {
    cursor: pointer;
    color: $grey-lg-text;
}

.navigation-mobile {
    width: 100%;
    height: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 64px;
    left: 0;
    background-color: #ffffff;
    padding: 0;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32), 0 -2px 2px -2px rgba(0, 0, 0, 0.32);
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 2.2s;
    z-index: 999;
}

.navigation-mobile .navigation-sign {
    flex-direction: row;
    margin: 8px 0 24px 0;
}

.navigation-mobile .navigation-user {
    margin: 0 16px 24px 16px;
}
</style>
