<template>
    <div class="grid referral-section">
        <!-- <div class="cell cell--7-col cell--4-col-tablet cell--4-col-phone referral-section-desc">
            <slot></slot>
        </div>
        <div class="cell cell--5-col cell--4-col-tablet cell--4-col-phone referral-section-copyboard">
            <div class="copyboard-title">
                {{ copyboardTitle }}
            </div>
            <div class="copyboard-zone">
                <p>{{ copyText }}</p>
                <img @click="copy" src="../../assets/copy.png" alt="Копировать" />
            </div>
        </div> -->
    </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
    props: {
        copyboardTitle: String,
        copyText: String
    },
    methods: {
        ...mapMutations(["SET_SNACK_BAR"]),
        copy() {
            copyTextToClipboard(this.copyText);

            function copyTextToClipboard(text) {
                if (!navigator.clipboard) {
                    fallbackCopyTextToClipboard(text);
                    return;
                }
                navigator.clipboard.writeText(text).catch(err => {
                    console.error("Async: Could not copy text: ", err);
                });
            }

            function fallbackCopyTextToClipboard(text) {
                let textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    let successful = document.execCommand("copy");
                    let msg = successful ? "successful" : "unsuccessful";
                    alert(msg);
                } catch (err) {
                    console.error("Fallback: Oops, unable to copy", err);
                }
                document.body.removeChild(textArea);
            }
            this.SET_SNACK_BAR({ active: true, text: "Скопировано" });
        }
    }
};
</script>

<style lang="sass" scoped>
.referral-section
    .referral-section-desc p
        color: $grafit;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 16px;
    .referral-section-copyboard
        align-self: flex-start
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px !important;
        border: 1px solid #ebeff6;
        border-radius: $border-sm;
        .copyboard-title
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px;
        .copyboard-zone
            color: $black;
            font-size: 16px;
            font-weight: 400;
            background-color: #ebeff6;
            padding: 12px 16px;
            margin-bottom: 16px;
            border-radius: $border-sm;
            display: flex
            align-items: center
            justify-content: space-between
            img
                width: 25px
                margin-left: 10px
                cursor: pointer
                transition: all 0.2s
                &:hover
                    opacity: 0.7
</style>
