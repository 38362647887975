<template>
    <button :class="$twMerge(`custom-button flex justify-center items-center`, sumClasses, sizeClasses)">
        <Icon v-if="spinner" :color="spinnerColor" icon="line-md:loading-loop" height="24" />
        <p>
            {{ value }}
        </p>
    </button>
</template>

<script>
// import spinner from "./spinner.vue";
import { Icon } from "@iconify/vue2";

export default {
    components: {
        // spinner
        Icon
    },
    props: {
        classes: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: "Отправить"
        },
        spinner: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        outline: {
            type: Boolean,
            default: false
        },
        variant: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "medium"
        }
    },
    data() {
        return {};
    },
    computed: {
        sumClasses() {
            let defaultStyle = " text-sm px-5 font-normal py-3 rounded-md hover:bg-opacity-80";

            if (this.variant == "primary") {
                defaultStyle += ` bg-primary text-white`;
            } else if (this.variant == "error") {
                defaultStyle += ` bg-error text-white`;
            } else if (this.variant == "ghost") {
                defaultStyle += ` bg-transparent text-neutral-content`;
            } else {
                defaultStyle += ` text-neutral-content bg-base-100`;
            }

            defaultStyle += ` ${this.classes}`;

            return defaultStyle;
        },
        sizeClasses() {
            let result = "";
            if (this.size == "large") {
                result += "py-3 text-md font-normal";
            } else if (this.size == "small") {
                result += "py-2.5 px-4 text-xs font-normal";
            }
            return result;
        },
        spinnerColor() {
            return "white";
        }
    },
    methods: {}
};
</script>

<style lang="scss">
.custom-button {
    transition: 0.4s all;
}
// .button {
//     cursor: pointer;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     -webkit-user-select: none;
//     user-select: none;
//     text-align: center;
//     background-color: $orange;
//     padding: 10px 16px;
//     margin: 0;
//     border-radius: $border-sm;
//     box-shadow: 2px 2px 6px 0 rgba(53, 151, 250, 0.24);
//     text-decoration: none;
//     border: none;
//     position: relative;
//     overflow: hidden;
//     text-transform: uppercase;
// }
// .button.full-width {
//     width: 100%;
// }
// .button-outline {
//     background-color: inherit;
//     border: 1px solid #acacac;
//     box-shadow: none;
// }

// .button-outline:hover {
//     color: #fff;
//     box-shadow: none;
//     background: $cur--hover;
// }

// .button:focus {
//     outline: none;
// }

// .button-value {
//     font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
//     font-size: 16px;
//     color: $white;
// }

// .button-outline .button-value {
//     color: $grafit;
// }

// .spinner-block {
//     position: absolute;
//     top: 0px;
//     left: 0px;
//     right: 0px;
//     bottom: 0px;
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
// }

// .button-value-hidden {
//     color: transparent !important;
// }
// .button.button-disabled {
//     background-color: $orange-disabled;
// }
// .button.button-outline.button-disabled {
//     background-color: inherit;
// }
</style>
