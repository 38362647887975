<template>
    <div v-if="reserves.length" class="mt-8">
        <p class="text-neutral-content text-xl font-medium text-center mb-4">
            {{ $t("exchanger.reserves.title") }}
        </p>
        <div class="card p-0">
            <div class="slider-container">
                <div class="slider my-4 flex items-center" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
                    <div v-for="(reserve, index) in reservesArray" :key="index" class="slide">
                        <div class="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-2">
                            <div v-for="(item, index) in reserve" class="flex flex-col items-center justify-center" :key="index">
                                <img class="w-10 h-10 rounded-xs object-cover" :src="$getImageUrl(item.image)" alt="" />
                                <div class="text-xs text-center mt-2 text-neutral-content">
                                    {{ item.name }}
                                </div>
                                <div class="text-neutral text-sm mt-1">
                                    <span class="font-medium">{{ item.reserve }}</span> {{ item.code }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-center text-neutral-content pb-4">
                <div class="cursor-pointer  mr-4 hover:text-neutral" @click="prevSlide">
                    <Icon icon="solar:arrow-left-linear" height="32" />
                </div>
                <div class="cursor-pointer ml-4 hover:text-neutral" @click="nextSlide">
                    <Icon icon="solar:arrow-right-linear" height="32" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import windowSize from "../../mixins/windowSize";
import { Icon } from "@iconify/vue2";

export default {
    mixins: [windowSize],
    components: {
        Icon
    },
    data() {
        return {
            reserves: [],
            currentIndex: 0,
            slideeItemCount: 3,
            windowWidth: window.innerWidth
        };
    },
    watch: {
        windowWidth() {
            this.updateBreakpoint();
        }
    },
    computed: {
        reservesArray() {
            let reserves = [...this.reserves];
            let slideeItemCount = this.slideeItemCount;

            let reserverDivider = Math.ceil(reserves.length / slideeItemCount);
            let reservesArray = [];

            for (let i = 0; i < reserverDivider; i++) {
                reservesArray.push(reserves.slice(i * slideeItemCount, (i + 1) * slideeItemCount));
            }

            return reservesArray;
        }
    },
    mounted() {
        this.fetchReserves();

        this.$nextTick(() => {
            // Этот код выполнится после обновления DOM
            this.updateBreakpoint();
        });
    },
    updated() {
        this.updateBreakpoint();
    },
    created() {
        this.updateBreakpoint();
    },
    methods: {
        fetchReserves() {
            axios.get("/exchange-reserves").then(response => {
                this.reserves = response.data;
            });
        },
        nextSlide() {
            this.currentIndex = (this.currentIndex + 1) % this.reservesArray.length;
        },
        prevSlide() {
            this.currentIndex = (this.currentIndex - 1 + this.reservesArray.length) % this.reservesArray.length;
        },
        updateBreakpoint() {
            let breakpoint = this.windowWidth;
            if (breakpoint < 640) {
                this.slideeItemCount = 3;
            } else if (breakpoint >= 640 && breakpoint < 768) {
                this.slideeItemCount = 4;
            } else if (breakpoint >= 768 && breakpoint < 1024) {
                this.slideeItemCount = 5;
            } else if (breakpoint >= 1024) {
                this.slideeItemCount = 6;
            }
        }
    }
};
</script>

<style>
.slider-container {
    position: relative;
    overflow: hidden;
    margin: auto;
}

.slider {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
}

img {
    width: 100%;
    height: auto;
}

.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}
</style>
