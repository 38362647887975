<template>
    <div v-if="isOpen">
        <!-- Overlay (фон при открытом drawer) -->
        <div class="fixed inset-0  bg-black bg-opacity-50 transition-opacity duration-300" @click="close" :style="{ zIndex: 1000 }"></div>

        <!-- Drawer (сама панель) -->
        <div :class="['fixed bg-white shadow-lg transform transition-transform duration-300 ease-in-out', drawerPositionClass, isOpen ? 'translate-x-0 translate-y-0' : translateClass, drawerSizeClass]" :style="{ zIndex: 1000 }">
            <!-- Заголовок Drawer -->
            <div class="px-6 py-4 border-b text-neutral-content">
                <h2 class="text-lg">{{ header }}</h2>
                <button class="absolute top-4 right-4" @click="close">
                    <Icon icon="mingcute:close-fill" height="25" />
                </button>
            </div>

            <!-- Контент Drawer -->
            <div class="py-4 px-6">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
    name: "Drawer",
    components: {
        Icon
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        closeHandler: Function,
        header: {
            type: String,
            require: true
        },
        direction: {
            type: String,
            default: "right", // Направление: 'right', 'left', 'top', 'bottom'
            validator: value => ["right", "left", "top", "bottom"].includes(value)
        },
        size: {
            type: String,
            default: "medium", // Размеры: 'small', 'medium', 'large', '100'
            validator: value => ["small", "medium", "large", "100"].includes(value)
        }
    },
    computed: {
        drawerPositionClass() {
            switch (this.direction) {
                case "left":
                    return "top-0 left-0 h-full";
                case "right":
                    return "top-0 right-0 h-full";
                case "top":
                    return "top-0 left-0 w-full";
                case "bottom":
                    return "bottom-0 left-0 w-full";
                default:
                    return "top-0 right-0 h-full";
            }
        },
        translateClass() {
            switch (this.direction) {
                case "left":
                    return "-translate-x-full";
                case "right":
                    return "translate-x-full";
                case "top":
                    return "-translate-y-full";
                case "bottom":
                    return "translate-y-full";
                default:
                    return "translate-x-full";
            }
        },
        drawerSizeClass() {
            if (this.size === "100") {
                return this.direction === "top" || this.direction === "bottom" ? "h-full" : "w-full";
            }

            switch (this.size) {
                case "small":
                    return this.direction === "top" || this.direction === "bottom" ? "h-1/4" : "w-1/4";
                case "large":
                    return this.direction === "top" || this.direction === "bottom" ? "h-3/4" : "w-3/4";
                default:
                    return this.direction === "top" || this.direction === "bottom" ? "h-1/2" : "w-1/2";
            }
        }
    },
    methods: {
        close() {
            // this.$emit("update:isOpen", false);
            this.closeHandler();
        }
    }
};
</script>
