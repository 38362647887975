<template>
    <div class="">
        <div class="grid grid-cols-10 gap-4 items-start">
            <div class="col-span-10 md:col-span-2 card   rounded-sm p-1 h-min flex md:flex-col">
                <MyButton @click.native="show = 'agreement'" classes="rounded-xs w-full" :variant="show == 'agreement' ? 'primary' : 'ghost'" :value="$t('agreement.title')" />
                <MyButton @click.native="show = 'aml'" classes="rounded-xs w-full" :variant="show == 'aml' ? 'primary' : 'ghost'" :value="$t('amlkyc.title')" />
            </div>
            <div class="col-span-10 md:col-span-8 card rounded-sm">
                <div class="text-center text-lg mb-4 text-neutral-content">{{ title }}</div>
                <div class="text-neutral" v-html="contentView"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyButton from "../components/ui/button.vue";
// import MyButton from

export default {
    components: {
        MyButton
    },
    data() {
        return {
            show: "agreement"
        };
    },
    computed: {
        ...mapGetters(["agreement", "amlkyc"]),
        title() {
            if (this.show == "agreement") {
                return this.$t("agreement.title");
            } else if (this.show == "aml") {
                return this.$t("amlkyc.title");
            } else {
                return "";
            }
        },
        contentView() {
            if (this.show == "agreement") {
                return this.content;
            } else if (this.show == "aml") {
                return this.aml;
            } else {
                return "";
            }
        },

        content() {
            let locale = this.$i18n.locale;

            let result = locale == "ru" ? this.agreement.rus : this.agreement.eng;

            return result;
        },
        aml() {
            let locale = this.$i18n.locale;

            let result = locale == "ru" ? this.amlkyc.rus : this.amlkyc.eng;

            return result;
        }
    }
};
</script>

<style lang="scss" scoped>
.regulations {
    color: #656565;
    font-size: 16px;
}

.regulations .card {
    background-color: $white;
    margin: 16px;
    padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.regulations .card .page-name {
    color: $grey-text;
    font-size: 28px;
    margin-bottom: 24px;
}

.regulations .card .content {
    line-height: 24px;
    color: $grafit;
}

@media (max-width: 739px) {
    .regulations .card {
        margin: 8px;
        padding: 24px 16px;
    }
}
</style>
