<template lang="">
    <div class="bg-base-100 flex rounded-md">
        <div v-for="option in options" @click="updateValue(option.value)" :class="getItemClass(option)" :key="option.value">
            {{ option.name }}
        </div>
    </div>
</template>
<script>
import { twMerge } from "tailwind-merge";

export default {
    props: {
        options: {
            type: Array,
            default: () => []
        },
        selected: {
            type: String,
            default: ""
        },
        value: {
            type: String
        },
        size: {
            type: String,
            default: "medium"
        },
        onChange: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            localValue: this.value
        };
    },
    watch: {
        value(newValue) {
            this.localValue = newValue;
        }
    },
    methods: {
        updateValue(newValue) {
            this.localValue = newValue;
            this.onChange(newValue);
            this.$emit("update:value", newValue);
        },
        getItemClass(item) {
            let addedStyle = "";
            if (this.size == "small") {
                addedStyle = "text-sm py-1";
            }
            return twMerge("cursor-pointer flex-1 px-3 py-2 rounded-md text-center text-neutral-content hover:bg-secondary transition-all duration-300", `${this.selected == item.value ? " bg-primary text-white hover:bg-primary " : ""}`, addedStyle);
        }
    }
};
</script>
<style lang=""></style>
