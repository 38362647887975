<template lang="">
    <div :class="$twMerge('flex', classes)">
        <div class="bg-primary text-white rounded-sm w-6 h-6 flex items-center justify-center mr-1">
            <div>
                {{ number }}
            </div>
        </div>
        <p class="text-neutral">
            {{ text }}
        </p>
    </div>
</template>
<script>
export default {
    props: {
        text: String,
        number: Number,
        classes: {
            type: String,
            default: ""
        }
    }
};
</script>
<style lang="scss" scoped>
.cardStep_icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    color: $white;
    background-color: $orange;
    border-radius: 50%;
    margin-right: 8px;
}
</style>
