<template>
    <div class="flex items-center justify-center">
        <div class="flex flex-col items-center card bg-base-100 w-max">
            <div v-if="addressTransaction" class="mb-3">
                <h6 class="text-neutral">
                    {{ $t("orderPage.resolved.trans") }}
                    <a target="_blank" :href="addressTransaction" class="text-primary hover:underline">
                        <span>{{ $t("orderPage.resolved.transLink") }}</span>
                        <!-- <Icon color="#3597fa" icon="akar-icons:link-out" height="16" /> -->
                    </a>
                </h6>
            </div>

            <div v-if="!!order.sendQrInfo" class="mb-3 text-center text-lg text-primary">
                {{ order.sendQrInfo }}
            </div>

            <div class="text-neutral mb-3">
                <p class="">
                    {{ $t("orderPage.resolved.thank") }}
                    <a target="_blank" :href="reviewLink" class="text-primary hover:underline"><span>Bestchange</span></a
                    >.
                </p>
            </div>

            <h6 class="text-neutral ">{{ $t("orderPage.resolved.t") }}</h6>
        </div>
    </div>
</template>

<script>
import CONSTANT_LINKS from "../../../constants/links.json";
import { getFullBlockchainTransactionUrl } from "../../../utils/currency/getFullBlockchainTransactionUrl";

// import { Icon } from "@iconify/vue2";

//  comp
export default {
    props: {
        order: Object
    },
    components: {
        // Icon
    },
    computed: {
        addressTransaction() {
            let order = this.order;
            let linkTake = order.currency.give.currency.transactionLink;
            let addressPayment = order.fields.give.find(field => field.isAddress);
            return getFullBlockchainTransactionUrl(linkTake, addressPayment?.value);
        },
        reviewLink() {
            let locale = this.$i18n.locale === "ru" ? "rus" : "eng";
            let domain = CONSTANT_LINKS.bestchange[locale];
            return domain + "/ex-bank-exchanger.html";
        }
    }
};
</script>

<style lang="scss" scoped>
.resolvedSection {
    padding: 0px 16px 16px 16px;
    margin-top: 15px;
}

.resolvedSection .textLine {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.resolvedSection .link {
    color: #3597fa;
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.resolvedSection .link:hover {
    text-decoration: underline;
}

.resolvedSection .wishText {
    margin-right: 5px;
    display: flex;
}

.resolvedSection .thank {
    text-align: center;
}

.resolvedSection .qr {
    text-align: center;
    font-weight: 600;
}
</style>
