//

function hideCardDetails(cardNumber) {
    cardNumber.trim();

    console.log(cardNumber, cardNumber.length)

    let showSymbolCount = 4;

    if (cardNumber.length > 16) {
        showSymbolCount = 6;
    }

    cardNumber = cardNumber.slice(-showSymbolCount).padStart(cardNumber.length, "*");
    console.log(cardNumber, "cardNumber");
    return cardNumber;
}

function formattedCard(cardNumber) {
    let countSymbolsBetweenSpace = Math.round(cardNumber.length / 4);

    let regex = new RegExp(`.{1,${countSymbolsBetweenSpace}}`, "g");
    let result = cardNumber.match(regex).join(" ");
    return result;
}

export { hideCardDetails, formattedCard };
