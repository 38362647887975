<template>
    <div class="flex flex-col gap-4 justify-between h-full">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div class="bg-white rounded-lg shadow-lg p-4">
                <div class="">
                    <p class="text-neutral-content ">
                        Email:
                    </p>
                    <p class="text-neutral text-lg font-normal">
                        {{ user.email }}
                    </p>
                </div>
                <p class="text-neutral-content  font-normal text-left mt-4">
                    {{ $t("settings.resendEmailDesc") }}
                </p>
                <my-button classes="mt-5" variant="primary" :value="$t('settings.resendEmailBtn')" :spinner="reverifySubmit" @click.native="resendVerifyMail" />
            </div>
            <div class="bg-white rounded-lg shadow-lg p-4">
                <p class="text-neutral-content text-center text-lg">
                    {{ $t("settings.global.title") }}
                </p>
                <div class="mt-4">
                    <my-input :label="$t('settings.global.form.name')" :validation="validations.name" v-model="name"></my-input>
                    <my-input :label="$t('settings.global.form.phone')" :validation="validations.mobile" v-model.trim="mobile"></my-input>
                    <my-button variant="primary" class="w-full mt-4" :value="$t('settings.global.form.saveBtn')" :spinner="spinner.updateUser" @click.native="updateUser"></my-button>
                </div>
            </div>
            <div class="bg-white rounded-lg shadow-lg p-4">
                <p class="text-neutral-content text-center text-lg">
                    {{ $t("settings.password.title") }}
                </p>
                <div class="mt-4 justify-end">
                    <my-input classes="mb-2" v-model.trim="password.old" inputType="password" :label="$t('settings.password.form.oldPassword')" :validation="validations.password.old"></my-input>

                    <my-input classes="mb-2" v-model.trim="password.new.first" inputType="password" :label="$t('settings.password.form.newPassword')" :validation="validations.password.new.first"></my-input>

                    <my-input classes="mb-2" v-model.trim="password.new.second" inputType="password" :label="$t('settings.password.form.newPasswordConfirm')" :validation="validations.password.new.second"></my-input>
                    <div class="">
                        <my-button variant="primary" class="w-full mt-4" :value="$t('settings.password.form.saveBtn')" :spinner="spinner.updatePassword" @click.native="changePassword"></my-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-end mt-4">
            <my-button classes="" variant="error" :value="$t('settings.deleteAccountBtn')" :spinner="spinner.deleteAccount" @click.native="deleteAccount"></my-button>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import myButton from "../../components/ui/button.vue";
import myInput from "../../components/ui/input";
import UserService from "../../services/UserService";

export default {
    components: {
        myButton,
        myInput
    },
    data() {
        return {
            reverifySubmit: false,
            levelColor: {
                radial: false,
                colors: [
                    {
                        color: "#6546f7",
                        offset: "0",
                        opacity: "1"
                    },
                    {
                        color: "lime",
                        offset: "100",
                        opacity: "0.6"
                    }
                ]
            },
            progress: 0,
            price: 0,
            colorSteps: {
                0: "GREEN",
                1: "BLUE",
                2: "RED"
            },
            description: "level",
            partner: null,
            availableBonusesPrecent: null,
            nextLevelSum: null,
            spinner: {
                updateUser: false,
                updatePassword: false,
                deleteAccount: false
            },
            name: "",
            mobile: "",
            password: {
                old: "",
                new: {
                    first: "",
                    second: ""
                }
            },
            validations: {
                name: {
                    isValid: true,
                    text: ""
                },
                mobile: {
                    isValid: true,
                    text: ""
                },
                password: {
                    old: {
                        isValid: true,
                        text: ""
                    },
                    new: {
                        first: {
                            isValid: true,
                            text: ""
                        },
                        second: {
                            isValid: true,
                            text: ""
                        }
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters(["user", "getCourse"]),
        currentLocale() {
            return this.$i18n.locale;
        },
        getLevelsPrices() {
            let prices = [
                {
                    from: 0,
                    to: 99999
                },
                {
                    from: 100000,
                    to: 499999
                },
                {
                    from: 500000,
                    to: Infinity
                }
            ];

            return prices;
        },
        getInfoAboutLevelsHTML() {
            let getLevelsPrices = this.getLevelsPrices;

            let result = "";

            getLevelsPrices.forEach((l, i) => {
                let levelCount = i + 1;

                let endRow = l.to == Infinity ? this.$t("settings.getInfoAboutLevelsHTML.andMore1") : this.$t("settings.getInfoAboutLevelsHTML.andMore2", { to: this.priceFormatting(l.to) });

                let row = this.$t("settings.getInfoAboutLevelsHTML.startRow", { levelCount, levelName: this.getLevelName(levelCount), from: this.priceFormatting(l.from), endRow });
                result += row;
            });
            return result;
        },
        levelProgress() {
            return (100 / 3) * this.user.level;
        },
        levelStars() {
            let array = [];
            for (let p = 1; p <= this.user.level; p++) {
                array.push(p);
            }
            return array;
        },

        bonusesProgress() {
            if (this.user.verified && this.user.referral && this.user.accrual) {
                if (this.allBonuses === 0) return 0;

                let precent = this.allBonuses / 100;
                let progress = Math.floor(this.availableBonusesSum / precent);
                return progress;
            } else {
                return 0;
            }
        },
        allBonuses() {
            return this.user.verified && this.user.referral && this.user.accrual ? this.user.referral.balance + this.user.accrual.balance : 0;
        },
        availableBonusesSum() {
            let sum = this.user.verified && this.user.referral && this.user.accrual ? this.user.referral.balance + this.user.accrual.availableBalance : 0;
            return sum;
        },
        availableSumTextHtml() {
            return this.$t("settings.availableSumTextHtml", { sum: this.availableBonusesSum });
        },
        getTotalExchangesSumHtml() {
            let { sum } = this.getTotalExchangeSum;
            return this.$t("settings.getTotalExchangesSumHtml", { sum });
        },

        getTotalExchangeSum() {
            let totalExchangeInRub = this.user.totalExchanged;
            return { sum: totalExchangeInRub, code: "RUB" };
        },
        levelProgressHtml() {
            let main = "";
            let side = "";
            let userLevel = this.user.level;

            if (userLevel === 1) {
                let sumForNextLevel = this.getLevelsPrices[0].to + 1 - this.getTotalExchangeSum.sum;
                sumForNextLevel = this.priceFormatting(Math.floor(sumForNextLevel));

                main = this.$t("settings.levelProgressHtml.main", { nextLevelSum: sumForNextLevel, nextLevelName: this.getLevelName(2) });
                side = this.$t("settings.levelProgressHtml.side", { coef: 2, newNum: 70, oldNum: 35 });
            } else if (userLevel === 2) {
                let sumForNextLevel = this.getLevelsPrices[1].to + 1 - +this.getTotalExchangeSum.sum;
                sumForNextLevel = this.priceFormatting(Math.floor(sumForNextLevel));

                main = this.$t("settings.levelProgressHtml.main", { nextLevelSum: sumForNextLevel, nextLevelName: this.getLevelName(3) });
                side = this.$t("settings.levelProgressHtml.side", { coef: 1.5, newNum: 105, oldNum: 70 });
            } else if (userLevel === 3) {
                main = this.$t("settings.levelProgressHtml.current", { levelName: this.getLevelName(3) });
            }
            return {
                main,
                side
            };
        }
    },
    mounted() {
        this.getPartner();
    },
    created() {
        if (this.user && (!this.name || !this.mobile)) {
            this.name = this.name || this.user.name || "";
            this.mobile = this.mobile || this.user.mobile || "";
        }
    },
    watch: {
        name() {
            if (this.name) {
                this.validations.name.isValid = true;
            }
        },
        mobile() {
            if (this.mobile) {
                this.validations.mobile.isValid = true;
                let x = this.mobile.replace(/\D/g, "").match(/(\d?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
                this.mobile = !x[3] ? "+" + x[1] + " " + x[2] : "+" + x[1] + " " + "(" + x[2] + ") " + x[3] + (x[4] ? "-" + x[4] : "") + (x[5] ? "-" + x[5] : "");
            }
        },
        "password.old"() {
            if (this.password.old) {
                this.validations.password.old.isValid = true;
            }
        },
        "password.new.first"() {
            if (this.password.new.first) {
                this.validations.password.new.first.isValid = true;
            }
        },
        "password.new.second"() {
            if (this.password.new.second) {
                this.validations.password.new.second.isValid = true;
            }
        }
    },
    methods: {
        ...mapActions(["deleteUser", "userChangePassword", "userChange"]),
        ...mapMutations(["SET_SNACK_BAR"]),
        priceFormatting(amount) {
            return new Intl.NumberFormat(this.currentLocale == "ru" ? "ru-RU" : "en-EN").format(amount).trim();
        },
        getLevelName(n) {
            switch (n) {
                case 1:
                    return this.$t("settings.getLevelName.starter");
                case 2:
                    return this.$t("settings.getLevelName.medium");
                case 3:
                    return this.$t("settings.getLevelName.pro");
                default:
                    throw new Error("Incorrect level");
            }
        },
        resendVerifyMail() {
            if (this.reverifySubmit) return;

            this.reverifySubmit = true;

            UserService.resendVerify()
                .then(() => {
                    this.SET_SNACK_BAR({ active: true, text: this.$t("settings.sendEmailVerify") });
                })
                .catch()
                .finally(() => {
                    this.reverifySubmit = false;
                });
        },
        determineColor(value) {
            let allB = this.allBonuses;
            let precent = allB / 100;
            let availablePrecents = Math.floor(value / precent);

            let index = 0;
            for (const step of [33, 66, 99]) {
                if (availablePrecents > step) {
                    index += 1;
                } else {
                    this.priceColor = this.colorSteps[index];
                    return this.colorSteps[index];
                }
            }
        },
        async getPartner() {
            if (this.user.partner) {
                await axios
                    .get("partner")
                    .then(response => {
                        this.partner = response.data;
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        },
        updateUser() {
            if (!this.spinner.updateUser) {
                if (this.mobile && this.mobile.length <= 10) {
                    this.validations.mobile.isValid = false;
                    this.validations.mobile.text = this.$t("settings.changeUserPhoneError");
                } else {
                    this.spinner.updateUser = true;
                    const successHandler = () => {
                        this.spinner.updateUser = false;
                    };

                    const errorHandler = () => {
                        this.spinner.updateUser = false;
                    };

                    let data = {
                        name: this.name,
                        mobile: this.mobile
                    };

                    this.userChange({ data, successHandler, errorHandler });
                }
            }
        },
        changePassword() {
            if (!this.spinner.updatePassword) {
                this.validations.password = {
                    old: {
                        isValid: true,
                        text: ""
                    },
                    new: {
                        first: {
                            isValid: true,
                            text: ""
                        },
                        second: {
                            isValid: true,
                            text: ""
                        }
                    }
                };

                this.spinner.updatePassword = true;
                if (this.password.old.length === 0) {
                    this.validations.password.old.isValid = false;
                    this.validations.password.old.text = this.$t("settings.changePwdHandler.enterPassword");
                    this.spinner.updatePassword = false;
                } else if (this.password.new.first.length < 6) {
                    this.validations.password.new.first.isValid = false;
                    this.validations.password.new.first.text = this.$t("settings.changePwdHandler.minLengthError");
                    this.spinner.updatePassword = false;
                } else if (this.password.new.second.length === 0 || this.password.new.second.length < 6) {
                    this.validations.password.new.second.isValid = false;
                    this.validations.password.new.second.text = this.$t("settings.changePwdHandler.minLengthError");
                    this.spinner.updatePassword = false;
                } else if (this.password.new.first !== this.password.new.second) {
                    this.password.new.second = "";
                    this.validations.password.new.first.isValid = false;
                    this.validations.password.new.first.text = this.$t("settings.changePwdHandler.dontMatchError");
                    this.spinner.updatePassword = false;
                } else {
                    const successHandler = () => {
                        this.password.old = "";
                        this.password.new.first = "";
                        this.password.new.second = "";
                        this.spinner.updatePassword = false;
                    };

                    const errorHandler = data => {
                        if (data.password) {
                            this.validations.password.old.isValid = false;
                            this.validations.password.old.text = data.error;
                        }
                        this.spinner.updatePassword = false;
                    };

                    let data = {
                        old: this.password.old,
                        new: this.password.new.first
                    };

                    this.userChangePassword({ data, successHandler, errorHandler });
                }
            }
        },
        deleteAccount() {
            if (confirm(this.$t("settings.deleteAccountHandler"))) {
                this.deleteUser();
                this.$router.push("/");
            }
        }
    }
};
</script>
