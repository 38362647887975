<template>
    <label :class="`block ` + classes">
        <div v-if="label" :class="labelClass">
            <span>
                {{ label }}
            </span>
        </div>
        <div class="pl-3" :class="inputWrapClassResult">
            <div v-if="$slots.default">
                <slot></slot>
            </div>
            <div v-if="!!preIcon" class="text-neutral-content mr-2">
                <Icon :icon="preIcon" height="22" />
            </div>
            <img :alt="imageUrlTitle" :title="imageUrlTitle" class="object-cover w-6 h-6 mr-2" :src="image || imageFullUrl" v-if="image || imageFullUrl" />
            <input
                :type="inputType"
                :placeholder="placeholder"
                :value="value"
                v-on="numberic()"
                @input="changeInput"
                :class="inputClass"
                @paste="onPaste"
                @focus="
                    $emit('focus');
                    isActive = true;
                "
                @blur="
                    $emit('blur');
                    isActive = false;
                "
            />
            <span class="text-neutral-content px-4" v-if="currency.code">{{ currency.code }}</span>
        </div>
        <span v-if="isInvalid" v-html="validation.text" class="label-text text-error "></span>
    </label>
</template>

<script>
import { twMerge } from "tailwind-merge";
import { Icon } from "@iconify/vue2";

export default {
    components: {
        Icon
    },
    props: {
        imageFullUrl: {
            type: String,
            default: ""
        },
        imageUrlTitle: {
            type: String,
            default: ""
        },
        classes: {
            type: String,
            default: ""
        },
        inputWrapClasses: {
            type: String,
            default: ""
        },
        inputClasses: {
            type: String,
            default: ""
        },
        labelClasses: {
            type: String,
            default: ""
        },
        value: {
            type: [String, Number],
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        preIcon: {
            type: String,
            default: ""
        },
        inputType: {
            type: String,
            default: "text"
        },
        currency: {
            type: Object,
            default: () => ({})
        },
        validation: {
            type: Object,
            default: () => ({})
        },
        isCard: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            isActive: false
        };
    },
    watch: {
        value() {
            // console.log(this.value, "watch value");
        },
        validation() {
            // console.log(this.validation, "input validation");
        }
    },
    computed: {
        inputWrapClassResult() {
            let r = twMerge("flex items-center bg-base-100  border-2 border-transparent border-solid focus-within:bg-secondary focus:bg-secondary rounded-sm ", `${this.isInvalid ? "border-error" : ""} ${this.inputWrapClasses}`);
            return r;
        },
        labelClass() {
            let classResult = twMerge("label mb-1 label-text", this.labelClasses);
            return classResult;
        },
        inputClass() {
            let classResult = twMerge("pr-4 py-2 bg-transparent flex-1 text-neutral placeholder-base-300 outline-none text-md focus:bg-secondary", this.inputClasses);
            return classResult;
        },
        image() {
            if (!this.objectEmpty(this.currency)) {
                return this.$getImageUrl(this.currency.image);
                // return `${this.URL.server}/images/${this.currency.image}.png`;
            } else if (this.icon) {
                return `${this.URL.server}/${this.icon}.png`;
            } else {
                return undefined;
            }
        },
        isLabel() {
            return this.value.length !== 0;
        },
        isCurrency() {
            return !this.objectEmpty(this.currency);
        },
        isInvalid() {
            return !this.objectEmpty(this.validation) ? !this.validation.isValid : false;
        }
    },
    methods: {
        numberic() {
            if (this.isCurrency) {
                return { keypress: this.isNumber };
            } else if (this.isCard) {
                return { keypress: this.isNumber };
            } else {
                return {};
            }
            // this.isCurrency
            // isCurrency ?  : {}
        },
        changeInput(e) {
            let value = e.target.value;

            if (this.isCard) {
                let number = value + "";
                number = number.split("");
                number = number.filter(n => n !== " " && !isNaN(n));
                if (number.length > 19) {
                    number = number.slice(0, 19);
                }

                let result = [];
                number.forEach((n, i) => {
                    if (++i % 4 === 0) {
                        result.push(n);
                        result.push(" ");
                    } else {
                        result.push(n);
                    }
                });
                result = result.join("").trim();
                // this.value = result;

                value = result;

                this.$emit("input", value);
            } else {
                // this.value = value;
                this.$emit("input", value);
            }
        },
        isNumber(e) {
            e = e ? e : window.event;

            const charCode = e.code ? e.which : e.keyCode;
            let isDotRepeat = false,
                count,
                amount = e.target.value,
                dotIndex = amount.indexOf(".");

            if (~dotIndex) {
                if (charCode === 48 && dotIndex === 1) return;
                count = amount.length - dotIndex;

                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    count--;
                }
                if (charCode === 44) {
                    isDotRepeat = true;
                }
            }
            if (count > this.currency.precision) {
                e.preventDefault();
            } else if (isDotRepeat) {
                e.preventDefault();
            } else if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                e.preventDefault();
            } else {
                return true;
            }
        },
        onPaste() {
            // let val = e.target.value;
            // console.log(val, "val");
            // console.log("on paste");
            // if (this.isCurrency) {
            //     // e.preventDefault();
            //     // console.log(val, "value");
            //     // this.$emit("input", val.replace(/\D/g, ""));
            // }
        }
    }
};
</script>

<style lang="scss" scoped>
// .input-wrapper {
//     position: relative;
//     margin: 16px 0;
// }

// .input-wrapper.invalid.currency {
//     margin: 16px 0 8px 0;
// }

// .label {
//     color: $grey-lg-text;
//     font-size: 14px;
// }

// .input-wrapper.invalid .label {
//     color: #e84c4c;
// }

// .input {
//     height: 38px;
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: nowrap;
//     align-items: center;
//     border-bottom: 2.2px solid #ebf2ff;
//     background: #ffffff !important; // желательно прописывать, так как в Chrome при сохранных данных оно может быть желтым
//     outline: none; // удаляет обводку в браузерах хром(желтая) и сафари(синяя)
//     color: $grafit; //цвет шрифта в обычном состоянии
// }

// .input.active {
//     border-color: rgb(53, 151, 250);
// }

// .input.invalid {
//     border-color: #e84c4c;
// }

// .input img {
//     width: 32px;
//     height: 32px;
//     border-radius: 50%;
//     object-fit: cover;
// }

// .input input {
//     width: 100%;
//     font-size: 16px;
//     color: $cur-name;
//     margin: 0;
//     padding: 0 0 2px 0;
//     border: none;
//     background-image: none;
//     background-color: transparent;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
// }

// input::-webkit-input-placeholder {
//     color: $grey-lg-text;
// }

// .input.currency {
//     height: 48px;
//     padding: 0 0 7px 0;
// }

// .input.currency input {
//     width: calc(100% - 64px);
//     padding: 12px 16px;
// }

// .input input:focus {
//     outline: none;
// }

// .input .currency-code {
//     width: 32px;
//     text-align: center;
//     color: $code;
//     font-size: 12px;
//     font-weight: 500;
//     text-transform: uppercase;
// }

// .input-wrapper.invalid .input:not(.currency) input {
//     color: #e84c4c;
// }

// .input-wrapper.invalid .input input::placeholder {
//     color: #e84c4c;
//     opacity: 1;
// }

// .input-wrapper.invalid .input input:-ms-input-placeholder {
//     color: #e84c4c;
// }

// .input-wrapper.invalid .input input::-ms-input-placeholder {
//     color: #e84c4c;
// }

// .validation-message {
//     color: #e84c4c;
//     font-size: 14px;
//     line-height: 20px;
//     margin-top: 16px;
// }

// .validation-message:first-letter {
//     text-transform: capitalize;
// }

// .validation-message > .amount {
//     font-weight: 500;
// }
</style>
