let mode = process.env.NODE_ENV;

let isProd = mode === "production";

let URL = {
    main: isProd ? "http://localhost:8081/" : "http://localhost:8080/",
    api: isProd ? "https://api.ex-bank.cc/api/" : "http://localhost:3000/api/",
    server: isProd ? "https://api.ex-bank.cc" : "http://localhost:3000",
    // server: "https://api.ex-bank.cc",
    client: "https://ex-bank.cc/"
};

const SUPPORT_EMAIL = "support@ex-bank.cc";

export { URL, SUPPORT_EMAIL };
