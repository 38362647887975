<template lang="">
    <div :class="wrapClass">
        {{ statusName }}
        
    </div>
</template>
<script>
export default {
    props: {
        statusCode: {
            type: Number,
            default: 100
        },
        classes: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: "small"
        }
    },
    computed: {
        wrapClass() {
            let statusClass = "text-white rounded-sm  text-sm px-3 py-1 ";

            switch (this.statusCode) {
                case 101:
                    statusClass += " bg-warning";
                    break;
                case 102:
                    statusClass += " bg-success";
                    break;
                case 103:
                case 104:
                    statusClass += " bg-error";
                    break;
                default:
                    statusClass = "";
            }

            if (this.size == "medium") {
                statusClass += " text-md";
            }

            let result = this.$twMerge(statusClass, this.classes);

            return result;
        },
        statusName() {
            console.log(this.statusCode, "d");
            switch (this.statusCode) {
                case 101:
                    return this.$t("itemCard.statusName.new");
                case 102:
                    return this.$t("itemCard.statusName.received");
                case 103:
                case 104:
                    return this.$t("itemCard.statusName.rejected");
                default:
                    return "";
            }
        }
    }
};
</script>
<style lang=""></style>
