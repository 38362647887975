<template>
    <div v-if="content" class="">
        <div class="card rounded-md p-2 flex justify-center items-center news-header">
            <div class="w-max-content" v-html="content"></div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["notification"]),
        content() {
            let locale = this.$i18n.locale;

            let result = locale == "ru" ? this.notification.rus : this.notification.eng;

            return result;
            // === "ru" ? "en" : "ru";
        }
    },
    watch: {}
};
</script>

<style lang="scss" scoped>
@keyframes pulseShadow {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    10% {
        transform: scale(1.02);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    }
    20% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
}

.news-header {
    animation: pulseShadow 10s ease-in-out infinite;
}
</style>
