<template>
    <div class="bg-secondary p-3 rounded-md">
        <div class="flex justify-between items-center mb-2">
            <p class="">
                <span class="text-primary text-lg">{{ new Intl.NumberFormat("ru-RU").format(reward.sum).trim() }}</span> <span class="text-neutral-sm">RUB</span>
            </p>
            <div :class="statusClass">
                {{ statusText }}
            </div>
        </div>
        <div class="flex justify-between mb-2">
            <p class="text-primary">
                {{ type }}
            </p>
            <div v-if="reward.status === 103" class="text-error text-right">
                {{ $t("rewardCard.comment") }}

                <br />
                <span class="text-right">{{ reward.comment }}</span>
            </div>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-2">
            <div class="" v-for="field in reward.fields" :key="field._id">
                <p class="text-neutral-content text-sm">{{ field.name }}:</p>
                <p class="text-neutral">{{ field.value }}</p>
            </div>
        </div>

        <div class="flex justify-end">
            <div class="text-sm text-neutral-content ">
                {{
                    moment(reward.created)
                        .lang($i18n.locale)
                        .format("DD MMMM YYYY, HH:mm")
                }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";

export default {
    props: {
        reward: Object
    },
    computed: {
        statusText() {
            switch (this.reward.status) {
                case 101:
                    return this.$t("withdrawalHistory.status.inProcess");
                case 102:
                    return this.$t("withdrawalHistory.status.paid");
                case 103:
                    return this.$t("withdrawalHistory.status.cancel");
                default:
                    return "";
            }
        },
        statusClass() {
            let classText = "reward-status";
            switch (this.reward.status) {
                case 101:
                    return classText + " " + "new";
                case 102:
                    return classText + " " + "resolve";
                case 103:
                    return classText + " " + "reject";
                default:
                    return classText;
            }
        },

        type() {
            switch (this.reward.type) {
                case "cashback":
                    return this.$t("withdrawalHistory.typeName.cashback");
                case "referral":
                    return this.$t("withdrawalHistory.typeName.referral");
                default:
                    return "";
            }
        }
    },
    methods: {
        moment: moment
    }
};
</script>

<style lang="scss" scoped>
.reward-card {
    display: flex;
    min-width: calc(33.333333% - 10px);
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 10px;
    margin: 5px 5px 15px;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.reward-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.reward-flex-bottom {
    margin-bottom: 0px;
    margin-top: 10px;
    color: #2d3540;
    font-size: 14px;
}

@media (max-width: 1200px) {
    .reward-card {
        min-width: calc(50% - 10px);
    }
}

@media (max-width: 800px) {
    .reward-card {
        min-width: calc(100% - 10px);
    }
}

.reward-field {
    margin-bottom: 6px;
}

.reward-field span {
    color: #2d3540;
}

.reward-sum {
    color: #2d3540;
    font-size: 18px;
    font-weight: 500;
}

.reward-status {
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
}

.new {
    background-color: #ffc200;
}
.resolve {
    background-color: #4bbd5c;
}

.reject {
    background-color: #d0021b;
}

.reward-comment {
    margin-top: 10px;
}

.reward-comment span {
    color: red;
    font-size: 1.2rem;
    display: block;
    margin-top: 6px;
}
</style>
