<template>
    <div>
        <Drawer :header="title" :isOpen="!!mode" :closeHandler="closeHandler" direction="bottom" size="100">
            <div>
                <Tabs size="small" :selected="mode == 'take' ? takeActiveFilter : giveActiveFilter" :onChange="changeTypeHandler" :options="typeOptions" />
                <div class="mt-8">
                    <CurrencyItem v-if="selected.take" :image="selected.take.image" :name="selected.take.name" :code="selected.take.code" class="" />
                    <CurrencyList classes="mt-8" :showReserve="mode == 'give'" :selectedId="currencyListSelectedId" :currencies="currencies" :changeCurrency="changeCurrencyHandler" />
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import Drawer from "../../components/ui/Drawer.vue";
import Tabs from "../../components/ui/Tabs.vue";
import CurrencyItem from "../../components/CurrencyItem.vue";
import CurrencyList from "../../components/ui/CurrencyList.vue";

export default {
    components: { Drawer, Tabs, CurrencyItem, CurrencyList },
    props: {
        mode: {
            type: String,
            default: ""
        },
        closeHandler: {
            type: Function,
            default: () => {}
        },
        currenciesTakeFilterNet: {
            type: Array,
            default: () => []
        },
        currenciesGiveFilterNet: {
            type: Array,
            default: () => []
        },
        takeActiveFilter: {
            type: String,
            default: ""
        },
        takeFilterList: {
            type: Array,
            default: () => []
        },
        giveActiveFilter: {
            type: String,
            default: ""
        },
        giveFilterList: {
            type: Array,
            default: () => []
        },
        selected: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        currencies() {
            switch (this.mode) {
                case "take":
                    return this.currenciesTakeFilterNet;
                case "give":
                    return this.currenciesGiveFilterNet;
                default:
                    return [];
            }
        },
        currencyListSelectedId() {
            switch (this.mode) {
                case "take":
                    return this.selected?.take?._id;
                case "give":
                    return this.selected?.give?._id;
                default:
                    return undefined;
            }
        },
        title() {
            let mode = this.mode;

            switch (mode) {
                case "take":
                    return this.$t("exchanger.html.takeTitle");
                case "give":
                    return this.$t("exchanger.html.giveTitle");
                default:
                    return "";
            }
        },
        typeOptions() {
            let list = this.mode == "take" ? this.takeFilterList : this.giveFilterList;
            return list.map(item => {
                return {
                    name: item.name,
                    value: item.id
                };
            });
        }
    },
    methods: {
        changeCurrencyHandler(curr) {
            if (this.mode == "take") {
                this.selected.take = curr;
            } else {
                this.selected.give = curr;
            }
            this.closeHandler();
        },
        changeTypeHandler(value) {
            if (this.mode == "take") {
                this.$emit("update:takeActiveFilter", value);
            } else {
                this.$emit("update:giveActiveFilter", value);
            }
        }
    }
};
</script>
