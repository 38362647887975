<!-- components/Modal.vue -->
<template>
    <div v-if="isOpen" :style="{ zIndex: 9999 }" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 transition-opacity duration-300 ease-out" @click.self="close">
        <div :class="$twMerge('bg-white max-h-screen overflow-y-auto rounded-lg shadow-lg px-6 pb-4 pt-0 transform transition-transform duration-300 ease-out', windowClasses)" :style="windowStyle">
            <div class="mt-4">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: "Диалоговое окно"
        },
        windowClasses: {
            type: String,
            default: ""
        },
        windowStyle: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        close() {
            this.$emit("close");
        }
    }
};
</script>

<style scoped>
/* Добавьте стили для плавного появления */
.scale-100 {
    transform: scale(1);
}
.scale-90 {
    transform: scale(0.9);
}
.opacity-100 {
    opacity: 1;
}
.opacity-0 {
    opacity: 0;
}
</style>
