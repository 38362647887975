<template lang="">
    <div class="">
        <div v-for="(link, i) in links" :key="i" class="text-neutral-content text-center text-lg bg-white rounded-lg p-4">
            <span> {{ $t("popupOrder.paymentOrder.paymentMethodLink.title") }} </span>
            <a target="_blank" referrerpolicy="origin" class="text-primary hover:underline cursor-pointer" :href="link">
                {{ $t("popupOrder.paymentOrder.paymentMethodLink.btn") }}
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        links: Array
    }
};
</script>
<style lang="scss" scoped>
.orderPaymentLinks {
}
.orderPaymentLinks_item {
    margin-bottom: 10px;
}
.orderPaymentLinks_item a {
    cursor: pointer;
    color: #3597fa;
    text-transform: uppercase;
}
.orderPaymentLinks_item a:hover {
    text-decoration: underline;
}
</style>
