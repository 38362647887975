<template lang="">
    <div class="">
        <ModalHeader :title="$t('popupCard.resolvedCard.title', { number: formattedCard(card.number) })" :closeHandler="closeHandler" />
        <div class="mt-4">
            <div class="my-4">
                <div class="text-success flex justify-center ">
                    <Icon icon="weui:done2-outlined" height="60" />
                </div>
                <p class="text-center text-xl text-neutral">{{ $t("popupCard.resolvedCard.statusName") }}</p>
            </div>
            <div class="flex justify-between">
                <p class="text-lg font-medium text-neutral">{{ formattedCard(card.number) }}</p>
                <!-- <StatusCardNameBadge classes="ml-3" :statusCode="card.status" /> -->
                <!--  -->
            </div>
            <div class="mt-2">
                <p class="text-sm font-medium text-neutral-content">{{ card.name }}</p>
            </div>
            <div class="mt-4 flex justify-end">
                <p class="text-sm text-neutral-content">
                    {{
                        moment(card.created)
                            .lang(locale)
                            .format("DD MMMM YYYY, HH:mm")
                    }}
                </p>
            </div>

            <!-- {{ $t("popupCard.resolvedCard.desc") }} -->
        </div>
    </div>
</template>
<script>
// Components
// import ModalContentWrap from "../ModalContentWrap/ModalContentWrap.vue";
import ModalHeader from "../ui/Modal/ModalHeader.vue";
import { formattedCard } from "../../utils/сard";
// import StatusCardNameBadge from "./UI/StatusCardNameBadge.vue";
import moment from "moment";
import { Icon } from "@iconify/vue2";

export default {
    components: {
        // ModalContentWrap,
        ModalHeader,
        Icon
        // StatusCardNameBadge
    },
    props: {
        closeHandler: Function,
        card: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        locale() {
            return this.$i18n.locale;
        }
    },
    methods: {
        formattedCard,
        moment: moment
    }
};
</script>
<style lang="scss" scoped>
.resolvedCardSection {
    font-size: 16px;
    line-height: 22px;
    color: #252525;
}
</style>
