import { URL } from "../config";

export const urlMixin = {
    methods: {
        $getImageUrl(imageName) {
            // return `https://api.ex-bank.cc/images/${imageName}.png`;
            return `${URL.server}/images/${imageName}.png`;
        }
    }
};
