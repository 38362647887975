<template lang="">
    <div class="mt-2">
        <EmptyListStub v-if="!user.verified" :title="`${user.name || user.email}, ` + $t('referralPage.notVerifiedTitle')" classes="" iconName="mdi:lock-outline" />
        <div v-if="user.verified">
            <div  class="grid grid-cols-1 sm:grid-cols-2 gap-4 px-0">
                <div class="card">
                    <p class="text-neutral-content text-center text-lg">
                        {{ $t("referralPage.title") }}
                    </p>
                    <div class="mt-4">
                        <p class="text-neutral text-center text-sm">
                            {{ $t("referralPage.regulations.p1") }}
                        </p>
                        <p class="text-neutral mt-4 text-center text-sm">
                            {{ $t("referralPage.regulations.p2") }}
                        </p>
                        <div class="bg-secondary px-4 py-2 rounded-sm mt-4">
                            <p class="text-neutral-content text-md">
                                {{ $t("referralPage.referralLinkTitle") }}
                            </p>
                            <div class="flex items-center justify-between text-neutral mt-3">
                                <p class="text-sm">{{ referralLink() }}</p>
                                <div class="hover:text-primary cursor-pointer transition-all duration-200" @click="copyReferralLink" alt="Копировать">
                                    <Icon icon="tabler:copy" height="25" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-6" v-if="user.referral && referralHistory">
                        <div class="mb-6">
                            <div class="flex justify-between ">
                                <div>
                                    <p class="text-neutral-content">
                                        {{ $t("referralPage.referralRewards.total") }}
                                    </p>
                                    <div class="">
                                        <span class="text-primary text-lg"> {{ new Intl.NumberFormat("ru-RU").format(getUserReferralBalance).trim() }}</span> <span class="text-sm text-neutral-content">RUB</span>
                                    </div>
                                </div>

                                <MyButton variant="primary" v-if="getUserReferralBalance !== 0" :spinner="spinner.referral" :value="$t('referralPage.referralRewards.orderAPayoutBtn')" @click.native.stop="orderPayment('referral')"></MyButton>
                            </div>
                            <div v-if="message.referral" class="text-error text-sm mt-2 ">
                                {{ message.referral }}
                            </div>
                        </div>

                        <p class="mb-2 text-neutral-content text-md">
                            {{ $t("referralPage.referralRewards.title") }}
                        </p>
                        <div v-if="!referralHistory.length" class="text-neutral-content flex flex-col items-center">
                            <Icon icon="f7:rectangle-stack" height="28" color="" />
                            <p class="text-sm mt-1">{{ $t("referralPage.referralRewards.notRewards") }}</p>
                        </div>
                        <div v-else>
                            <div class="max-h-80 overflow-y-auto">
                                <div v-for="item in referralHistory" :key="item._id" class="flex items-center justify-between bg-base-100 rounded-sm px-3 py-2 mb-2">
                                    <p class="text-neutral flex items-center">
                                        <span>{{ new Intl.NumberFormat("ru-RU").format(item.sum).trim() }}</span> <span class="text-sm text-neutral-content ml-2">RUB</span>
                                    </p>
                                    <p class="text-xs text-neutral-content">
                                        {{ item.date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <p class="text-neutral-content text-center text-lg">
                        {{ $t("cashbackPage.title") }}
                    </p>
                    <div class="mt-4">
                        <p class="text-neutral text-center text-sm">
                            {{ $t("cashbackDesc.p1") }}
                        </p>
                        <p class="text-neutral mt-4 text-center text-sm">
                            {{ $t("cashbackDesc.p2") }}
                        </p>
                    </div>
                    <div class="mt-6" v-if="user.accrual && accrualHistory">
                        <div class="mb-6">
                            <div class="flex justify-between ">
                                <div>
                                    <p class="text-neutral-content">
                                        {{ $t("referralPage.referralRewards.total") }}
                                    </p>
                                    <div class="">
                                        <span class="text-primary text-lg"> {{ new Intl.NumberFormat("ru-RU").format(getUserAccrualBalance).trim() }}</span> <span class="text-sm text-neutral-content">RUB</span>
                                    </div>
                                </div>

                                <MyButton variant="primary" v-if="getUserAccrualBalance !== 0" :spinner="spinner.bonus" :value="$t('referralPage.referralRewards.orderAPayoutBtn')" @click.native.stop="orderPayment('cashback')"></MyButton>
                            </div>
                            <div v-if="message.bonus" class="text-error text-sm mt-2 ">
                                {{ message.bonus }}
                            </div>
                        </div>

                        <p class="mb-2 text-neutral-content text-md">
                            {{ $t("referralPage.bonuses.title") }}
                        </p>
                        <div v-if="!accrualHistory.length" class="text-neutral-content flex flex-col items-center">
                            <Icon icon="f7:rectangle-stack" height="28" color="" />
                            <p class="text-sm mt-1">{{ $t("referralPage.referralRewards.notRewards") }}</p>
                        </div>
                        <div v-else>
                            <div class="max-h-80 overflow-y-auto">
                                <div v-for="item in accrualHistory" :key="item._id" class="flex items-center justify-between bg-base-100 rounded-sm px-3 py-2 mb-2">
                                    <p class="text-neutral flex items-center">
                                        <span>{{ new Intl.NumberFormat("ru-RU").format(item.sum).trim() }}</span> <span class="text-sm text-neutral-content ml-2">RUB</span>
                                    </p>
                                    <p class="text-xs text-neutral-content">
                                        {{ item.date }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-4">
                <p class="text-neutral-content text-center text-lg">
                    {{ $t("referralPage.rewardConclusions.title") }}
                </p>
                <div class="mt-6 max-h-80 overflow-y-auto rounded-md">
                    <reward-card class="mb-3" v-for="reward in rewards" :key="reward._id" :reward="reward" />
                </div>
                <div v-if="!rewards.length" class="text-neutral-content flex flex-col items-center mt-2">
                    <Icon icon="f7:rectangle-stack" height="28" color="" />
                    <p class="text-sm mt-1">{{ $t("referralPage.referralRewards.notWithdrawRewards") }}</p>
                </div>
            </div>
        </div>
        <RewardWindow :showRewardMode="showRewardMode" :addReward="addReward" @close-reward="closeReward" />
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

// import RefSection from "./RefSection.vue";
import { Icon } from "@iconify/vue2";
import EmptyListStub from "../../components/EmptyListStub.vue";
import MyButton from "../../components/ui/button.vue";
import RewardWindow from "../../components/reward.vue";
import RewardCard from "../../components/item/reward.vue";

// import VueModal from "../../components/vue-modal/vue-modaltor.vue";
// import ItemAccrual from "../../components/item/accrual.vue";

import axios from "axios";
export default {
    components: {
        EmptyListStub,
        Icon,
        MyButton,
        RewardWindow,
        RewardCard
        // VueModal
        // ItemAccrual
        // RefSection
    },
    created() {
        this.getRewards();

        this.sockets.subscribe("update reward", reward => {
            this.rewards = this.rewards.map(r => (r._id === reward._id ? reward : r));
        });
    },
    data() {
        return {
            rewards: [],
            isDrawerOpen: true,
            spinner: {
                referral: false,
                bonus: false,
                promocode: false,
                partner: false
            },
            showRewardMode: "",
            message: {
                referral: "",
                bonus: ""
            }
        };
    },
    computed: {
        ...mapGetters(["user", "isDesktop", "getCourse"]),
        referralHistory() {
            let user = this.user;
            if (user.referral && user.referral.history) {
                let l = user.referral.history.filter(i => i.sum !== 0);
                return l;
            } else {
                return [];
            }
        },
        accrualHistory() {
            let user = this.user;
            if (user.accrual && user.accrual.history) {
                return user.accrual.history.filter(i => i.sum !== 0);
            } else {
                return [];
            }
        },
        getUserAccrualBalance() {
            return this.user.accrual.availableBalance;
        },
        getUserReferralBalance() {
            return this.user.referral.balance;
        }
    },
    methods: {
        ...mapMutations(["SET_SNACK_BAR"]),
        async getRewards() {
            await axios
                .get("/reward")
                .then(response => {
                    this.rewards = response.data;
                })
                .catch(e => {
                    console.error(e);
                });
        },
        addReward(reward) {
            this.rewards.unshift(reward);
            this.showRewardMode = "";
        },
        closeReward() {
            this.showRewardMode = "";
        },
        referralLink() {
            return "https://ex-bank.cc/?ref=" + this.user.referral.link;
        },
        orderPayment(mode) {
            if (mode === "referral") {
                if (this.user.referral.balance < 100) {
                    this.message.referral = this.$t("referralPage.orderPaymentHandler.minSumError");

                    setTimeout(() => {
                        this.message.referral = "";
                    }, 3200);
                } else {
                    this.showRewardMode = "referral";
                }
            } else if (mode === "cashback") {
                if (this.user.accrual.availableBalance < 100) {
                    this.message.bonus = this.$t("referralPage.orderPaymentHandler.minSumError");
                    setTimeout(() => {
                        this.message.bonus = "";
                    }, 3200);
                } else {
                    this.showRewardMode = "cashback";
                }
            }
        },
        copyReferralLink() {
            copyTextToClipboard(this.referralLink());

            function copyTextToClipboard(text) {
                if (!navigator.clipboard) {
                    fallbackCopyTextToClipboard(text);
                    return;
                }
                navigator.clipboard.writeText(text).catch(err => {
                    console.error("Async: Could not copy text: ", err);
                });
            }

            function fallbackCopyTextToClipboard(text) {
                let textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    let successful = document.execCommand("copy");
                    let msg = successful ? "successful" : "unsuccessful";
                    alert(msg);
                } catch (err) {
                    console.error("Fallback: Oops, unable to copy", err);
                }
                document.body.removeChild(textArea);
            }
            this.SET_SNACK_BAR({ active: true, text: "Скопировано" });
        }
    }
};
</script>
<style lang=""></style>
