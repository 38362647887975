<template lang="">
    <div :class="$twMerge('mt-2 text-error', classes)">
        <p class="text-center text-normal">{{ $t("popupCard.rejectedCard.error1") }}</p>
        <div v-if="comment" class="text-sm mt-2 text-center">{{ $t("popupCard.rejectedCard.commentText") }} "{{ comment }}"</div>
    </div>
</template>
<script>
export default {
    props: {
        comment: String,
        classes: {
            type: String,
            default: ""
        }
    }
};
</script>
<style lang="scss" scoped>
.rejectedCardComment {
    font-size: 14px;
    line-height: 20px;
    color: red;
    text-align: center;
    margin-top: 10px;
}

.rejectedCardComment_text {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}
</style>
