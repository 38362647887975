<template lang="">
    <div :class="concatClass">
        <div class="flex items-center">
            <img :alt="name" class="w-6 h-6 object-cover rounded-xs mr-1" :src="$getImageUrl(image)" />
            <p class="text-neutral mr-2">
                {{ name }}
            </p>
            <p class="text-neutral-content">
                {{ code }}
            </p>
        </div>
        <div v-if="iconName">
            <Icon class="text-neutral-content hover:text-neutral" height="28" :icon="iconName" />
        </div>
    </div>
</template>
<script>
import { twMerge } from "tailwind-merge";
import { Icon } from "@iconify/vue2";

export default {
    components: {
        Icon
    },
    props: {
        image: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            default: ""
        },
        code: {
            type: String,
            default: ""
        },
        iconName: {
            type: String,
            default: ""
        },
        classes: {
            type: String,
            default: ""
        }
    },
    computed: {
        imageURL() {
            return `${this.URL.server}/images/${this.image}.png`;
        },
        concatClass() {
            return twMerge("flex  justify-between items-center p-4 rounded-md bg-base-100", this.classes);
        }
    }
};
</script>
<style lang=""></style>
