<template>
    <div :class="$twMerge('flex items-center grid grid-cols-7', classes)">
        <div class="col-span-3 flex justify-end items-center">
            <img alt="" class="w-8 h-8 mr-3 rounded-full" :src="image.take" v-if="image.take" />
            <div>
                <div class="text-lg text-neutral">
                    {{ pair.take.amount }} <span class="text-md text-neutral-content"> {{ pair.take.code }}</span>
                </div>
                <div class="text-sm text-neutral-content">{{ pair.take.name }}</div>
            </div>
        </div>

        <div class="text-neutral col-span-1 flex justify-center">
            <Icon icon="hugeicons:exchange-01" height="28" />
        </div>

        <div class="col-span-3 flex  items-center">
            <img alt="" class="w-8 h-8 mr-3 rounded-xs" :src="image.give" v-if="image.give" />
            <div>
                <div class="text-lg text-neutral">
                    {{ pair.give.amount }}<span class="text-md text-neutral-content"> {{ pair.give.code }}</span>
                </div>
                <div class="text-sm text-neutral-content">{{ pair.give.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
    components: {
        Icon
    },
    props: {
        pair: Object,
        mode: String,
        classes: {
            type: String,
            default: ""
        }
    },

    computed: {
        image() {
            return {
                take: this.pair ? `${this.URL.server}/images/${this.pair.take.image}.png` : "",
                give: this.pair ? `${this.URL.server}/images/${this.pair.give.image}.png` : ""
            };
        }
    }
};
</script>

<style lang="scss" scoped>
.order-exchange .time {
    font-size: 14px;
}
.order-exchange .grid {
    width: 100%;
    align-items: center;
}
.order-exchange .grid > div {
    margin: 16px 0px;
}
.order-exchange .currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
.order-exchange .currency .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    -o-object-fit: cover;
    object-fit: cover;
}
.order-exchange .currency .amount {
    font-size: 20px;
    margin-bottom: 8px;
}
.order-exchange .currency .name {
    color: $grafit;
    font-size: 16px;
}
.order-exchange .icon-exchange {
    color: #acacac;
}
.change-arrow {
    transform: rotate(-90deg);
}
</style>
