<template>
    <div id="app">
        <div>
            <!-- <myNewsHeader></myNewsHeader> -->
            <my-header></my-header>
        </div>

        <div class="container max-w-screen-xl mx-auto flex-1 px-4">
            <div class="flex flex-col my-5 " :class="{ 'is-profile': isProfile }">
                <transition :name="transition" mode="out-in">
                    <keep-alive>
                        <router-view class="" />
                    </keep-alive>
                </transition>
            </div>
        </div>
        <div>
            <snack-bar></snack-bar>
            <my-footer></my-footer>
            <!-- <VueModal :bg-overlay="'#00000052'" :defaultWidth="'fit-content'" :bgPanel="'white'" :visible="showModal" @hideModal="hideOrder" :animation-panel="'modal-slide-bottom'"> <order :hideHandler="hideOrder" :order="currentOrder"/></VueModal> -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

// import VueModal from "./components/vue-modal/vue-modaltor.vue";
// import newsHeader from "./components/newsHeader.vue";
import header from "./components/header.vue";
import footer from "./components/footer.vue";
import snackBar from "./components/ui/snackBar.vue";

// import order from "./components/popup/order/order";

// import OrderService from "./services/OrderService";

import AdNotify from "./AdNotify.vue";

import { getStorageValue, setStorageValue } from "./utils/localStorageManage";

export default {
    components: {
        // Icon,
        myHeader: header,
        // myNewsHeader: newsHeader,
        myFooter: footer,
        // VueModal,
        snackBar
        // order
        // AdNotify
    },
    data() {
        return {
            transition: null,
            subscribeId: "",
            resetOrder: false,
            showModal: false
        };
    },
    created() {
        let timer = 20000;
        let than = this;
        let locale = this.$i18n.locale;

        setTimeout(() => {
            let adContent = locale == "ru" ? than.getAd?.rus : than.getAd?.eng;
            if (!adContent) return;

            let now = Date.now();

            let addExpireTime = getStorageValue("ad-expire-time");

            if (!addExpireTime) {
                setStorageValue("ad-expire-time", now + 3 * 24 * 60 * 60 * 1000);
            } else {
                if (addExpireTime > now) return;
            }

            this.$toast.info(<AdNotify />, {
                position: "top-left",
                icon: false,
                timeout: false
            });
        }, timer);
    },
    mounted() {
        // this.fetchUnauthOrder();
    },
    beforeDestroy() {
        // console.log("app destroyed");
    },
    computed: {
        ...mapGetters(["isAuthenticated", "currentOrder", "getAd"]),
        page() {
            return this.$route.meta.page;
        },
        isProfile() {
            return this.$route.name === "profile";
        }
    },
    watch: {
        currentOrder() {
            let o = this.currentOrder;
            if (o) {
                this.showModal = true;
            }
            if (!this.isAuthenticated && o && this.subscribeId !== o._id) {
                this.subscribeId = o._id;
                this.sockets.subscribe(`update_unauth_order_${o._id}`, order => {
                    this.UPDATE_ORDER(order);
                });
            }
        },
        showModal() {
            if (!this.showModal) {
                this.SET_CURRENT_ORDER(null);
            }
        },
        page(to, from) {
            this.changeTransition(to, from);
        }
    },

    methods: {
        ...mapMutations(["UPDATE_ORDER", "SET_CURRENT_ORDER"]),
        // async fetchUnauthOrder() {
        //     try {
        //         let response = await OrderService.getUnauthOrder();
        //         if (response && response.data) {
        //             this.SET_CURRENT_ORDER(response.data);
        //         }
        //     } catch (e) {
        //         console.error(e);
        //         localStorage.removeItem("orderToken");
        //     }
        // },
        changeTransition(to, from) {
            this.transition = to > from ? "slide-left" : "slide-right";
        },
        hideOrder() {
            this.showModal = false;
        }
    }
};
</script>
<style>
/* Reset CSS */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
</style>

<style scoped>
html {
    height: -webkit-fill-available;
}

#app {
    position: relative;
    min-height: -webkit-fill-available;
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.page-content-wrap {
    flex-grow: 1;
}

.page-wrap {
    min-height: calc(100% - 112px);
    width: 100%;
    overflow-x: hidden;
    flex-grow: 1;
}

.page-wrap.is-profile {
    min-height: calc(100% - 176px);
}

.page {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.slide-left-enter-active,
.slide-right-enter-active,
.slide-left-leave-active,
.slide-right-leave-active {
    transition: all 0.32s;
}

.slide-left-enter,
.slide-right-enter,
.slide-left-leave-active,
.slide-right-leave-active {
    opacity: 0;
}

.slide-left-enter,
.slide-right-leave-active {
    transform: translateX(80px);
}

.slide-left-leave-active,
.slide-right-enter {
    transform: translateX(-80px);
}

@media (max-width: 749px) {
    .page-wrap {
        min-height: calc(100% - 228px);
    }

    .page-wrap.is-profile {
        min-height: calc(100% - 292px);
    }
}
</style>

<style>
/*
@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/ProximaNova/ProximaNova-Regular.eot');
    src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
    url('/fonts/ProximaNova/ProximaNova-Regular.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/ProximaNova/ProximaNova-Semibold.eot');
    src: local('Proxima Nova Semibold'), local('ProximaNova-Semibold'),
    url('/fonts/ProximaNova/ProximaNova-Semibold.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
}
@font-face {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/ProximaNova/ProximaNova-Bold.eot');
    src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
    url('/fonts/ProximaNova/ProximaNova-Bold.woff') format('woff'),
    url('/fonts/ProximaNova/ProximaNova-Bold.ttf') format('truetype');
}
*/

@font-face {
    font-family: "Whitney";
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/Whitney/Whitney-Medium.eot");
    src: local("Whitney Medium"), local("Whitney-Medium"), url("/fonts/Whitney/Whitney-Medium.woff") format("woff"), url("/fonts/Whitney/Whitney-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Whitney";
    font-style: normal;
    font-weight: 500;
    src: url("/fonts/Whitney/Whitney-Semibold.eot");
    src: local("Whitney Semibold"), local("Whitney-Semibold"), url("/fonts/Whitney/Whitney-Semibold.woff") format("woff"), url("/fonts/Whitney/Whitney-Semibold.ttf") format("truetype");
}

@font-face {
    font-family: "Whitney";
    font-style: normal;
    font-weight: 600;
    src: url("/fonts/Whitney/Whitney-Bold.eot");
    src: local("Whitney Bold"), local("Whitney-Bold"), url("/fonts/Whitney/Whitney-Bold.woff") format("woff"), url("/fonts/Whitney/Whitney-Bold.ttf") format("truetype");
}

html,
body {
    height: 100%;
    width: 100%;
    font-family: "Whitney", sans-serif;
    background-color: #f5f3f1;
}

html *,
html :after,
html :before {
    box-sizing: border-box;
}

body {
    -webkit-tap-highlight-color: transparent;
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

a,
img {
    outline: none;
}

.grid {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin: 0 auto;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.grid.grid--no-spacing {
    padding: 0;
}

.cell {
    box-sizing: border-box;
}

.cell--top {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.cell--middle {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.cell--bottom {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.cell--stretch {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
}

.grid.grid--no-spacing > .cell {
    margin: 0;
}

.cell--order-1 {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.cell--order-2 {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
}

.cell--order-3 {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}

.cell--order-4 {
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
}

.cell--order-5 {
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
}

.cell--order-6 {
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
}

.cell--order-7 {
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
}

.cell--order-8 {
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
}

.cell--order-9 {
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
}

.cell--order-10 {
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
}

.cell--order-11 {
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
}

.cell--order-12 {
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
}

@media (max-width: 749px) {
    .grid {
        padding: 8px;
    }

    .cell {
        margin: 8px;
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell {
        width: 100%;
    }

    .cell--hide-phone {
        display: none !important;
    }

    .cell--order-1-phone.cell--order-1-phone {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .cell--order-2-phone.cell--order-2-phone {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .cell--order-3-phone.cell--order-3-phone {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }

    .cell--order-4-phone.cell--order-4-phone {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }

    .cell--order-5-phone.cell--order-5-phone {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }

    .cell--order-6-phone.cell--order-6-phone {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }

    .cell--order-7-phone.cell--order-7-phone {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }

    .cell--order-8-phone.cell--order-8-phone {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }

    .cell--order-9-phone.cell--order-9-phone {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }

    .cell--order-10-phone.cell--order-10-phone {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }

    .cell--order-11-phone.cell--order-11-phone {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }

    .cell--order-12-phone.cell--order-12-phone {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }

    .cell--1-col,
    .cell--1-col-phone.cell--1-col-phone {
        width: calc(25% - 16px);
    }

    .grid--no-spacing > .cell--1-col,
    .grid--no-spacing > .cell--1-col-phone.cell--1-col-phone {
        width: 25%;
    }

    .cell--2-col,
    .cell--2-col-phone.cell--2-col-phone {
        width: calc(50% - 16px);
    }

    .grid--no-spacing > .cell--2-col,
    .grid--no-spacing > .cell--2-col-phone.cell--2-col-phone {
        width: 50%;
    }

    .cell--3-col,
    .cell--3-col-phone.cell--3-col-phone {
        width: calc(75% - 16px);
    }

    .grid--no-spacing > .cell--3-col,
    .grid--no-spacing > .cell--3-col-phone.cell--3-col-phone {
        width: 75%;
    }

    .cell--4-col,
    .cell--4-col-phone.cell--4-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--4-col,
    .grid--no-spacing > .cell--4-col-phone.cell--4-col-phone {
        width: 100%;
    }

    .cell--5-col,
    .cell--5-col-phone.cell--5-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--5-col,
    .grid--no-spacing > .cell--5-col-phone.cell--5-col-phone {
        width: 100%;
    }

    .cell--6-col,
    .cell--6-col-phone.cell--6-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--6-col,
    .grid--no-spacing > .cell--6-col-phone.cell--6-col-phone {
        width: 100%;
    }

    .cell--7-col,
    .cell--7-col-phone.cell--7-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--7-col,
    .grid--no-spacing > .cell--7-col-phone.cell--7-col-phone {
        width: 100%;
    }

    .cell--8-col,
    .cell--8-col-phone.cell--8-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--8-col,
    .grid--no-spacing > .cell--8-col-phone.cell--8-col-phone {
        width: 100%;
    }

    .cell--9-col,
    .cell--9-col-phone.cell--9-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--9-col,
    .grid--no-spacing > .cell--9-col-phone.cell--9-col-phone {
        width: 100%;
    }

    .cell--10-col,
    .cell--10-col-phone.cell--10-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--10-col,
    .grid--no-spacing > .cell--10-col-phone.cell--10-col-phone {
        width: 100%;
    }

    .cell--11-col,
    .cell--11-col-phone.cell--11-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--11-col,
    .grid--no-spacing > .cell--11-col-phone.cell--11-col-phone {
        width: 100%;
    }

    .cell--12-col,
    .cell--12-col-phone.cell--12-col-phone {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--12-col,
    .grid--no-spacing > .cell--12-col-phone.cell--12-col-phone {
        width: 100%;
    }

    .cell--1-offset,
    .cell--1-offset-phone.cell--1-offset-phone {
        margin-left: calc(25% + 8px);
    }

    .grid.grid--no-spacing > .cell--1-offset,
    .grid.grid--no-spacing > .cell--1-offset-phone.cell--1-offset-phone {
        margin-left: 25%;
    }

    .cell--2-offset,
    .cell--2-offset-phone.cell--2-offset-phone {
        margin-left: calc(50% + 8px);
    }

    .grid.grid--no-spacing > .cell--2-offset,
    .grid.grid--no-spacing > .cell--2-offset-phone.cell--2-offset-phone {
        margin-left: 50%;
    }

    .cell--3-offset,
    .cell--3-offset-phone.cell--3-offset-phone {
        margin-left: calc(75% + 8px);
    }

    .grid.grid--no-spacing > .cell--3-offset,
    .grid.grid--no-spacing > .cell--3-offset-phone.cell--3-offset-phone {
        margin-left: 75%;
    }
}

@media (min-width: 740px) and (max-width: 1099px) {
    .grid {
        padding: 8px;
    }

    .cell {
        margin: 8px;
        width: calc(50% - 16px);
    }

    .grid--no-spacing > .cell {
        width: 50%;
    }

    .cell--hide-tablet {
        display: none !important;
    }

    .cell--order-1-tablet.cell--order-1-tablet {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .cell--order-2-tablet.cell--order-2-tablet {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .cell--order-3-tablet.cell--order-3-tablet {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }

    .cell--order-4-tablet.cell--order-4-tablet {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }

    .cell--order-5-tablet.cell--order-5-tablet {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }

    .cell--order-6-tablet.cell--order-6-tablet {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }

    .cell--order-7-tablet.cell--order-7-tablet {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }

    .cell--order-8-tablet.cell--order-8-tablet {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }

    .cell--order-9-tablet.cell--order-9-tablet {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }

    .cell--order-10-tablet.cell--order-10-tablet {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }

    .cell--order-11-tablet.cell--order-11-tablet {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }

    .cell--order-12-tablet.cell--order-12-tablet {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }

    .cell--1-col,
    .cell--1-col-tablet.cell--1-col-tablet {
        width: calc(12.5% - 16px);
    }

    .grid--no-spacing > .cell--1-col,
    .grid--no-spacing > .cell--1-col-tablet.cell--1-col-tablet {
        width: 12.5%;
    }

    .cell--2-col,
    .cell--2-col-tablet.cell--2-col-tablet {
        width: calc(25% - 16px);
    }

    .grid--no-spacing > .cell--2-col,
    .grid--no-spacing > .cell--2-col-tablet.cell--2-col-tablet {
        width: 25%;
    }

    .cell--3-col,
    .cell--3-col-tablet.cell--3-col-tablet {
        width: calc(37.5% - 16px);
    }

    .grid--no-spacing > .cell--3-col,
    .grid--no-spacing > .cell--3-col-tablet.cell--3-col-tablet {
        width: 37.5%;
    }

    .cell--4-col,
    .cell--4-col-tablet.cell--4-col-tablet {
        width: calc(50% - 16px);
    }

    .grid--no-spacing > .cell--4-col,
    .grid--no-spacing > .cell--4-col-tablet.cell--4-col-tablet {
        width: 50%;
    }

    .cell--5-col,
    .cell--5-col-tablet.cell--5-col-tablet {
        width: calc(62.5% - 16px);
    }

    .grid--no-spacing > .cell--5-col,
    .grid--no-spacing > .cell--5-col-tablet.cell--5-col-tablet {
        width: 62.5%;
    }

    .cell--6-col,
    .cell--6-col-tablet.cell--6-col-tablet {
        width: calc(75% - 16px);
    }

    .grid--no-spacing > .cell--6-col,
    .grid--no-spacing > .cell--6-col-tablet.cell--6-col-tablet {
        width: 75%;
    }

    .cell--7-col,
    .cell--7-col-tablet.cell--7-col-tablet {
        width: calc(87.5% - 16px);
    }

    .grid--no-spacing > .cell--7-col,
    .grid--no-spacing > .cell--7-col-tablet.cell--7-col-tablet {
        width: 87.5%;
    }

    .cell--8-col,
    .cell--8-col-tablet.cell--8-col-tablet {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--8-col,
    .grid--no-spacing > .cell--8-col-tablet.cell--8-col-tablet {
        width: 100%;
    }

    .cell--9-col,
    .cell--9-col-tablet.cell--9-col-tablet {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--9-col,
    .grid--no-spacing > .cell--9-col-tablet.cell--9-col-tablet {
        width: 100%;
    }

    .cell--10-col,
    .cell--10-col-tablet.cell--10-col-tablet {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--10-col,
    .grid--no-spacing > .cell--10-col-tablet.cell--10-col-tablet {
        width: 100%;
    }

    .cell--11-col,
    .cell--11-col-tablet.cell--11-col-tablet {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--11-col,
    .grid--no-spacing > .cell--11-col-tablet.cell--11-col-tablet {
        width: 100%;
    }

    .cell--12-col,
    .cell--12-col-tablet.cell--12-col-tablet {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--12-col,
    .grid--no-spacing > .cell--12-col-tablet.cell--12-col-tablet {
        width: 100%;
    }

    .cell--1-offset,
    .cell--1-offset-tablet.cell--1-offset-tablet {
        margin-left: calc(12.5% + 8px);
    }

    .grid.grid--no-spacing > .cell--1-offset,
    .grid.grid--no-spacing > .cell--1-offset-tablet.cell--1-offset-tablet {
        margin-left: 12.5%;
    }

    .cell--2-offset,
    .cell--2-offset-tablet.cell--2-offset-tablet {
        margin-left: calc(25% + 8px);
    }

    .grid.grid--no-spacing > .cell--2-offset,
    .grid.grid--no-spacing > .cell--2-offset-tablet.cell--2-offset-tablet {
        margin-left: 25%;
    }

    .cell--3-offset,
    .cell--3-offset-tablet.cell--3-offset-tablet {
        margin-left: calc(37.5% + 8px);
    }

    .grid.grid--no-spacing > .cell--3-offset,
    .grid.grid--no-spacing > .cell--3-offset-tablet.cell--3-offset-tablet {
        margin-left: 37.5%;
    }

    .cell--4-offset,
    .cell--4-offset-tablet.cell--4-offset-tablet {
        margin-left: calc(50% + 8px);
    }

    .grid.grid--no-spacing > .cell--4-offset,
    .grid.grid--no-spacing > .cell--4-offset-tablet.cell--4-offset-tablet {
        margin-left: 50%;
    }

    .cell--5-offset,
    .cell--5-offset-tablet.cell--5-offset-tablet {
        margin-left: calc(62.5% + 8px);
    }

    .grid.grid--no-spacing > .cell--5-offset,
    .grid.grid--no-spacing > .cell--5-offset-tablet.cell--5-offset-tablet {
        margin-left: 62.5%;
    }

    .cell--6-offset,
    .cell--6-offset-tablet.cell--6-offset-tablet {
        margin-left: calc(75% + 8px);
    }

    .grid.grid--no-spacing > .cell--6-offset,
    .grid.grid--no-spacing > .cell--6-offset-tablet.cell--6-offset-tablet {
        margin-left: 75%;
    }

    .cell--7-offset,
    .cell--7-offset-tablet.cell--7-offset-tablet {
        margin-left: calc(87.5% + 8px);
    }

    .grid.grid--no-spacing > .cell--7-offset,
    .grid.grid--no-spacing > .cell--7-offset-tablet.cell--7-offset-tablet {
        margin-left: 87.5%;
    }
}

@media (min-width: 1100px) {
    .grid {
        padding: 8px;
    }

    .cell {
        margin: 8px;
        width: calc(33.3333333333% - 16px);
    }

    .grid--no-spacing > .cell {
        width: 33.3333333333%;
    }

    .cell--hide-desktop {
        display: none !important;
    }

    .cell--order-1-desktop.cell--order-1-desktop {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .cell--order-2-desktop.cell--order-2-desktop {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .cell--order-3-desktop.cell--order-3-desktop {
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3;
    }

    .cell--order-4-desktop.cell--order-4-desktop {
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4;
    }

    .cell--order-5-desktop.cell--order-5-desktop {
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5;
    }

    .cell--order-6-desktop.cell--order-6-desktop {
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6;
    }

    .cell--order-7-desktop.cell--order-7-desktop {
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7;
    }

    .cell--order-8-desktop.cell--order-8-desktop {
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8;
    }

    .cell--order-9-desktop.cell--order-9-desktop {
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9;
    }

    .cell--order-10-desktop.cell--order-10-desktop {
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10;
    }

    .cell--order-11-desktop.cell--order-11-desktop {
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11;
    }

    .cell--order-12-desktop.cell--order-12-desktop {
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12;
    }

    .cell--1-col,
    .cell--1-col-desktop.cell--1-col-desktop {
        width: calc(8.3333333333% - 16px);
    }

    .grid--no-spacing > .cell--1-col,
    .grid--no-spacing > .cell--1-col-desktop.cell--1-col-desktop {
        width: 8.3333333333%;
    }

    .cell--2-col,
    .cell--2-col-desktop.cell--2-col-desktop {
        width: calc(16.6666666667% - 16px);
    }

    .grid--no-spacing > .cell--2-col,
    .grid--no-spacing > .cell--2-col-desktop.cell--2-col-desktop {
        width: 16.6666666667%;
    }

    .cell--3-col,
    .cell--3-col-desktop.cell--3-col-desktop {
        width: calc(25% - 16px);
    }

    .grid--no-spacing > .cell--3-col,
    .grid--no-spacing > .cell--3-col-desktop.cell--3-col-desktop {
        width: 25%;
    }

    .cell--4-col,
    .cell--4-col-desktop.cell--4-col-desktop {
        width: calc(33.3333333333% - 16px);
    }

    .grid--no-spacing > .cell--4-col,
    .grid--no-spacing > .cell--4-col-desktop.cell--4-col-desktop {
        width: 33.3333333333%;
    }

    .cell--5-col,
    .cell--5-col-desktop.cell--5-col-desktop {
        width: calc(41.6666666667% - 16px);
    }

    .grid--no-spacing > .cell--5-col,
    .grid--no-spacing > .cell--5-col-desktop.cell--5-col-desktop {
        width: 41.6666666667%;
    }

    .cell--6-col,
    .cell--6-col-desktop.cell--6-col-desktop {
        width: calc(50% - 16px);
    }

    .grid--no-spacing > .cell--6-col,
    .grid--no-spacing > .cell--6-col-desktop.cell--6-col-desktop {
        width: 50%;
    }

    .cell--7-col,
    .cell--7-col-desktop.cell--7-col-desktop {
        width: calc(58.3333333333% - 16px);
    }

    .grid--no-spacing > .cell--7-col,
    .grid--no-spacing > .cell--7-col-desktop.cell--7-col-desktop {
        width: 58.3333333333%;
    }

    .cell--8-col,
    .cell--8-col-desktop.cell--8-col-desktop {
        width: calc(66.6666666667% - 16px);
    }

    .grid--no-spacing > .cell--8-col,
    .grid--no-spacing > .cell--8-col-desktop.cell--8-col-desktop {
        width: 66.6666666667%;
    }

    .cell--9-col,
    .cell--9-col-desktop.cell--9-col-desktop {
        width: calc(75% - 16px);
    }

    .grid--no-spacing > .cell--9-col,
    .grid--no-spacing > .cell--9-col-desktop.cell--9-col-desktop {
        width: 75%;
    }

    .cell--10-col,
    .cell--10-col-desktop.cell--10-col-desktop {
        width: calc(83.3333333333% - 16px);
    }

    .grid--no-spacing > .cell--10-col,
    .grid--no-spacing > .cell--10-col-desktop.cell--10-col-desktop {
        width: 83.3333333333%;
    }

    .cell--11-col,
    .cell--11-col-desktop.cell--11-col-desktop {
        width: calc(91.6666666667% - 16px);
    }

    .grid--no-spacing > .cell--11-col,
    .grid--no-spacing > .cell--11-col-desktop.cell--11-col-desktop {
        width: 91.6666666667%;
    }

    .cell--12-col,
    .cell--12-col-desktop.cell--12-col-desktop {
        width: calc(100% - 16px);
    }

    .grid--no-spacing > .cell--12-col,
    .grid--no-spacing > .cell--12-col-desktop.cell--12-col-desktop {
        width: 100%;
    }

    .cell--1-offset,
    .cell--1-offset-desktop.cell--1-offset-desktop {
        margin-left: calc(8.3333333333% + 8px);
    }

    .grid.grid--no-spacing > .cell--1-offset,
    .grid.grid--no-spacing > .cell--1-offset-desktop.cell--1-offset-desktop {
        margin-left: 8.3333333333%;
    }

    .cell--2-offset,
    .cell--2-offset-desktop.cell--2-offset-desktop {
        margin-left: calc(16.6666666667% + 8px);
    }

    .grid.grid--no-spacing > .cell--2-offset,
    .grid.grid--no-spacing > .cell--2-offset-desktop.cell--2-offset-desktop {
        margin-left: 16.6666666667%;
    }

    .cell--3-offset,
    .cell--3-offset-desktop.cell--3-offset-desktop {
        margin-left: calc(25% + 8px);
    }

    .grid.grid--no-spacing > .cell--3-offset,
    .grid.grid--no-spacing > .cell--3-offset-desktop.cell--3-offset-desktop {
        margin-left: 25%;
    }

    .cell--4-offset,
    .cell--4-offset-desktop.cell--4-offset-desktop {
        margin-left: calc(33.3333333333% + 8px);
    }

    .grid.grid--no-spacing > .cell--4-offset,
    .grid.grid--no-spacing > .cell--4-offset-desktop.cell--4-offset-desktop {
        margin-left: 33.3333333333%;
    }

    .cell--5-offset,
    .cell--5-offset-desktop.cell--5-offset-desktop {
        margin-left: calc(41.6666666667% + 8px);
    }

    .grid.grid--no-spacing > .cell--5-offset,
    .grid.grid--no-spacing > .cell--5-offset-desktop.cell--5-offset-desktop {
        margin-left: 41.6666666667%;
    }

    .cell--6-offset,
    .cell--6-offset-desktop.cell--6-offset-desktop {
        margin-left: calc(50% + 8px);
    }

    .grid.grid--no-spacing > .cell--6-offset,
    .grid.grid--no-spacing > .cell--6-offset-desktop.cell--6-offset-desktop {
        margin-left: 50%;
    }

    .cell--7-offset,
    .cell--7-offset-desktop.cell--7-offset-desktop {
        margin-left: calc(58.3333333333% + 8px);
    }

    .grid.grid--no-spacing > .cell--7-offset,
    .grid.grid--no-spacing > .cell--7-offset-desktop.cell--7-offset-desktop {
        margin-left: 58.3333333333%;
    }

    .cell--8-offset,
    .cell--8-offset-desktop.cell--8-offset-desktop {
        margin-left: calc(66.6666666667% + 8px);
    }

    .grid.grid--no-spacing > .cell--8-offset,
    .grid.grid--no-spacing > .cell--8-offset-desktop.cell--8-offset-desktop {
        margin-left: 66.6666666667%;
    }

    .cell--9-offset,
    .cell--9-offset-desktop.cell--9-offset-desktop {
        margin-left: calc(75% + 8px);
    }

    .grid.grid--no-spacing > .cell--9-offset,
    .grid.grid--no-spacing > .cell--9-offset-desktop.cell--9-offset-desktop {
        margin-left: 75%;
    }

    .cell--10-offset,
    .cell--10-offset-desktop.cell--10-offset-desktop {
        margin-left: calc(83.3333333333% + 8px);
    }

    .grid.grid--no-spacing > .cell--10-offset,
    .grid.grid--no-spacing > .cell--10-offset-desktop.cell--10-offset-desktop {
        margin-left: 83.3333333333%;
    }

    .cell--11-offset,
    .cell--11-offset-desktop.cell--11-offset-desktop {
        margin-left: calc(91.6666666667% + 8px);
    }

    .grid.grid--no-spacing > .cell--11-offset,
    .grid.grid--no-spacing > .cell--11-offset-desktop.cell--11-offset-desktop {
        margin-left: 91.6666666667%;
    }
}

.spacer {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}
</style>
