<template lang="">
    <div :class="$twMerge('flex items-center justify-between text-lg font-normal text-neutral-content', classes)">
        <p>
            {{ title }}
        </p>
        <div @click="closeHandler" class="cursor-pointer hover:text-neutral ml-2">
            <Icon icon="line-md:close" height="28px" />
        </div>
    </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
    components: {
        Icon
    },
    props: {
        title: {
            type: String,
            default: "Title"
        },
        classes: {
            type: String,
            default: ""
        },
        closeHandler: Function
    }
};
</script>
<style lang=""></style>
