<template lang="">
    <div>
        <ModalHeader :title="$t('popupCard.verifyCard.title')" :closeHandler="closeHandler" />

        <div class="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 px-0">
            <div>
                <StepItem :number="1" :text="$t('popupCard.verifyCard.step1')" />

                <div class="text-neutral-content mt-2">
                    {{ $t("popupCard.verifyCard.desc1", { number: "" }) }}
                </div>

                <div class="text-neutral mt-4">
                    {{ $t("popupCard.verifyCard.desc2") }}<br />
                    <div class="ml-3 mt-1 font-medium">
                        {{ $t("popupCard.verifyCard.desc3") }}
                        <br />
                        {{ $t("popupCard.verifyCard.desc4") }}
                    </div>
                </div>
                <StepItem classes="mt-4" :number="2" :text="addImageText" />

                <div class="mt-4 flex flex-col items-center justify-center">
                    <div v-if="!image" class="rounded-md   w-8/12">
                        <CardPhotoExample />
                    </div>
                    <p class="mt-2 text-sm text-center text-neutral-content">
                        {{ $t("popupCard.verifyCard.examplePhotoDesc") }}
                    </p>
                </div>
            </div>
            <div class="flex flex-col justify-between w-full">
                <ImageSelect :image="image" :file="file" :removeImage="removeImage" :isScreenMobile="isScreenMobile" :setImage="setImage" :setFile="setFile" />
                <div class="grid grid-cols-2 gap-4 w-full">
                    <div>
                        <my-button classes="w-full" size="large" :value="$t('popupCard.verifyCard.confirm.cancel')" @click.native="closeHandler"></my-button>
                    </div>
                    <div class="">
                        <my-button classes="w-full" size="large" variant="primary" :value="$t('popupCard.verifyCard.confirm.send')" @click.native="sendVerifyHandler" :spinner="spinner"></my-button>
                    </div>
                </div>
            </div>

            <!-- <NoVerifyExchange :orderFieldsData="orderFieldsData" :orderData="orderData" :successCreateHandler="successCreateHandler" :errorCreateHandler="errorCreateHandler" /> -->
        </div>
        <!-- <template v-slot:btns> -->

        <!-- </template> -->
        <!-- </ModalContentWrap> -->
    </div>
</template>
<script>
import { mapMutations } from "vuex";
// Components
// import ModalContentWrap from "../ModalContentWrap/ModalContentWrap.vue";
import ImageSelect from "../ui/ImageSelect.vue";
// Computeds
import addImageText from "./computeds/addImageText";
import isScreenMobile from "./computeds/isScreenMobile";

// Modules
// import NoVerifyExchange from "./modules/NoVerifyExchange.vue";

// UI
import StepItem from "../ui/StepItem.vue";
// import MyInput from "../ui/input";
import MyButton from "../ui/button.vue";

// Services
import OrderService from "../../services/OrderService";

// Main Ui
import CardPhotoExample from "./UI/CardPhotoExample.vue";

import ModalHeader from "../ui/Modal/ModalHeader.vue";

// import Modal from "../../components/ui/Modal.vue";

export default {
    data() {
        return {
            image: null,
            file: null,
            spinner: false,
            exchange: null
        };
    },
    props: {
        closeHandler: Function,
        orderData: Object,
        successCreateHandler: Function,
        errorCreateHandler: Function,
        orderFieldsData: Object
    },
    created(){
        console.log(this.closeHandler, 'closeHandler')
    },
    methods: {
        ...mapMutations(["USER_LOGIN", "SET_ORDER_TOKEN", "SET_ORDER_DATA", "SET_CURRENT_ORDER", "SET_SNACK_BAR"]),
        removeImage() {
            this.file = null;
            this.image = null;
        },
        setImage(i) {
            this.image = i;
        },
        setFile(f) {
            this.file = f;
        },
        async sendVerifyHandler() {
            try {
                // console.log(this.file, 'file')
                if (this.spinner) return;
                if (!this.file) return;
                this.spinner = true;
                let form = new FormData();
                form.append("data", JSON.stringify(this.orderFieldsData));
                form.append("dest", "card");
                form.append("image", this.file);
                let response = await OrderService.create(form);
                this.successCreateHandler(response.data);
            } catch (e) {
                this.errorCreateHandler(e);
            } finally {
                this.spinner = false;
            }
        }
    },
    computed: {
        addImageText,
        isScreenMobile
    },

    components: {
        ImageSelect,
        // ModalContentWrap,
        StepItem,
        // NoVerifyExchange,
        CardPhotoExample,
        MyButton,
        ModalHeader
    },
    beforeDestroy() {
        this.image = null;
        this.file = null;
        this.spinner = false;
    }
};
</script>
<style lang="scss" scoped>
.verifyn {
}

.verifyCardSection_description {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 16px;
}

.verifyCardSection_descriptionImport {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 0px 16px;
    margin-top: 5px;
    font-weight: 600;
}

.verifyCardSection_description > p {
    margin-bottom: 16px;
}
.verifyCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.verifyCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.verifyCardSection_btnsWrap button:last-child {
    margin-left: 10px;
    display: inline-block;
}
</style>
