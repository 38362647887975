<template lang="">
    <div>
        <Modal windowClasses="max-w-5xl" :isOpen="show && isCardVerify" @close="closeModal" @confirm="closeModal">
            <VerifyCard v-if="isCardVerify" :closeHandler="closeModal" :orderData="orderData" :successCreateHandler="successCreateHandler" :errorCreateHandler="errorCreateHandler" :orderFieldsData="orderFieldsData" />
        </Modal>

        <Modal windowClasses="max-w-xl" :isOpen="show && isAddQr" @close="closeModal" @confirm="closeModal">
            <AddQR v-if="isAddQr" :order="orderData" :orderFieldsData="orderFieldsData" :closeHandler="closeModal" :successCreateHandler="successCreateHandler" :errorCreateHandler="errorCreateHandler" />
        </Modal>

        <Modal windowClasses="max-w-5xl" :isOpen="show && isChangeAmount" @close="closeModal" @confirm="closeModal"> <ChangeAmountStatus v-if="isChangeAmount" :hideHandler="closeModal" :order="orderData.order" /> </Modal>

        <Modal windowClasses="max-w-5xl" :isOpen="show && isCardReverify" @close="closeModal" @confirm="closeModal">
            <RejectedOrderCard v-if="isCardReverify" :closeHandler="closeModal" :card="orderData.card" :successCreateHandler="successCreateHandler" :errorCreateHandler="errorCreateHandler" :orderFieldsData="orderFieldsData" />
        </Modal>
    </div>
</template>
<script>

// Store
import { mapMutations } from "vuex";

// Modules
import ChangeAmountStatus from "./ChangeAmountStatus.vue";
import VerifyCard from "../Card/VerifyCard.vue";
import RejectedOrderCard from "../Card/RejectedOrderCard/RejectedOrderCard.vue";
import AddQR from "./AddQR.vue";
import Modal from "../ui/Modal/Modal.vue";
export default {
    props: {
        show: Boolean,
        closeModal: Function,
        orderData: Object,
        orderFieldsData: Object
    },
    components: {
        // VueModal,
        Modal,
        AddQR,
        ChangeAmountStatus,
        VerifyCard,
        RejectedOrderCard
    },
    computed: {
        status() {
            return this?.orderData?.status;
        },
        isCardVerify() {
            return this.status == "verify";
        },
        isCardReverify() {
            return this.status == "reverify";
        },
        isChangeAmount() {
            return this.status == "change-amount";
        },
        isAddQr() {
            return this.status == "qr";
        }
    },
    methods: {
        ...mapMutations(["USER_LOGIN", "SET_ORDER_TOKEN", "SET_ORDER_DATA", "SET_CURRENT_ORDER", "SET_SNACK_BAR"]),

        successCreateHandler(data) {
            if (data.user) {
                this.USER_LOGIN(data);
            }
            if (data.orderToken) {
                this.SET_ORDER_TOKEN(data.orderToken);
            }
            let order = data.order;
            this.eventer.emit("clear exchanger fields");
            this.$router.push("/order/" + order.hash);
            this.closeModal();
        },
        errorCreateHandler(err) {
            let errorMessage = err?.response?.data?.error;
            if (errorMessage) {
                this.SET_SNACK_BAR({ active: true, text: errorMessage });
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.order-wrapper {
    min-width: 480px;
    max-width: 100%;
}
</style>
