<template>
    <div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div class="card">
                <p class="text-neutral-content text-center text-lg">
                    {{ $t("contacts.title") }}
                </p>
                <p class=" text-neutral-content mt-4">
                    {{ $t("contacts.help") }}
                </p>
                <div class="mt-4">
                    <div class="">
                        <div class="flex items-center text-neutral-content mb-4">
                            <Icon icon="iconoir:clock" height="34" />
                            <div class="ml-2">
                                <p class="text-neutral-content text-sm">{{ $t("contacts.workingTime") }}:</p>
                                <p class="text-neutral text-md">
                                    {{ $t("contacts.aroundTheClock") }}
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center text-neutral-content mb-4">
                            <Icon icon="ion:mail-outline" height="34" />
                            <div class="ml-2">
                                <p class="text-neutral-content text-sm">{{ $t("contacts.emailSupport") }}:</p>
                                <a class="text-primary text-md" target="_blank" href="mailto:support@ex-bank.cc"> support@ex-bank.cc</a>
                            </div>
                        </div>
                        <div class="flex items-center text-neutral-content">
                            <Icon icon="akar-icons:chat-bubble" height="34" />
                            <div class="ml-2">
                                <p class="text-neutral-content text-sm">{{ $t("contacts.onlineChat") }}:</p>
                                <p @click="openChat" class="text-primary text-md">
                                    {{ $t("contacts.openChat") }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <p class="text-neutral-content text-center text-lg">
                    {{ $t("contacts.serviceReviews") }}
                </p>
                <p class="text-neutral-content mt-4" v-html="$t('contacts.conclusion')" />

                <p class="text-center text-neutral-content mt-4" v-html="content" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Icon } from "@iconify/vue2";

export default {
    components: {
        Icon
    },
    computed: {
        ...mapGetters(["contacts"]),
        content() {
            let locale = this.$i18n.locale;

            let result = locale == "ru" ? this.contacts.rus : this.contacts.eng;

            return result;
        }
    },
    methods: {
        openChat() {
            // window.jivo_api.open();
            window.Chatra("openChat");
            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.contacts {
    color: $black;
    font-size: 16px;
}

.contacts .card {
    background-color: $white;
    margin: 16px;
    padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.contacts .card .page-name {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 32px;
    display: flex;
    color: $grafit;
    text-align: center;
}

.options {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.options .option {
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    border-radius: 10px;
    width: -webkit-fill-available;
    margin: 8px 10px;
}

//.options .option:not(:last-child) {
//    margin-right: 64px;
//}

.options .option .icon {
    color: #565656;
    margin-right: 16px;
    display: flex;
    align-items: center;
}

.options .option .info > .title {
    color: $grafit;
    font-size: 18px;
    margin-bottom: 8px;
}

.options .option .info > .sub-title {
    font-size: 16px;
    color: $grey-lg-text;
}

.description {
    color: $grafit;
    line-height: 26px;
}

.border {
    height: 2px;
    width: 100%;
    margin: 15px 0;
}

.links-wrap > p:not(:last-child) {
    margin-bottom: 16px;
}

.links-wrap > p:nth-child(9) {
    margin-bottom: 32px;
}

.links-wrap > p:last-child {
    line-height: 26px;
}

.links-wrap > p > a {
    color: #0081ff;
    text-decoration: underline;
}

.links-wrap > p > a:hover {
    color: #2a5885;
    text-decoration: underline;
}

.monitoring-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.monitoring-wrap > a {
    width: 88px;
    height: 32px;
}

.monitoring-wrap > a:not(:last-child) {
    margin-right: 16px;
}

.monitoring-wrap > a > img {
    width: 100%;
    height: 100%;
}

@media (max-width: 739px) {
    .contacts .card {
        margin: 8px;
        padding: 24px 16px;
    }
    .options {
        flex-direction: column;
    }

    .border {
        margin: 10px 0;
    }
}

.open-link {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: $link;
    transition: all 0.32s;

    &:hover {
        color: #2a5885;
    }
}

.chat-img {
    width: 28px;
}
</style>
